import React from "react";
import Theme from "../theme/theme_2";
import { PromotionForm } from "../components";

const createPromotion = () => {
  return (
    <div>
      <Theme PageName={"Create Promotion"}>
        <PromotionForm formType="create" />
      </Theme>
    </div>
  );
};

export default createPromotion;
