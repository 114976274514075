import React from "react";
import ReceptionManagement from "../components/ReceptionManagement";
import Theme from "../theme/theme_2";

export default function receptionManagement() {
  return (
    <div>
      <Theme PageName={"Reception Management"}>
        <ReceptionManagement />
      </Theme>
    </div>
  );
}
