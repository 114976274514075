import React from "react";
import Theme from "../theme/theme_2";
import { PromotionForm } from "../components";

const editPromotion = () => {
  return (
    <div>
      <Theme PageName={"Edit Promotion"}>
        <PromotionForm formType="edit" />
      </Theme>
    </div>
  );
};

export default editPromotion;
