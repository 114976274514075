import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Card as BaseCard,
  Space,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

const { TextArea } = Input;

const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgb(0 89 83);
`;

export default function EditProjectPage({ initialValue, submitForm }) {
  const history = useHistory();
  const [editProjectForm] = Form.useForm();

  return (
    <div className="theme-2-content">
      <Form
        name="editProjectForm"
        form={editProjectForm}
        labelAlign="left"
        onFinish={submitForm}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 7 },
          md: { span: 7 },
          lg: { span: 7 },
          xl: { span: 7 },
          xxl: { span: 5 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 17 },
          md: { span: 18 },
          lg: { span: 20 },
          xl: { span: 20 },
          xxl: { span: 18 },
        }}
        initialValues={initialValue}
      >
        <Card
          title="Project Information"
          extra={
            <Row>
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <Form.Item style={{ paddingTop: "10%" }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          style={{ marginRight: "10px" }}
                        />
                      }
                      onClick={() => history.goBack()}
                    >
                      Back
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="theme-button-save"
                      icon={
                        <FontAwesomeIcon
                          icon={faSave}
                          style={{ marginRight: "10px" }}
                        />
                      }
                    >
                      Save
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <Form.Item style={{ paddingTop: "35%" }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={<FontAwesomeIcon icon={faArrowLeft} />}
                      onClick={() => history.goBack()}
                    ></Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="theme-2-button-1"
                      icon={<FontAwesomeIcon icon={faSave} />}
                    ></Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          }
        >
          <Row justify="center">
            <Col xxl={12} xl={12} lg={16} md={16} sm={24} xs={24}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Project Name"
                    name="projectName"
                    rules={[
                      {
                        required: true,
                        message: "Please input project name!",
                      },
                      {
                        min: 1,
                        message: "minimum length = 1",
                      },
                      {
                        max: 165,
                        message: "maximum length = 165",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Project Name"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Please input project address!",
                      },
                      {
                        min: 1,
                        message: "minimum length = 1",
                      },
                      {
                        max: 255,
                        message: "maximum length = 255",
                      },
                    ]}
                  >
                    <TextArea
                      rows={3}
                      placeholder="Address"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Telephone"
                    name="telephone"
                    rules={[
                      {
                        required: true,
                        message: "Please input telephone number!",
                        whitespace: true,
                      },
                      {
                        pattern: "^[+]?[0-9]{9,12}$",
                        message: "Your telephone number is incorrect format.",
                      },
                    ]}
                  >
                    <Input placeholder="Telephone" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
}
