import React from "react";
import Theme from "../theme/theme_2";
import CarForm from "../components/CarForm";

export default function viewCars() {
  return (
    <div>
      <Theme PageName={"View Car"}>
        <CarForm />
      </Theme>
    </div>
  );
}
