import React, { useState, useEffect, useContext } from 'react';
import { api } from '../services';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import theme from '../theme';
import {
    Row, Col, Button, Form,
    Input, Space, Tooltip, Image
} from 'antd';
import { Card, CardTable, Icon } from '../components';
import { faSearch, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { UserAvatar } from './UserAvatar';
import { FromEstemModal } from '../components';
import Cookies from 'universal-cookie';
import AuthenContext from "../utils/useAuthentication";
const swal = withReactContent(Swal);

const cookies = new Cookies();
export default function ParkingManagement() {
    const [dataNew, setDataNew] = useState();
    const [dataNewID, setDataNewID] = useState();
    const [isOpenModel, setIsOpenModel] = useState(false);
    const [searchForm] = Form.useForm();
    const { user } = useContext(AuthenContext);
    const [stampTemplate, setStampTemplate] = useState([]);

    const getStampTemplate = () => {
        global.store.dispatch({ type: "set_loading_show", data: { show: true } });
        const option = {
            method: "get",
            url: `${process.env.REACT_APP_API}/organizations/${user.data.org_id}/stamp_template`,
            headers: {
                'Authorization': 'Bearer ' + cookies.get('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        api(option)
            .then((res) => {
                global.store.dispatch({
                    type: "set_loading_show",
                    data: { show: false },
                });
                if (res.data.code === 200) {
                    setStampTemplate(get(res, "data.data"));
                }
            })
    };

    const onOpenModel = (dataIn) => {
        // getStampTemplate();
        // setDataNewID(dataIn);
        // setIsOpenModel(true);
    }

    const handleSave = () => {
        searchForm
            .validateFields()
            .then((values) => {
                global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
                const option = {
                    method: 'get',
                    url: `${process.env.REACT_APP_API}/parking`,
                    headers: {
                        'Authorization': 'Bearer ' + cookies.get('accessToken'),
                        'Content-Type': 'application/json'
                    },
                };
                api(option)
                    .then((res) => {
                        global.store.dispatch({
                            type: 'set_loading_show',
                            data: { show: false },
                        });
                        if (res.data.code === 200) {
                            setDataNew(
                                sortBy(get(res, 'data.data'), [
                                    'trn_log_date'
                                ]).reverse()
                            );
                        }
                    })
                    .catch((err) => {
                        global.store.dispatch({
                            type: "set_loading_show",
                            data: { show: false },
                        });
                    });
            })
    };

    const columns = [
        {
            title: 'Date/Time',
            dataIndex: 'trn_log_date',
            key: 'trn_log_date',
            align: 'start',
            className: 'theme-2-table-text',

        },
        {
            title: 'License Plate',
            dataIndex: 'lp_no',
            key: 'lp_no',
            align: 'start',
            className: 'theme-2-table-text',
            responsive: ['sm'],

        },
        {
            title: 'Card No',
            dataIndex: 'card_no',
            key: 'card_no',
            align: 'start',
            className: 'theme-2-table-text',
            responsive: ['sm'],
        },
        {
            title: 'Car Pic',
            dataIndex: 'image_vehicle_url',
            key: 'image_vehicle_url',
            align: 'start',
            className: 'theme-2-table-text',
            responsive: ['sm'],
            render: (id, dataNew) => {
                return (
                    <Space>
                        <Image className="loading_image" src={dataNew.image_vehicle_url} style={{ width: '150px' }} />
                    </Space>
                );
            },
        },
        {
            title: 'Driver Pic',
            dataIndex: 'image_driver_url',
            key: 'image_driver_url',
            align: 'start',
            className: 'theme-2-table-text',
            responsive: ['sm'],
            render: (id, dataNew) => {
                return (
                    <Space>
                        <Image className="loading_image" src={dataNew.image_driver_url} style={{ width: '150px' }} />
                    </Space>
                );
            },
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            className: 'theme-2-table-text',
            render: (dataNew) => (
                <Space size="middle">
                    <Button
                        className="theme-2-button-4"
                        type="primary"
                        onClick={() => onOpenModel(dataNew)}
                    >
                        Parking
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div className="theme-2-content">
            <Row gutter={[0, 0]}>
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Card margin="20px 0">
                        <Row justify="space-between">
                            <Col span={5}>
                                <div style={{ marginTop: '10px' }}>
                                    <p className="theme-2-text-1">{'Parking for Car \xa0'}</p>
                                    <p className="theme-2-text-2">
                                        {get(dataNew, 'length') ? get(dataNew, 'length') : 0}
                                        {'\xa0Total car'}
                                    </p>
                                </div>
                            </Col>
                            <Col span={19}>
                                <Row >
                                    <Col style={{ width: '100%' }}>
                                        <Form
                                            name="searchForm"
                                            form={searchForm}
                                        >
                                            <Col xs={2} sm={16} md={12} lg={5} xl={4} style={{ width: '100%', float: 'right', padding: '10px' }}>
                                                <Form.Item>
                                                    <Button
                                                        className="theme-2-button-4"
                                                        type="primary"
                                                        block
                                                        icon={
                                                            <Icon color="white" margin="0 10px 0 0" icon={faSearch} />
                                                        }
                                                        onClick={handleSave}
                                                    >
                                                        Search
                                                    </Button>
                                                </Form.Item>
                                            </Col>

                                            <Col xs={2} sm={16} md={12} lg={8} xl={8} style={{ width: '100%', float: 'right', padding: '10px' }}>
                                                <Form.Item
                                                    name="cardNo"
                                                    label="Card No"
                                                >
                                                    <Input placeholder="Card No" style={{ width: '100%' }} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={2} sm={16} md={12} lg={8} xl={8} style={{ width: '100%', float: 'right', padding: '10px' }}>
                                                <Form.Item
                                                    name="licensePlate"
                                                    label="License Plate"
                                                >
                                                    <Input placeholder="License Plate" style={{ width: '100%' }} />
                                                </Form.Item>
                                            </Col>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={20}>
                                <CardTable
                                    rowKey="id"
                                    columns={columns}
                                    dataSource={dataNew}
                                    bordered={false}
                                    pagination={{ position: ['bottomCenter'] }}
                                    size="middle"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {/* <FromEstemModal
                onData={dataNewID}
                stampTemplate={stampTemplate}
                visible={isOpenModel}
                onCancel={() => setIsOpenModel(false)}
            /> */}
        </div>
    );
}
