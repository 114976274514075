import React, { useState, useEffect, useContext, useRef } from 'react';
import sortBy from 'lodash/sortBy';
import { api } from '../services';
import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import theme from '../theme';
import {
  Tooltip,
  Table,
  Row,
  Col,
  Button,
  Space,
  Form,
  Select,
  Modal,
  InputNumber,
} from 'antd';
import { Icon, Card, CardTable, Tag } from '.';
import {
  faCheck,
  faTimes,
  faMoneyBillWave,
} from '@fortawesome/free-solid-svg-icons';
import { SelectIcon } from './icon';
import Cookies from 'universal-cookie';
import { format } from 'date-fns';
import { ComparePassword } from './ComparePassword';
import { checkPermission } from '../utils/checkPermission';
import AuthenContext from '../utils/useAuthentication';
import ReactToPrint from 'react-to-print';

const swal = withReactContent(Swal);

export default function Coupon() {
  const [coupon, setDataCoupon] = useState([{}]);
  const { user } = useContext(AuthenContext);
  const [isTenantGUID, settenantGUID] = useState('');
  const [coupon1, setDataCoupon1] = useState([{}]);
  const [tenants, setTenants] = useState();
  const [organizes, setOrganize] = useState([]);
  const [totalData, setTotalData] = useState();
  const [isCheck, setIsCheck] = useState('0');
  const [page, setPage] = useState('');
  const [BuyCoupon, setBuyCoupon] = useState();
  const [isBuyCoupon, setisBuyCoupon] = useState(false);
  const [isDetailsCoupon, setisDetailsCoupon] = useState(false);
  const [isConfirmCouponModal, setisConfirmCouponModal] = useState(false);
  const [sumCoupon, setSumCoupon] = useState();
  const tempSumCoupon = get(BuyCoupon, 'balance') * 30;

  const cookies = new Cookies();

  const getOrganization = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/organizations`,
      headers: {
        Authorization: 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json',
      },
    };
    api(option).then((res) => {
      global.store.dispatch({
        type: 'set_loading_show',
        data: { show: false },
      });
      if (res.data.code === 200) {
        setOrganize(res.data.data);
      }
    });
  };
  const fetchCouponTenant = (type) => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    let data1;
    if (type == '') {
      data1 = {
        SiteGUID: `${process.env.REACT_APP_SITEGUID}`,
        TenantGUID: user?.data?.tenant_guid,
      };
    } else {
      data1 = {
        SiteGUID: `${process.env.REACT_APP_SITEGUID}`,
        TenantGUID: type,
      };
    }
    const option = {
      method: 'post',
      url: `${process.env.REACT_APP_API_SAPI}/Stamp/ListStampQuotaHourByTenant`,
      headers: {
        Authorization:
          'Bearer ' +
          '011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7',
        'Content-Type': 'application/json',
      },
      data: data1,
    };
    console.log(option);
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data) {
          const temp = [];
          temp.push(res.data);
          console.log(temp);
          if (temp.length > 1) {
            setTotalData(res.data.length);
            formatData1(res.data);
          } else {
            setTotalData(temp.length);
            formatData1(temp);
          }
          setIsCheck('1');
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };
  const fetchCoupon = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'post',
      url: `${process.env.REACT_APP_API_SAPI}/Stamp/ListStampQuotaHour`,
      headers: {
        Authorization:
          'Bearer ' +
          '011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7',
        'Content-Type': 'application/json',
      },
      data: {
        SiteGUID: `${process.env.REACT_APP_SITEGUID}`,
      },
    };

    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.status === 200) {
          const temp = [];
          temp.push(res.data);
          if (temp.length > 0) {
            setTotalData(res.data.length);
            formatData(res.data);
          } else {
            setTotalData(temp.length);
            formatData(temp);
          }

          setIsCheck('0');
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };
  const fetchTenant = async () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'post',
      url: `${process.env.REACT_APP_API_SAPI}/Tenant/Tenants`,
      headers: {
        Authorization:
          'Bearer ' +
          '011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7',
        'Content-Type': 'application/json',
      },
      data: {
        SiteGUID: `${process.env.REACT_APP_SITEGUID}`,
        BuildingID: 1,
      },
    };
    await api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.status === 200) {
          setTenants(res.data);
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };
  const formatData = (data) => {
    const dataFormat = data.map((coupon) => {
      return {
        balance: coupon.balance,
        tenantGUID: coupon.tenantGUID,
        orderAmount: coupon.orderAmount,
      };
    });
    setDataCoupon(dataFormat);
  };
  const formatData1 = (data) => {
    const dataFormat = data.map((coupon) => {
      return {
        balance: coupon.balance,
        tenantGUID: coupon.tenantGUID,
        orderAmount: coupon.orderAmount,
      };
    });
    setDataCoupon1(dataFormat);
  };

  useEffect(() => {
    getOrganization();
    fetchTenant();
    if (checkPermission('2')) {
      if (isCheck === '0') {
        fetchCoupon();
      }
    } else {
      fetchCouponTenant('');
    }
  }, []);

  const tagOrganizeAdminRender = (props) => {
    const { label, closable, onClose } = props;
    return (
      <Tag
        color={theme.bgTextButton}
        closable={closable}
        onClose={onClose}
        closeIcon={<Icon fontSize="10px" icon={faTimes} />}
      >
        {label}
      </Tag>
    );
  };

  const BuyCouponModal = () => {
    const [buyCouponForm] = Form.useForm();
    const [couponTotalPrice, setBuyCouponTotalPrice] = useState(0);

    const handleCancel = () => {
      buyCouponForm.resetFields();
      setisBuyCoupon(false);
    };
    const handleSave = () => {
      setisBuyCoupon(false);
      setisDetailsCoupon(true);
      setSumCoupon(couponTotalPrice);
      buyCouponForm.validateFields().then((values) => {
        setisDetailsCoupon({
          tempCoupon: values?.sum_coupon
            ? values?.sum_coupon
            : get(BuyCoupon, 'balance'),
          sumCoupon: sumCoupon,
          tempSumCoupon: tempSumCoupon,
        });
      });
    };

    const changeNumCoupon = (data) => {
      setBuyCouponTotalPrice(data * 30);
    };

    return (
      <Modal
        visible={isBuyCoupon}
        width="40%"
        height="50%"
        title="Buy Coupon"
        onOk={handleSave}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            key="back"
            className="theme-2-button-2"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="theme-button-save"
            type="primary"
            onClick={handleSave}
          >
            ยืนยัน
          </Button>,
        ]}
      >
        <Form
          name="BuyCouponForm"
          form={buyCouponForm}
          labelAlign="center"
          layout="vertical"
        >
          <Row align="middle" justify="center">
            <Col span={24} style={{ textAlign: 'left' }}>
              <h2>จำนวนโค้วต้า</h2>
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Form.Item
                name="sum_coupon"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'กรุณาใส่จำนวนโค้วต้า',
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  defaultValue={get(BuyCoupon, 'balance')}
                  onChange={changeNumCoupon}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            justify="space-between"
            style={{ borderTop: '2px solid #0a4643' }}
          >
            <Col span={12}>
              <h2>รวมราคา</h2>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <h2>{couponTotalPrice.toLocaleString()} บาท</h2>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };
  const ConfirmPassword = () => {
    swal
      .fire({
        title: 'Enter your password',
        input: 'password',
        inputPlaceholder: 'Enter your password',
        inputAttributes: {
          autocapitalize: 'off',
          autocorrect: 'off',
        },
        showConfirmButton: true,
        confirmButtonColor: '#027f7b',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        cancelButtonText: <div style={{ color: '#027f7b' }}>Cancel</div>,
        cancelButtonColor: '#e3e3e3',
        reverseButtons: true,
        preConfirm: (password) => {
          if (!password) {
            return Swal.showValidationMessage(`Please enter password`);
          }
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          setisConfirmCouponModal(true);
          addCoupon();
        }
      });
  };
  const ConfirmCouponModal = () => {
    const printContentRef = useRef();

    const handleSave = () => {
      setisConfirmCouponModal(false);
      // ConfirmPassword();
    };
    const handleCancel = () => {
      setisConfirmCouponModal(false);
      fetchCoupon();
    };
    const tenantName = tenants?.filter((tenant) => {
      return tenant?.tenantGUID === get(BuyCoupon, 'tenantGUID');
    });

    return (
      <Modal
        visible={isConfirmCouponModal}
        width="60%"
        height="60%"
        title="Confirm Coupon"
        onOk={handleSave}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            key="back"
            className="theme-2-button-2"
            onClick={handleCancel}
          >
            ปิด
          </Button>,
          <ReactToPrint
            trigger={() => (
              <Button key="submit" className="theme-button-save" type="primary">
                ปริ้น
              </Button>
            )}
            content={() => printContentRef.current}
          />,
        ]}
      >
        <div ref={printContentRef} style={{ padding: 20 }}>
          <Row align="middle" justify="center">
            <Col span={24} style={{ textAlign: 'center' }}>
              <h2>บริษัท ทรู พรอพเพอร์ตีส์ จำกัด (สาขา 00001)</h2>
              <h3>เลขที่ 18 อาคารทรูทาวเวอร์ ถนนรัชดาภิเษก แขวงห้วยขวาง กรุงเทพมหานคร 10310</h3>
              <h3>เลขทะเบียนผู้เสียภาษี 0994002150404</h3>
              
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={12} style={{ textAlign: 'left' }}>
              <h5>ชื่อบริษัท {tenantName ? tenantName[0]?.tenantName : ''}</h5>
              <h5>
                ที่อยู่บริษัท {tenantName ? tenantName[0]?.taxAddress : ''}{' '}
              </h5>
              <h5>
                เลขทะเบียนผู้เสียภาษี {tenantName ? tenantName[0]?.taxID : ''}
              </h5>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <h5>หมายเลขใบเสร็จ N/A</h5>
              <h5>วันที่ {format(new Date(), 'dd/MM/yyyy')}</h5>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={16} style={{ textAlign: 'right' }}>
              <h1>ใบเสร็จ/ใบกำกับภาษี</h1>
            </Col>
          </Row>
          <Row align="middle" justify="center" style={{ marginTop: 20 }}>
            <Col span={24}>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">รายละเอียด</th>
                    <th scope="col">ราคาต่อหน่อย</th>
                    <th scope="col">จำนวน</th>
                    <th scope="col">ราคา(บาท)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: 'center' }}>
                    <td scope="row">จำนวนโควต้า</td>
                    <td scope="row">1</td>
                    <td scope="row">
                      {sumCoupon ? sumCoupon / 30 : tempSumCoupon / 30}
                    </td>
                    <td scope="row">
                      {sumCoupon
                        ? sumCoupon?.toLocaleString()
                        : tempSumCoupon?.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          {/* <Row justify="space-between" style={{ marginTop: 20 }}>
            <Col span={24} style={{ textAlign: "right" }}>
              <h5> {get(BuyCoupon, "balance")} บาท</h5>
              <h5> {get(BuyCoupon, "balance")} บาท</h5>
              <h5> {get(BuyCoupon, "balance")} บาท</h5>
            </Col>
          </Row> */}
          <Row
            justify="space-between"
            style={{ borderTop: '2px solid #0a4643', marginTop: 20 }}
          >
            <Col span={24} style={{ textAlign: 'right' }}>
              <h5>
                {' '}
                รวม{' '}
                {sumCoupon
                  ? sumCoupon?.toLocaleString()
                  : tempSumCoupon?.toLocaleString()}{' '}
                บาท
              </h5>
              <h5>
                {' '}
                ภาษี %{' '}
                {sumCoupon
                  ? (sumCoupon / 100) * 7
                  : (tempSumCoupon / 100) * 7}{' '}
                บาท
              </h5>
              <h5>
                {' '}
                ยอดรวมทั้งหมด{' '}
                {sumCoupon
                  ? (sumCoupon / 100) * 7 + sumCoupon
                  : (tempSumCoupon / 100) * 7 + tempSumCoupon}{' '}
                บาท
              </h5>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };
  const DetailsCouponModal = () => {
    const handleSave = () => {
      setisDetailsCoupon(false);
      ConfirmPassword();
    };
    const tenantName = tenants?.filter((tenant) => {
      return tenant?.tenantGUID === get(BuyCoupon, 'tenantGUID');
    });

    return (
      <Modal
        visible={isDetailsCoupon}
        width="60%"
        height="70%"
        title="Details Coupon"
        onOk={handleSave}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            className="theme-button-save"
            type="primary"
            onClick={handleSave}
          >
            ชำระเงิน
          </Button>,
        ]}
      >
        <Row align="middle" justify="center">
          <Col span={24} style={{ textAlign: 'center' }}>
            <h2>สรุปรายการสั่งซื้อ</h2>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={12} style={{ textAlign: 'left' }}>
            <h5>ชื่อบริษัท {tenantName ? tenantName[0]?.tenantName : ''}</h5>
            <h5>
              ที่อยู่บริษัท {tenantName ? tenantName[0]?.taxAddress : ''}{' '}
            </h5>
            <h5>
              เลขทะเบียนผู้เสียภาษี {tenantName ? tenantName[0]?.taxID : ''}
            </h5>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <h5>วันที่ {format(new Date(), 'dd/MM/yyyy')}</h5>
          </Col>
        </Row>
        <Row align="middle" justify="center" style={{ marginTop: 20 }}>
          <Col span={24}>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">รายละเอียด</th>
                  <th scope="col">ราคาต่อหน่อย</th>
                  <th scope="col">จำนวน</th>
                  <th scope="col">ราคา(บาท)</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'center' }}>
                  <td scope="row">จำนวนโควต้า</td>
                  <td scope="row">1</td>
                  <td scope="row">
                    {sumCoupon ? sumCoupon / 30 : tempSumCoupon / 30}
                  </td>
                  <td scope="row">
                    {sumCoupon
                      ? sumCoupon?.toLocaleString()
                      : tempSumCoupon?.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{ borderTop: '2px solid #0a4643', marginTop: 20 }}
        >
          <Col span={24} style={{ textAlign: 'right' }}>
            <h5>
              {' '}
              รวม{' '}
              {sumCoupon
                ? sumCoupon?.toLocaleString()
                : tempSumCoupon?.toLocaleString()}{' '}
              บาท
            </h5>
            <h5>
              {' '}
              ภาษี %{' '}
              {sumCoupon
                ? (sumCoupon / 100) * 7
                : (tempSumCoupon / 100) * 7}{' '}
              บาท
            </h5>
            <h5>
              {' '}
              ยอดรวมทั้งหมด{' '}
              {sumCoupon
                ? (sumCoupon / 100) * 7 + sumCoupon
                : (tempSumCoupon / 100) * 7 + tempSumCoupon}{' '}
              บาท
            </h5>
          </Col>
        </Row>
      </Modal>
    );
  };
  const addCoupon = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'post',
      url: `${process.env.REACT_APP_API_SAPI}/Stamp/AddStampQuotaHour`,
      headers: {
        Authorization:
          'Bearer ' +
          '011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7',
        'Content-Type': 'application/json',
      },
      data: {
        SiteGUID: `${process.env.REACT_APP_SITEGUID}`,
        TenantGUID: get(BuyCoupon, 'tenantGUID'),
        Quantity: get(isDetailsCoupon, 'tempCoupon'),
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.status === 200) {
          return <></>;
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };
  const columns1 = [
    {
      title: 'บริษัท',
      dataIndex: ['tenantGUID'],
      key: 'tenantGUID',
      align: 'center',
      className: 'theme-2-table-text',
      render: (tenantGUID) => {
        const tenant = tenants?.filter((tenant) => {
          return tenant?.tenantGUID === tenantGUID;
        });
        return (
          <>
            <p>{tenant ? tenant[0]?.tenantName : ''}</p>
          </>
        );
      },
    },
    {
      title: 'คงเหลือ',
      dataIndex: ['balance'],
      key: 'balance',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
    },
    {
      // title: "Action",
      key: 'id',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      render: (coupon) =>
        checkPermission('2') && (
          <Space size="middle">
            <Tooltip title="Buy" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faMoneyBillWave} />}
                type="text"
                onClick={() => {
                  setBuyCoupon({
                    tenantGUID: get(coupon, 'tenantGUID'),
                    balance: get(coupon, 'balance'),
                  });
                  setisBuyCoupon(true);
                }}
              />
            </Tooltip>
          </Space>
        ),
    },
  ];
  const columns = [
    {
      title: 'บริษัท',
      dataIndex: ['tenantGUID'],
      key: 'tenantGUID',
      align: 'center',
      className: 'theme-2-table-text',
      render: (tenantGUID) => {
        const tenant = tenants?.filter((tenant) => {
          return tenant?.tenantGUID === tenantGUID;
        });
        return (
          <>
            <p>{tenant ? tenant[0]?.tenantName : ''}</p>
          </>
        );
      },
    },
    {
      title: 'คงเหลือ',
      dataIndex: ['balance'],
      key: 'balance',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
    },
    {
      // title: "Action",
      key: 'id',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      render: (coupon) =>
        checkPermission('2') && (
          <Space size="middle">
            <Tooltip title="Buy" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faMoneyBillWave} />}
                type="text"
                onClick={() => {
                  setBuyCoupon({
                    tenantGUID: get(coupon, 'tenantGUID'),
                    balance: get(coupon, 'balance'),
                  });
                  setisBuyCoupon(true);
                }}
              />
            </Tooltip>
          </Space>
        ),
    },
  ];

  return (
    <div className="theme-2-content">
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card margin="20px 0">
            <Row justify="space-between">
              <Col span={4}>
                <p className="theme-2-text-1">{'All Coupon \xa0'}</p>
                <p className="theme-2-text-2">
                  {totalData ? totalData : 0}
                  {'\xa0Total coupon'}
                </p>
              </Col>
              <Col span={8}>
                <Col span={24} style={{ width: '100%', padding: '10px' }}>
                  {checkPermission('2') && (
                    <>
                      <Select
                        style={{ width: '100%' }}
                        suffixIcon={<SelectIcon />}
                        menuItemSelectedIcon={<Icon icon={faCheck} />}
                        placeholder="Search Organization"
                        onChange={(value) => {
                          fetchCouponTenant(value);
                          setIsCheck('1');
                        }}
                      >
                        {organizes.map((item) => {
                          return (
                            <Option key={item.id} value={item.tenant_guid}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </>
                  )}
                </Col>
              </Col>
            </Row>
            <Row justify="center">
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
              <Col xxl={20} xl={20} lg={20} md={20} sm={24} xs={24}>
                {checkPermission('2') && isCheck === '0' ? (
                  <CardTable
                    rowKey={(obj) => obj.id}
                    columns={columns}
                    dataSource={coupon}
                    bordered={false}
                    pagination={{
                      position: ['bottomCenter'],
                      total: totalData,
                    }}
                    size="middle"
                  />
                ) : (
                  <CardTable
                    rowKey={(obj) => obj.id}
                    columns={columns1}
                    dataSource={coupon1}
                    bordered={false}
                    pagination={{
                      position: ['bottomCenter'],
                      total: totalData,
                    }}
                    size="middle"
                  />
                )}
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <BuyCouponModal />
      <DetailsCouponModal />
      <ConfirmCouponModal />
    </div>
  );
}
