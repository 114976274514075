import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import styled from 'styled-components';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Card as BaseCard,
  Space,
  Select,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';

import { InputAvatar } from './InputAvatar';

const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgb(0 89 83);
`;

const cookies = new Cookies();

export const UpdateProfileForm = ({
  submitForm,
  initialValue,
  setImg1,
  manageAccess,
  type_from,
  initialValueParent,
  isCheckParent,
  departments,
  hasFieldChanged,
  handleCheckingChanges
}) => {
  const history = useHistory();
  const [updateProfileForm] = Form.useForm();

  const [image, setImage] = useState();

  const issuer_type = !!cookies.get('permission');

  const labelCol = {
    updateProfile: {
      xs: { span: 24 },
      sm: { span: 7 },
      md: { span: 7 },
      lg: { span: 7 },
      xl: { span: 7 },
      xxl: { span: 5 },
    },
    manageAccess: {
      xs: { span: 24 },
      sm: { span: 7 },
      md: { span: 10 },
      lg: { span: 9 },
      xl: { span: 8 },
      xxl: { span: 8 },
    },
  };

  const wrapperCol = {
    updateProfile: {
      xs: { span: 24 },
      sm: { span: 17 },
      md: { span: 18 },
      lg: { span: 20 },
      xl: { span: 20 },
      xxl: { span: 18 },
    },
    manageAccess: {
      xs: { span: 24 },
      sm: { span: 17 },
      md: { span: 18 },
      lg: { span: 20 },
      xl: { span: 20 },
      xxl: { span: 18 },
    },
  };

  const setImg = (url) => {
    setImage(url);
    setImg1(url);
    if (initialValue.portrait_photo != url) setIsChange(true);
    else setIsChange(false);
  };

  return (
    <Form
      name="updateProfileForm"
      form={updateProfileForm}
      labelAlign="left"
      onFinish={submitForm}
      labelCol={manageAccess ? labelCol.manageAccess : labelCol.updateProfile}
      wrapperCol={
        manageAccess ? wrapperCol.manageAccess : wrapperCol.updateProfile
      }
      initialValues={{
        email: get(initialValue, 'email'),
        firstName: get(initialValue, 'firstname'),
        lastName: get(initialValue, 'lastname'),
        telephone: get(initialValue, 'phone'),
        citizenId: get(initialValue, 'personal_id'),
        profilePicture: get(initialValue, 'portrait_photo'),
        remark: get(initialValue, 'remark'),
        tag_code: get(initialValue, 'tag_code'),
        tag_title: get(initialValue, 'tag_title'),
        department: get(initialValue, 'group_id'),
      }}
    >
      <Card
        title="User Profile"
        extra={
          <Row>
            {issuer_type && type_from === 'profile' && (
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <Form.Item style={{ paddingTop: '10%' }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          style={{ marginRight: '10px' }}
                        />
                      }
                      onClick={() => history.goBack()}
                    >
                      Back
                    </Button>
                    {hasFieldChanged && (
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="theme-button-save"
                        icon={
                          <FontAwesomeIcon
                            icon={faSave}
                            style={{ marginRight: '10px' }}
                          />
                        }
                      >
                        Save
                      </Button>
                    )}
                  </Space>
                </Form.Item>
              </Col>
            )}
            {issuer_type && type_from === 'profile' && (
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <Form.Item style={{ paddingTop: '35%' }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={<FontAwesomeIcon icon={faArrowLeft} />}
                      onClick={() => history.goBack()}
                    ></Button>
                    {hasFieldChanged && (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="theme-2-button-1"
                        icon={<FontAwesomeIcon icon={faSave} />}
                      ></Button>
                    )}
                  </Space>
                </Form.Item>
              </Col>
            )}
            {type_from === 'manageUser' && (
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <Form.Item style={{ paddingTop: '10%' }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          style={{ marginRight: '10px' }}
                        />
                      }
                      onClick={() => history.goBack()}
                    >
                      Back
                    </Button>
                    {hasFieldChanged && (
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="theme-button-save"
                        icon={
                          <FontAwesomeIcon
                            icon={faSave}
                            style={{ marginRight: '10px' }}
                          />
                        }
                      >
                        Save
                      </Button>
                    )}
                  </Space>
                </Form.Item>
              </Col>
            )}
            {type_from === 'manageUser' && (
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <Form.Item style={{ paddingTop: '35%' }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={<FontAwesomeIcon icon={faArrowLeft} />}
                      onClick={() => history.goBack()}
                    ></Button>
                    {hasFieldChanged && (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="theme-2-button-1"
                        icon={<FontAwesomeIcon icon={faSave} />}
                      ></Button>
                    )}
                  </Space>
                </Form.Item>
              </Col>
            )}
          </Row>
        }
      >
        <Row justify="center">
          <Col
            xxl={manageAccess ? 16 : 12}
            xl={manageAccess ? 20 : 12}
            lg={manageAccess ? 24 : 16}
            md={manageAccess ? 24 : 16}
            sm={24}
            xs={24}
          >
            <div className={isCheckParent ? 'parent-user' : ''}>
              <Row>
                <Col span={24}>
                  <Form.Item label="Profile Picture" name="profilePicture">
                    <InputAvatar
                      onChangeFn={setImg}
                      imageUrl={image ? image : initialValue.portrait_photo}
                      isuserPermission={initialValue}
                      userType={cookies.get('permission')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Email Address"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                      },
                      {
                        type: 'email',
                        message: 'Your email is incorrect.',
                      },
                      {
                        min: 8,
                        message: 'minimum length = 8',
                      },
                      {
                        max: 165,
                        message: 'maximum length = 165',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email Address"
                      style={{
                        width: '100%',
                        color: '#000',
                        cursor: 'not-allowed',
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      }}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Citizen ID"
                    name="citizenId"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your citizenID!',
                      },
                    ]}
                  >
                    <Input
                      readOnly
                      placeholder="Citizen ID"
                      style={{
                        width: '100%',
                        cursor: 'not-allowed',
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your firstname!',
                      },
                    ]}
                  >
                    <Input
                      placeholder="First Name"
                      onChange={(e) =>
                        handleCheckingChanges(e.target.value, 'firstname')
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your lastname!',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Last Name"
                      onChange={(e) =>
                        handleCheckingChanges(e.target.value, 'lastname')
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Telephone"
                    name="telephone"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your telephone number!',
                      },
                      {
                        pattern: '^[+]?[0-9]{9,12}$',
                        message: 'Your telephone number is incorrect format.',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Telephone"
                      onChange={(e) =>
                        handleCheckingChanges(e.target.value, 'phone')
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Employee ID"
                    name="tag_code"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your card number!',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Card Number"
                      onChange={(e) =>
                        handleCheckingChanges(e.target.value, 'tag_code')
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Mifare Card"
                    name="tag_title"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your true card!',
                      },
                    ]}
                  >
                    <Input
                      placeholder="True card"
                      onChange={(e) =>
                        handleCheckingChanges(e.target.value, 'tag_title')
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              {departments?.length > 0 && (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="department"
                      label="Department"
                      rules={[
                        {
                          required: true,
                          message: 'Please select department',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select department"
                        style={{ width: '100%' }}
                        onChange={(value) =>
                          handleCheckingChanges(value, 'group_id')
                        }
                      >
                        {departments.map((item) => (
                          <Select.Option
                            key={item.groupID}
                            value={item.groupID}
                          >
                            {item.groupName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {get(initialValue, 'remark') != null && (
                <Row>
                  <Col span={24}>
                    <Form.Item label="Remark" name="remark">
                      <Input
                        readOnly
                        value={get(initialValue, 'remark')}
                        style={{
                          width: '100%',
                          cursor: 'not-allowed',
                          backgroundColor: 'rgba(0,0,0,0.1)',
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>

            {isCheckParent && (
              <div>
                <Row>
                  <Col span={24}>
                    <h1>Parent user</h1>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label="E-mail">
                      <Input
                        readOnly
                        value={get(initialValueParent, 'email')}
                        placeholder="E-mail"
                        style={{
                          width: '100%',
                          cursor: 'not-allowed',
                          backgroundColor: 'rgba(0,0,0,0.1)',
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label="First name">
                      <Input
                        readOnly
                        value={get(initialValueParent, 'firstname')}
                        placeholder="First name"
                        style={{
                          width: '100%',
                          cursor: 'not-allowed',
                          backgroundColor: 'rgba(0,0,0,0.1)',
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Last name">
                      <Input
                        readOnly
                        value={get(initialValueParent, 'lastname')}
                        placeholder="Last name"
                        style={{
                          width: '100%',
                          cursor: 'not-allowed',
                          backgroundColor: 'rgba(0,0,0,0.1)',
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </Card>
    </Form>
  );
};
