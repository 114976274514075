import React from "react";
import { Row, Col } from "antd";
import BackgroundImage from "../assets/images/Theme_1.svg";
import LogoImage from "../assets/images/logo_ths.png";

export default function theme_1(props) {
  return (
    <div>
      <Row>
        <Col
          xxl={10}
          xl={10}
          lg={10}
          md={0}
          sm={0}
          xs={0}
          className="theme-1-left"
        >
          <img className="theme-1-backgroundImage" src={BackgroundImage} />
          <img className="theme-1-logoImages" src={LogoImage} />
        </Col>
        <Col
          xxl={14}
          xl={14}
          lg={14}
          md={24}
          sm={24}
          xs={24}
          className="theme-1-right"
        >
          {props.children}
        </Col>
      </Row>
    </div>
  );
}
