import React from "react";
import Theme from "../theme/theme_2";
import OrganizationManagement from "../components/OrganizationManagement";

export default function organizationManagement() {
  return (
    <div>
      <Theme PageName={"Organization Management"}>
        <OrganizationManagement />
      </Theme>
    </div>
  );
}
