import React from "react";
import Theme from "../theme/theme_2";
import ApprovalRequest from "../components/ApprovalRequest";

export default function approvalRequest() {
  return (
    <div>
      <Theme PageName={"Approval Request"}>
        <ApprovalRequest />
      </Theme>
    </div>
  );
}
