export const getRole = (id) => {
  switch (id) {
    case 1:
      return 'Project Owner';
    case 2:
      return 'Juristic Admin';
    case 3:
      return 'Reception';
    case 4:
      return 'Organize Admin';
    case 5:
      return 'Member';
    case 6:
      return 'Visitor';
    case 7:
      return 'Walk-in Visitor';
    case 8:
      return 'Seminar Visitor';
    default:
      return 'Nonmember';
  }
};
