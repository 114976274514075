import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { api } from '../services';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Image, Avatar } from 'antd';
import { Icon } from '../components';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

const swal = withReactContent(Swal);

export const UserAvatar = ({ fontSize, imgWidth, imageUrl }) => {
  return (
    <>
      {imageUrl && (
        <Avatar
          size={imgWidth ? imgWidth : 50}
          src={<Image src={imageUrl} />}
        />
      )}
      {!imageUrl && (
        <Icon icon={faUserCircle} fontSize={fontSize ? fontSize : '50px'} />
      )}
    </>
  );
};
