import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Card as BaseCard,
  Form,
  Select,
  DatePicker,
} from 'antd';
import Cookies from 'universal-cookie';
import { get } from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCheck, faSave } from '@fortawesome/free-solid-svg-icons';

import Theme from '../theme/theme_2';
import { api } from '../services';
import { Icon } from '../components';
import { getRole } from '../utils/helper';

const swal = withReactContent(Swal);

const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgb(0 89 83);
`;

const initialFieldChangeStatus = {
  parking_lot: false,
  end_date: false,
  start_date: false,
};

const EditCar = () => {
  const { goBack } = useHistory();
  const { carID } = useParams();
  const cookies = new Cookies();
  const [updateCarForm] = Form.useForm();

  const [data, setData] = useState();
  const [parkingLots, setParkingLots] = useState([]);
  const [fieldChanges, setFieldChanges] = useState(initialFieldChangeStatus);
  const hasFieldChanged = Object.values(fieldChanges).some((field) => field);

  const formatData = (data) => {
    const dataFormat = {
      id: data.id,
      name: data.user.firstname + '\xa0' + data.user.lastname,
      org_id: data.user.org_id,
      org_name: data.user.org_name,
      user_type: getRole(data.user.user_type),
      license_plate: data.license_plate_number,
      province: data.license_plate_province,
      parking_lot: data.parking_lot,
      end_date: moment(data.end_date),
      start_date: moment(data.start_date),
    };
    setData(dataFormat);
  };

  const handleCheckingChanges = (fieldName, value) => {
    const fieldStatuses = { ...fieldChanges };
    const isDate = moment(value, 'YYYY-MM-DD', true).isValid();

    if (isDate) {
      fieldStatuses[fieldName] = !data[fieldName].isSame(value, 'day');
    } else {
      fieldStatuses[fieldName] = data[fieldName] !== value;
    }

    setFieldChanges(fieldStatuses);
  };

  const fetchCarByID = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });

    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/cars/${carID}`,
      headers: {
        Authorization: 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json',
      },
    };

    api(option).then((res) => {
      if (res.data.code === 200) {
        formatData(get(res, 'data.data'));
      }
      global.store.dispatch({
        type: 'set_loading_show',
        data: { show: false },
      });
    });
  };

  const fetchParkingLot = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });

    const option = {
      method: 'post',
      url: `${process.env.REACT_APP_API_SAPI}/member/ListPermitBySite`,
      headers: {
        Authorization:
          'Bearer 011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7',
        'Content-Type': 'application/json',
      },
      data: {
        SiteGUID: `${process.env.REACT_APP_SITEGUID}`,
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.status === 200) {
          setParkingLots(res.data);
        }
      })
      .catch(() => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };

  useEffect(() => {
    fetchCarByID();
    fetchParkingLot();
  }, []);

  const renderTitle = (title) => (
    <Col span={12} align="end">
      <p sm="theme-2-text-3">{`${title}\xa0\xa0\xa0\xa0\xa0\xa0\xa0:`}</p>
    </Col>
  );

  const renderContentLayout = (title, content) => (
    <Row gutter={[24, 24]}>
      {renderTitle(title)}
      <Col span={12}>
        <p className="theme-2-text-4">{content}</p>
      </Col>
    </Row>
  );

  const onSaveChange = (data) => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });

    api({
      method: 'PUT',
      url: `${process.env.REACT_APP_API}/cars/${carID}`,
      headers: {
        Authorization: `Bearer ${cookies.get('accessToken')}`,
        'Content-Type': 'application/json',
      },
      data,
    })
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          fetchCarByID();
          setFieldChanges(initialFieldChangeStatus);
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Updated Successfully',
            showConfirmButton: true,
            confirmButtonColor: '#2F79FF',
            confirmButtonText: 'Close',
          });
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        swal.fire({
          icon: 'error',
          title: 'Updated Failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: '#2F79FF',
          confirmButtonText: 'Close',
        });
      });
  };

  return (
    <Theme PageName="Edit Car">
      <div className="theme-2-content">
        {data && parkingLots.length > 0 && (
          <Form
            name="updateCarForm"
            form={updateCarForm}
            onFinish={onSaveChange}
            initialValues={{
              parking_lot: get(data, 'parking_lot'),
              end_date: get(data, 'end_date'),
              start_date: get(data, 'start_date'),
            }}
          >
            <Card
              margin="20px 0"
              title="Edit Car"
              extra={
                <Row style={{ marginTop: '3%' }} justify="end">
                  <Button
                    type="default"
                    className="theme-2-button-2"
                    onClick={() => {
                      goBack();
                    }}
                    icon={
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={{ marginRight: '10px' }}
                      />
                    }
                  >
                    Back
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={!hasFieldChanged}
                    className="theme-button-save"
                    style={{ marginLeft: 10 }}
                    icon={
                      <FontAwesomeIcon
                        icon={faSave}
                        style={{ marginRight: '10px' }}
                      />
                    }
                  >
                    Save
                  </Button>
                </Row>
              }
            >
              {renderContentLayout('Name', get(data, 'name'))}
              {renderContentLayout('License Plate', get(data, 'license_plate'))}
              {renderContentLayout('Province', get(data, 'province'))}
              {renderContentLayout('Organization', get(data, 'org_name'))}
              {renderContentLayout('User Type', get(data, 'user_type'))}

              <Row gutter={[24, 24]}>
                {renderTitle('Parking Lot')}
                <Col span={12}>
                  <Form.Item
                    name="parking_lot"
                    rules={[
                      {
                        required: true,
                        message: 'Please input Parking Lot!',
                      },
                    ]}
                  >
                    <Select
                      name="parking_lot"
                      placeholder="Select Parking Lot"
                      style={{ width: '40%' }}
                      menuItemSelectedIcon={<Icon icon={faCheck} />}
                      onChange={(value) =>
                        handleCheckingChanges('parking_lot', value)
                      }
                    >
                      {parkingLots.map((item) => (
                        <Select.Option key={item.permitType} value={item.permitType}>
                          {item.permitName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                {renderTitle('Start Date')}
                <Col span={12}>
                  <Form.Item
                    name="start_date"
                    rules={[
                      {
                        required: true,
                        message: 'Please input Start Date!',
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: '40%' }}
                      placeholder="Select Start Date"
                      onChange={(value) =>
                        handleCheckingChanges('start_date', value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                {renderTitle('End Date')}
                <Col span={12}>
                  <Form.Item
                    name="end_date"
                    rules={[
                      {
                        required: true,
                        message: 'Please input End Date!',
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: '40%' }}
                      placeholder="Select End Date"
                      onChange={(value) =>
                        handleCheckingChanges('end_date', value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      </div>
    </Theme>
  );
};

export default EditCar;
