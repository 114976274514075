import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { api } from '../services';
import { checkPermission } from '../utils/checkPermission';
import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import styled from 'styled-components';
import { Row, Col, Space, Button } from 'antd';
import { Icon, Card, UserCard } from '../components';
import Modal from 'react-awesome-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faExclamationCircle,
  faEdit,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { ComparePassword } from './ComparePassword';
import Cookies from 'universal-cookie';

const JuristicAdminListWrapper = styled.div`
  margin-bottom: 10px;
  overflow: scroll;
`;

const swal = withReactContent(Swal);

export default function Building() {
  const history = useHistory();
  const { buildingID } = useParams();
  const [buildingInfo, setBuildingInfo] = useState();
  const [buildingInfoJuristic, setBuildingJuristicInfo] = useState();
  const [cfExitFloor, setCFExitFloor] = useState(false);
  const [exitFloor, setExitFloor] = useState();
  const [oldExitFloor, setOldExitFloor] = useState();
  const cookies = new Cookies();
  const onDeleteBuildingSuccess = () => {
    history.push('/project');
  };

  const getBuildingInfo = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/buildings/${buildingID}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          setBuildingInfo(get(res, 'data.data'));
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };
  const getBuildingJuristicInfo = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/users/juristic_admin?building_id=${buildingID}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          setBuildingJuristicInfo(get(res, 'data.data'));
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };
  useEffect(() => {
    getBuildingInfo();
    getBuildingJuristicInfo();
  }, []);

  const BuildingSection = () => {
    return (
      <Card margin="20px 0">
        <Row gutter={[0, 0]}>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            {buildingInfo && (
              <>
                <div>
                  <Space>
                    <p className="theme-2-text-3">{'Building Name : \xa0'}</p>
                    <p className="theme-2-text-4">
                      {get(buildingInfo, 'name')}
                    </p>
                  </Space>
                </div>
                <div>
                  <Space>
                    <p className="theme-2-text-3">{'Telephone : \xa0'}</p>
                    <p className="theme-2-text-4">
                      {get(buildingInfo, 'phone')}
                    </p>
                  </Space>
                </div>
                <div>
                  <p className="theme-2-text-3">{'Juristic Admin : \xa0'}</p>
                  {buildingInfoJuristic && <JuristicAdminList />}
                </div>
              </>
            )}
            {checkPermission('1') && (
              <Row justify="center" gutter={[0, 8]}>
                <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                  <Button
                    className="theme-2-button-3"
                    block
                    icon={<Icon margin="0 10px 0 0" icon={faEdit} />}
                    onClick={() => {
                      history.push(`/editbuilding/${buildingID}`);
                    }}
                  >
                    Edit Building
                  </Button>
                </Col>
              </Row>
            )}
            {checkPermission('1') && (
              <Row justify="center" gutter={[0, 8]}>
                <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                  <Button
                    className="theme-2-button-4"
                    type="primary"
                    block
                    icon={
                      <Icon color="white" margin="0 10px 0 0" icon={faTrash} />
                    }
                    onClick={() =>
                      ComparePassword(
                        'building',
                        buildingInfo,
                        onDeleteBuildingSuccess,
                        ''
                      )
                    }
                  >
                    Delete Building
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Card>
    );
  };

  const JuristicAdminList = () => {
    return (
      <JuristicAdminListWrapper>
        <Space>
          {buildingInfoJuristic.map((item) => {
            return (
              <UserCard
                key={get(item, 'id')}
                user={{
                  id: get(item, 'id'),
                  firstname: get(item, 'firstname'),
                  lastname: get(item, 'lastname'),
                  email: get(item, 'email'),
                  telephone_number: get(item, 'phone'),
                  face_img: get(item, 'portrait_photo'),
                }}
              />
            );
          })}
        </Space>
      </JuristicAdminListWrapper>
    );
  };

  return (
    <div className="theme-2-content">
      <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
        <Row justify="end" style={{ paddingTop: '2%' }}>
          <Button
            type="default"
            className="theme-2-button-2"
            onClick={() => {
              history.goBack();
            }}
            icon={
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ marginRight: '10px' }}
              />
            }
          >
            Back
          </Button>
        </Row>
      </Col>
      <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
        <Row justify="end" style={{ paddingTop: '5%' }}>
          <Button
            type="default"
            className="theme-2-button-2"
            onClick={() => {
              history.goBack();
            }}
            icon={
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ marginRight: '10px' }}
              />
            }
          >
            Back
          </Button>
        </Row>
      </Col>
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <BuildingSection />
        </Col>
        <Modal
          visible={cfExitFloor}
          width="400"
          height="300"
          effect="fadeInDown"
        >
          <Row
            justify="center"
            align="middle"
            style={{ height: '100%', padding: '20px' }}
          >
            <Icon fontSize="60px" icon={faExclamationCircle} />
            <h1 style={{ textAlign: 'center' }}>
              Are you sure you want to set floor "{get(exitFloor, 'floor_name')}
              " as exit floor?
            </h1>
            <Space>
              <Button onClick={() => setCFExitFloor(false)}>Cancle</Button>
              <Button onClick={() => handleSetExitFloor(exitFloor)}>
                Confirm
              </Button>
            </Space>
          </Row>
        </Modal>
      </Row>
    </div>
  );
}
