import React, { createContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import { api } from '../services';
import get from 'lodash/get';
import { isBefore } from 'date-fns';
import Avatar from 'antd/lib/avatar/avatar';
import { useHistory } from 'react-router-dom';
const cookies = new Cookies();

const AuthenContext = createContext();
export default AuthenContext;

export function ProvideAuth(props) {
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState();
  const [avatar, setAvatar] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [lastUrl, setLastUrl] = useState();
  const history = useHistory();
  const asyncLocalStorage = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    },
  };

  const getAvatar = (accessToken) => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/users/profile`,
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.message === 'success') {
          setAvatar(res.data.data.portrait_photo);
          setUser(res.data);
          setUserInfo(res.data);
          asyncLocalStorage
            .setItem('avatar', res.data.data.portrait_photo ? res.data.data.portrait_photo:'')
            .then(function () {
              return asyncLocalStorage.getItem('avatar');
            });
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
          logout();
          cookies.set('expiresAtTime', true);
      });
  };

  const login = (option) => {
    return api(option);
  };

  const getExpireTime = (token) => {
    return token;
  };

  const setTokenCookie = (accessToken, refreshToken,expires_in) => {
     var date = new Date();
     date.setTime(date.getTime() + (60*60*24*365));
     const decode = jwtDecode(accessToken);

     cookies.set('accessToken', accessToken, { path: '/', expires: date })
     cookies.set('expiresAt', date, { path: '/', expires: date })
     cookies.set('refreshToken', refreshToken, { path: '/', expires: date })
     cookies.set('preferred_username', decode.preferred_username, { path: '/', expires: date })

    cookies.set('accessToken', accessToken, {
      path: '/',
      expires: date,
    });
    cookies.set('expiresAt', getExpireTime(date), {
      path: '/',
      expires: getExpireTime(date),
    });
    cookies.set('refreshToken', refreshToken, {
      path: '/',
     expires: getExpireTime(date),
    });
    cookies.set('preferred_username', decode.preferred_username, {
      path: '/',
      expires: date,
    });
  };

  const setPermissionCookie = (permission, refreshToken,expires_in) => {
    var date = new Date();
     date.setTime(date.getTime() + (60*60*24*365));
    cookies.set('permission', permission, {path: '/',expires: date});
  };

  const getAccessToken = () => {
    const accessToken = cookies.get('accessToken');
    if (accessToken) {
      return accessToken;
    } else {
      return false;
    }
  };

  const resetProfile = () => {
    cookies.remove('accessToken', { path: '/' });
    cookies.remove('refreshToken', { path: '/' });
    cookies.remove('permission', { path: '/' });
    cookies.remove('expiresAt', { path: '/' });
    cookies.remove('preferred_username', { path: '/' });
    localStorage.removeItem('avatar');
    setUser(null);
    setIsAuthenticated(false);
  };

  const logout = () => {
    resetProfile();
  };

  const decodeToken = (accessToken, refreshToken,expires_in) => {
   
    if (accessToken) {
      var date = new Date();
      date.setTime(date.getTime() + (60*60*24*365));
      const decode = jwtDecode(accessToken);
      var expiryDate = new Date(Date.now() + (expires_in));
      const {
        preferred_username,
        first_name: firstName,
        last_name: lastName,
        user_id: userID,
        project_code: projectCode,
        user_type,
      } = decode;
      const data = {
        email : preferred_username,
        firstName :preferred_username,
        lastName:preferred_username,
        userID:preferred_username,
        projectCode:preferred_username,
        expTime:expires_in,
        permissions:user_type,
        accessToken,
        refreshToken,
      };
      cookies.set('expiresAt', date, { path: '/' });
      return data;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const accessToken = cookies.get('accessToken');
    const refreshToken = cookies.get('refreshToken');
    const checkIsAuthenticated = () => {
      if (accessToken && refreshToken) {
        decodeToken(accessToken, refreshToken);
        getAvatar(accessToken);
        const expiresAt = cookies.get('expiresAt');
        const isExpired = isBefore(Date.now(), new Date(expiresAt));

        if (isExpired) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } else {
        resetProfile();
      }
    };
    checkIsAuthenticated();
  }, []);

  return (
    <AuthenContext.Provider
      value={{
        login,
        logout,
        getAccessToken,
        isAuthenticated,
        decodeToken,
        userInfo,
        user,
        setUser,
        resetProfile,
        getExpireTime,
        setTokenCookie,
        setPermissionCookie,
        isSessionExpired,
        setIsSessionExpired,
        lastUrl,
        setLastUrl,
        getAvatar,
        avatar,
      }}
    >
      {props.children}
    </AuthenContext.Provider>
  );
}
