import { api } from "../services";

export const getAllPromoContent = (dispatch, data) => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API}/news-feed/${data.id}`,
    params: {
      pc: process.env.REACT_APP_PROJECT_CODE,
      lang: data.lang,
    },
  };
  return api(config)
    .then((res) => {
      if (res.data.status === "success") {
        dispatch({
          type: "set_promo_content",
          data: res.data.payload,
        });
      }
    })
};

export const setNewPromoContent = (dispatch, data) => {
  return dispatch({
    type: "set_new_promo_content",
    data: data,
  });
};
export const setPromoContentAtCurrLang = (dispatch, data) => {
  return dispatch({
    type: "set_promo_content_at_curr_lang",
    data: data,
  });
};

export const setPromoContent = (dispatch, data) => {
  return dispatch({
    type: "set_promo_content",
    data: data,
  });
};

export const clearPromoContent = (dispatch) => {
  return dispatch({
    type: "clear_promo_content",
  });
};

export const setExpiredDate = (dispatch, data) => {
  return dispatch({
    type: "set_expired_date",
    data: data,
  });
};
export const setPromoContentID = (dispatch, data) => {
  return dispatch({
    type: "set_promo_content_id",
    data: data,
  });
};
