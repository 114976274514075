import React from 'react';
import Theme from '../theme/theme_2';
import EntryExitLog from '../components/EntryExsitLog';
function entryExsitManagement() {
  return (
    <div>
      <Theme PageName={'Parking Entry Exit Log'}>
        <EntryExitLog />
      </Theme>
    </div>
  );
}
export default entryExsitManagement;
