import { combineReducers } from "redux";
import Loading from "./Loading";
import Language from "./Language";
import NewsFeed from "./NewsFeed";
import Promotion from "./Promotion";

export default combineReducers({
  Loading: Loading,
  Language: Language,
  NewsFeed: NewsFeed,
  Promotion: Promotion,
});
