import React from "react";
import Theme from "../theme/theme_2";
import ViewOrganization from "../components/ViewOrganization";

export default function viewOrganization() {
  return (
    <div>
      <Theme PageName={"View Organization"}>
        <ViewOrganization />
      </Theme>
    </div>
  );
}
