import React from "react";
import Theme from "../theme/theme_2";
import BlackList from "../components/BlackList";

export default function blackList() {
  return (
    <div>
      <Theme PageName={"Black List"}>
        <BlackList />
      </Theme>
    </div>
  );
}
