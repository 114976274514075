import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'universal-cookie';

import AuthenContext from '../utils/useAuthentication';
import { api } from '../services';
import Theme from '../theme/theme_2';
import { UpdateProfileForm, DataNotFound } from '../components';

const swal = withReactContent(Swal);
const cookies = new Cookies();

const initialFieldChangeStatus = {
  firstname: false,
  lastname: false,
  tag_code: false,
  tag_title: false,
  phone: false,
};

export default function UpdateUserProfile() {
  const history = useHistory();
  const { user, setUser } = useContext(AuthenContext);
  const [initialValue, setInitialValue] = useState();
  const [initialValueIMG, setInitialValueIMG] = useState();
  const [error, setError] = useState(false);

  const [fieldChanges, setFieldChanges] = useState(initialFieldChangeStatus);
  const hasFieldChanged = Object.values(fieldChanges).some((field) => field);

  const handleCheckingChanges = (value, fieldName) => {
    const fieldStatuses = { ...fieldChanges };
    fieldStatuses[fieldName] = initialValue[fieldName] !== value;

    setFieldChanges(fieldStatuses);
  };

  const getUserInfo = (userID) => {
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/users/${userID}`,
      headers: {
        Authorization: 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json',
      },
    };
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          setError(false);
          setInitialValue(get(res, 'data.data'));
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };
  useEffect(() => {
    if (get(user, 'data.id')) {
      getUserInfo(get(user, 'data.id'));
    }
  }, [get(user, 'data.id')]);
  const onUpdateUserSuccess = (values) => {
    setUser({
      ...user,
      firstName: values.firstname,
      lastName: values.lastname,
      telephone: values.phone,
    });
    swal.fire({
      icon: 'success',
      title: 'Success',
      text: `your profile was successfully edited.`,
      showConfirmButton: true,
      confirmButtonColor: '#2F79FF',
      confirmButtonText: 'Close',
    });
    history.push('/project');
    window.location.reload();
  };
  const submitForm = (fieldsValue) => {
    let values = {
      firstname: fieldsValue['firstName'],
      lastname: fieldsValue['lastName'],
      phone: fieldsValue['telephone'],
      portrait_photo: '',
    };
    if (initialValueIMG) values.portrait_photo = initialValueIMG;
    else values.portrait_photo = initialValue.portrait_photo;
    const option = {
      method: 'put',
      url: `${process.env.REACT_APP_API}/users/${get(user, 'data.id')}`,
      headers: {
        Authorization: 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json',
      },
      data: values,
    };
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          onUpdateUserSuccess(res, values);
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        swal.fire({
          icon: 'error',
          title: 'Updated failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: '#2F79FF',
          confirmButtonText: 'Close',
        });
      });
  };
  return (
    <div>
      <Theme PageName={'Edit Profile'}>
        {initialValue && get(user, 'data.id') && (
          <UpdateProfileForm
            userID={get(user, 'data.id')}
            submitForm={submitForm}
            initialValue={initialValue}
            setImg1={(e) => setInitialValueIMG(e)}
            type_from={'profile'}
            hasFieldChanged={hasFieldChanged}
            handleCheckingChanges={handleCheckingChanges}
          />
        )}
        {error && !initialValue && !get(user, 'data.id') && <DataNotFound />}
      </Theme>
    </div>
  );
}
