const loading = {
  show: false,
};

export default function Loading(state = loading, action) {
  switch (action.type) {
    case "set_loading_show":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
