import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import styled from 'styled-components';
import { Row, Col, Card as BaseCard } from 'antd';
import Cookies from 'universal-cookie';

import { api } from '../services';
import { UpdateProfileForm, DataNotFound } from '../components';

const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgb(0 89 83);
`;

const swal = withReactContent(Swal);
const cookies = new Cookies();

const initialFieldChangeStatus = {
  firstname: false,
  lastname: false,
  tag_code: false,
  tag_title: false,
  group_id: false,
  phone: false,
};

export default function ManageAccess({ userID }) {
  const [errorUser, setErrorUser] = useState(false);
  const [initialValue, setInitialValue] = useState();
  const [initialValueIMG, setInitialValueIMG] = useState();
  const [isCheckParent, setisCheckParent] = useState(false);
  const [initialValueParent, setInitialValueParent] = useState();
  const [departments, setDepartments] = useState([]);

  const [fieldChanges, setFieldChanges] = useState(initialFieldChangeStatus);
  const hasFieldChanged = Object.values(fieldChanges).some((field) => field);

  const handleCheckingChanges = (value, fieldName) => {
    const fieldStatuses = { ...fieldChanges };
    fieldStatuses[fieldName] = initialValue[fieldName] !== value;

    setFieldChanges(fieldStatuses);
  };

  const getUserInfo = () => {
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/users/${userID}`,
      headers: {
        Authorization: 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json',
      },
    };
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          setErrorUser(false);
          setInitialValue(get(res, 'data.data') ? get(res, 'data.data') : []);
          getUserInfoParent(get(res, 'data.data.parent_id'));
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };

  const getUserInfoParent = (userID) => {
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/users/${userID}`,
      headers: {
        Authorization: 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json',
      },
    };
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          setErrorUser(false);
          setInitialValueParent(
            get(res, 'data.data') ? get(res, 'data.data') : []
          );
          setisCheckParent(true);
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        setisCheckParent(false);
      });
  };

  const fetchDepartments = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'post',
      url: `${process.env.REACT_APP_API_SAPI}/member/LoadGroups`,
      headers: {
        Authorization:
          'Bearer 011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7',
        'Content-Type': 'application/json',
      },
      data: {
        SiteGUID: process.env.REACT_APP_SITEGUID,
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.status === 200) {
          setDepartments(res.data);
        }
      })
      .catch(() => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };

  useEffect(() => {
    getUserInfo();
    fetchDepartments();
  }, []);

  const onUpdateUserSuccess = (values) => {
    swal.fire({
      icon: 'success',
      title: 'Success',
      text: `User "${
        get(values, 'firstname') + ' ' + get(values, 'lastname')
      }" is successfully edited.`,
      showConfirmButton: true,
      confirmButtonColor: '#2F79FF',
      confirmButtonText: 'Close',
    });
  };

  const submitForm = (fieldsValue) => {
    let values = {
      firstname: fieldsValue['firstName'],
      lastname: fieldsValue['lastName'],
      phone: fieldsValue['telephone'],
      portrait_photo: '',
      tag_code: fieldsValue['tag_code'],
      tag_title: fieldsValue['tag_title'],
      group_id: fieldsValue['department'],
    };
    if (initialValueIMG) values.portrait_photo = initialValueIMG;
    else values.portrait_photo = initialValue.portrait_photo;
    const option = {
      method: 'put',
      url: `${process.env.REACT_APP_API}/users/${userID}`,
      headers: {
        Authorization: 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json',
      },
      data: values,
    };
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          const option = {
            method: 'POST',
            url: `${process.env.REACT_APP_API}/approvals/${res.data.data.approval_id}/approve`,
          };
          api(option).then((res) => {
            global.store.dispatch({
              type: 'set_loading_show',
              data: { show: false },
            });
            if (res.data.code === 200) {
              getUserInfo();
              setFieldChanges(initialFieldChangeStatus);
              onUpdateUserSuccess(values);
            }
          });
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        swal.fire({
          icon: 'error',
          title: 'Updated failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: '#2F79FF',
          confirmButtonText: 'Close',
        });
      });
  };

  return (
    <div>
      <div className="theme-2-content">
        <Row gutter={[8, 8]}>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            {initialValue && (
              <UpdateProfileForm
                manageAccess
                userID={userID}
                submitForm={submitForm}
                initialValue={initialValue}
                initialValueParent={initialValueParent}
                setImg1={(e) => setInitialValueIMG(e)}
                type_from={'manageUser'}
                isCheckParent={isCheckParent}
                departments={departments}
                handleCheckingChanges={handleCheckingChanges}
                hasFieldChanged={hasFieldChanged}
              />
            )}
            {errorUser && !initialValue && (
              <Card title="User Profile">
                <DataNotFound manageAccess />
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}
