import React from "react";
import { Layout } from "antd";

const { Sider } = Layout;

export default function NavBar({ MainMenu }) {
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      trigger={null}
      className="theme-2-side"
    >
      <MainMenu />
    </Sider>
  );
}
