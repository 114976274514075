import React from "react";
import Theme from "../theme/theme_2";
import { ConsentForm } from "../components";

export default function ConsentManagement() {
  return (
    <div>
      <Theme PageName={"Create Consent"}>
        <ConsentForm formType="create" />
      </Theme>
    </div>
  );
}
