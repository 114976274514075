import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Theme from "../theme/theme_2";
import { OrganizationForm } from "../components";
import Cookies from 'universal-cookie';

const swal = withReactContent(Swal);
const cookies = new Cookies();

export default function CreateOrganization() {
  const history = useHistory();
  const [initialRate, setInitialRate] = useState();
  const createOrganizeSuccess = (values, data) => {
    console.log("data", data.data.tenant_guid);
    const option = {
      method: "post",
      url: `${process.env.REACT_APP_API_SAPI}/Stamp/AddStampQuotaHour`,
      headers: {
        'Authorization': 'Bearer ' + "011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7",
        'Content-Type': 'application/json'
      },
      data: {
        "SiteGUID": `${process.env.REACT_APP_SITEGUID}`,
        "TenantGUID": data.data.tenant_guid,
        "Quantity": 0
      }
    }
    api(option)
      .then((res) => {
        if (res) {
          swal.fire({
            icon: "success",
            title: "Organize added",
            text: `Organize "${values.name}" is successfully added`,
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
          history.push("/management/organization");
        }
      })
      .catch((err) => {
      });

  };

  const submitForm = (fieldsValue) => {
    if (initialRate == "") {

    } else {
      const values = {
        name: get(fieldsValue, "organizeName"),
        building_id: parseInt(get(fieldsValue, "building")),
        org_admins: get(fieldsValue, "organizeAdmin"),
        tax_id: get(fieldsValue, "taxID"),
        rate_templates: initialRate
      };
      const option = {
        method: "post",
        url: `${process.env.REACT_APP_API}/organizations`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
        data: values,
      };
      global.store.dispatch({ type: "set_loading_show", data: { show: true } });
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });

          if (res.data.code === 201) {
            createOrganizeSuccess(values, res.data);
          }
        })
        .catch((err) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          swal.fire({
            icon: "error",
            title: 'Created failed',
            text: get(err.response.data, 'message'),
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
        });
    }

  };
  return (
    <div>
      <Theme PageName={"Create Organization"}>
        <OrganizationForm type="create" submitForm={submitForm} initialRate={(e) => setInitialRate(e)} />
      </Theme>
    </div>
  );
}
