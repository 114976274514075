import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuthenContext from '../utils/useAuthentication';
import { Dropdown, Menu } from 'antd';
import { Icon } from '../components';
import {
  faUser,
  faUserCircle,
  faSignOutAlt,
  faKey,
} from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';
import { ChangePasswordModal } from '../components';
const cookies = new Cookies();
export const HeadBarAvatar = ({ fontSize }) => {
  const history = useHistory();
  const { logout, avatar } = useContext(AuthenContext);
  const avatarStorage = localStorage.getItem('avatar');

  const [avatarState, setAvatarState] = React.useState('');
  const [isChangaPassword, setIsChangaPassword] = useState(false);

  const handleLogout = () => {
    logout();
    cookies.set('expiresAtTime', false);
    history.push('/login');
  };

  const avatarMenu = (
    <Menu>
      <Menu.Item
        icon={<Icon margin="10px 10px 0 5px" icon={faUser}  />}
        onClick={() => history.push('/updateprofile')}
      >
        Edit User Profie
      </Menu.Item>
      <Menu.Item
        icon={<Icon margin="10px 10px 0 5px" icon={faKey} />}
        onClick={() => setIsChangaPassword(true)}
      >
        Change Password
      </Menu.Item>
      <Menu.Item
        icon={<Icon margin="10px 10px 0 5px" icon={faSignOutAlt} />}
        onClick={handleLogout}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {avatar && (
        <Dropdown
          overlay={avatarMenu}
          placement="bottomCenter"
          arrow
          trigger={['click']}
        >
          <img
            style={{ borderRadius: '50%', objectFit: 'cover' }}
            alt="Avatar"
            height="30px"
            width="30px"
            margin="0 0 15px 0"
            src={avatar}
          />
        </Dropdown>
      )}
      {!avatar && (
        <Dropdown
          overlay={avatarMenu}
          placement="bottomCenter"
          arrow
          trigger={['click', 'hover']}
        >
          <Icon icon={faUserCircle} fontSize={fontSize ? fontSize : '25px'} id='faUserCircle'/>
        </Dropdown>
      )}
      <ChangePasswordModal
        visible={isChangaPassword}
        onCancel={() => setIsChangaPassword(false)}
        onLogout={() => handleLogout()}
      />
    </>
  );
};
