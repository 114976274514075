import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { api } from '../services';
import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Theme from '../theme/theme_2';
import { OrganizationForm, DataNotFound } from '../components';
import Cookies from 'universal-cookie';

const swal = withReactContent(Swal);
const cookies = new Cookies();

export default function EditOrganization() {
  const { organizeID } = useParams();
  const history = useHistory();
  const [initialValue, setInitialValue] = useState();
  const [initialOrgAdmin, setInitialOrgAdmin] = useState();
  const [initialRate, setInitialRate] = useState();
  const [initialRateStamp, setInitialRateStamp] = useState([]);
  const [initialStamp, setInitialStamp] = useState();
  const [errorAlert, setErrorAlert] = useState(false);

  const getInitialStamp = () => {
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/organizations/${organizeID}/stamp_template`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          setErrorAlert(false);
          setInitialStamp(get(res, "data.data") ? get(res, "data.data") :[]);
        }
      })
  }
  const getrateStamp = () =>{
    const option = {
      method: "post",
      url: `${process.env.REACT_APP_API_SAPI}/Stamp/ListRateTemplate`,
      headers: {
        'Authorization': 'Bearer ' + "011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7",
        'Content-Type': 'application/json'
      },
      data: {
        "SiteGUID": `${process.env.REACT_APP_SITEGUID}`,
      }
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.status === 200) {         
          setInitialRateStamp(res.data ? res.data : []);
        }
      });
  }
  const getInitialValue = () => {
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/organizations/${organizeID}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          setErrorAlert(false);
          getOrgAdmin(get(res, 'data.data'));
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };

  const getOrgAdmin = (data) => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const o = {
      method: "get",
      url: `${process.env.REACT_APP_API}/users/org_admin?org_id=${organizeID}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    }
    api(o)
      .then((res) => {
        if(res.data.code === 200)
        {
          setInitialOrgAdmin(get(res, 'data.data'));
          const values = get(res, 'data.data');
          let admin = [];
          if(values) {
            admin = values.map((item) => {
              return item.id;
            })
          }
          setInitialValue({
            organizeName: data.name,
            building: data.building_id,
            taxID: data.tax_id,
            organizeAdmin: admin,
          });
        }
      })
    }

  useEffect(() => {
    getrateStamp();
    getInitialValue();
    getInitialStamp();
  }, []);

  const editOrganizeSuccess = (values) => {
    swal.fire({
      icon: 'success',
      title: 'Organize edited',
      text: `Organize "${values.name}" is successfully edited`,
      showConfirmButton: true,
      confirmButtonColor: '#2F79FF',
      confirmButtonText: 'Close',
    });
    history.push('/management/organization');
  };

  const submitForm = (fieldsValue) => {
    if (initialRate == "") {
    
    }else{
      const values = {
        name: get(fieldsValue, "organizeName"),
        building_id: parseInt(get(fieldsValue, "building")),
        org_admins: get(fieldsValue, "organizeAdmin"),
        tax_id:get(fieldsValue,"taxID"),
        rate_templates: initialRate
      };
      
    const option = {
      method: 'put',
      url: `${process.env.REACT_APP_API}/organizations/${organizeID}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
      data: values,
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          editOrganizeSuccess(values, res);
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: "error",
          title: 'Updated failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: "#2F79FF",
          confirmButtonText: "Close",
        });
      });
    }
  };

  return (
    <div>
      <Theme PageName={'Edit Organization'}>
        {initialValue && initialOrgAdmin && (
          <OrganizationForm
            type="edit"
            initialValue={initialValue}
            initialOrgAdmin={initialOrgAdmin}
            initialStamp={initialStamp}
            initialRate={(e)=>setInitialRate(e)}
            initialRateStamp = {initialRateStamp}
            submitForm={submitForm}
          />
        )}
        {errorAlert && !initialValue && !initialOrgAdmin && <DataNotFound />}
      </Theme>
    </div>
  );
}
