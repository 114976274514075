import { useState } from 'react';
import styled from "styled-components";
import { Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const { Option } = Select

const StyledSelect = styled(Select)`
    color: ${props => props.theme.secondaryText};
    font-size: 20px;
    .ant-select-arrow{
        font-size: 20px;
        display: flex;
        align-items: center;
    }
`;
const DisplayItem = styled.div`
    color: ${props => props.theme.secondaryText};
    display: flex;
    justify-content: center;
`;

export const LanguageSelector = () => {
    const [selectedLang, setSelectLanguage] = useState('en');
    const changeLanguage = (value) => {
        setSelectLanguage(value)
    }
    const allLang = [
        {
            value: 'en',
            text: 'EN'
        },
        {
            value: 'th',
            text: 'TH'
        }
    ]
    return (
        <StyledSelect
            onChange={changeLanguage}
            value={selectedLang}
            bordered={false}
            showArrow
            suffixIcon={<FontAwesomeIcon icon={faCaretDown} />}
        >
            {
                allLang.map(lang => {
                    return (
                        <Option key={lang.value} value={lang.value}><DisplayItem>{lang.text}</DisplayItem></Option>
                    );
                })
            }
        </StyledSelect>
    );
}