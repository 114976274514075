import React, { useState, useEffect,useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from '../services';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AuthenContext from '../utils/useAuthentication';
import { QRCode } from 'react-qrcode-logo';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Card as BaseCard,
  Select,
  Space,
  Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';
const { Option, OptGroup } = Select;

const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgb(0 89 83);
`;

const swal = withReactContent(Swal);

const QRWrapper = styled.div`
  padding: 10px;
  background: ${(props) => props.theme.white};
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const cookies = new Cookies();

export default function RegisterNewUser() {
  const history = useHistory();
  const [registerNewUserForm] = Form.useForm();
  const { user } = useContext(AuthenContext);
  const [data, setData] = useState();
  const [link, setLink] = useState('');
  const [linkExpo, setLinkExpo] = useState('');
  const onFinish = (fieldsValue) => {
  let org_id = user.data.org_id;
  const option = {
    method: 'POST',
    url: `${process.env.REACT_APP_API}/invitation/member`,
    headers:{
      'Authorization': 'Bearer '+cookies.get('accessToken'),
      'Content-Type': 'application/json'
    },
    data: {"email": fieldsValue['email'],
    "org_id": org_id} ,
    
  };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          swal.fire({
            icon: 'success',
            title: get(res, 'data.message'),
            text: "Sent information to email success",
            showConfirmButton: true,
            confirmButtonColor: '#2F79FF',
            confirmButtonText: 'Close',
          }).then((result) => {
            if (result['isConfirmed']){
              history.push('/project');
            }
          })
        } else {
          swal.fire({
            icon: 'error',
            title: get(res, 'data.message'),
            text: get(res, 'data.data'),
            showConfirmButton: true,
            confirmButtonColor: '#2F79FF',
            confirmButtonText: 'Close',
          });
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: "error",
          title: 'Created failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: "#2F79FF",
          confirmButtonText: "Close",
        });
      });
  };

  return (
    <div className="theme-2-content">
      <Form
        name="registerNewUserForm"
        form={registerNewUserForm}
        labelAlign="left"
        onFinish={onFinish}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 7 },
          md: { span: 7 },
          lg: { span: 7 },
          xl: { span: 7 },
          xxl: { span: 5 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 17 },
          md: { span: 18 },
          lg: { span: 20 },
          xl: { span: 20 },
          xxl: { span: 18 },
        }}
        initialValues={{ oneTime: false }}
      >
        <Card
          title="Invite Information"
          extra={
            <Row>
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <Form.Item style={{ paddingTop: '15%' }}>
                  <Space>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="theme-2-button-1"
                      icon={
                        <FontAwesomeIcon
                          icon={faSave}
                          style={{ marginRight: '10px',color: 'white' }}
                        />
                      }
                    >
                      Send Invite
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <Form.Item style={{ paddingTop: '100%' }}>
                  <Space>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="theme-2-button-1"
                      icon={<FontAwesomeIcon icon={faSave} />}
                    ></Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          }
        >
          <Row justify="center">
            <Col xxl={12} xl={12} lg={16} md={16} sm={24} xs={24}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                      },
                      {
                        type: 'email',
                        message: 'Your email is incorrect.',
                      },
                      {
                        min: 8,
                        message: 'minimum length = 8',
                      },
                      {
                        max: 165,
                        message: 'maximum length = 165',
                      },
                    ]}
                  >
                    <Input placeholder="Email" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                {link !== '' && (
                  <div style={{ marginTop: 30 }}>
                    <Typography.Title level={5}>
                      URL for register with Application
                    </Typography.Title>
                    <QRWrapper>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <div>
                          <Input
                            onChange={() => {}}
                            value={link}
                            style={{ marginBottom: 10, width: '100%' }}
                          />
                          <QRCode
                            size={400}
                            value={link}
                            logoWidth={'100'}
                            logoHeight={'100'}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '5px',
                        }}
                      ></div>
                    </QRWrapper>
                  </div>
                )}
              </Row>

              <Row>
                {linkExpo !== '' && (
                  <div style={{ marginTop: 30 }}>
                    <Typography.Title level={5}>
                      URL for register with Application With Expo
                    </Typography.Title>
                    <QRWrapper>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <div>
                          <Input
                            onChange={() => {}}
                            value={linkExpo}
                            style={{ marginBottom: 10, width: '100%' }}
                          />
                          <QRCode
                            size={400}
                            value={linkExpo}
                            logoWidth={'100'}
                            logoHeight={'100'}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '5px',
                        }}
                      ></div>
                    </QRWrapper>
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
}
