import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from '../services';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AuthenContext from '../utils/useAuthentication';
import { QRCode } from 'react-qrcode-logo';
import {
    Row,
    Col,
    Button,
    Form,
    InputNumber,
    Card as BaseCard,
    Select,
    Space,
    TimePicker,
    DatePicker
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSave,
} from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';
import moment from 'moment';

const { Option, OptGroup } = Select;

const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgb(0 89 83);
`;

const swal = withReactContent(Swal);

const cookies = new Cookies();
const { RangePicker } = DatePicker;
export default function InviteSeminar() {
    const [registerInviteSeminar] = Form.useForm();
    const history = useHistory();
    const onSetTime = (time) => {
        const datetime = new Date(time);
        let temp = datetime.toString().split(" (")[0]
        return new Date(temp) / 1000
    }
    const onFinish = (fieldsValue) => {

        const option = {
            method: 'POST',
            url: `${process.env.REACT_APP_API}/invitation/seminar`,
            headers: {
                'Authorization': 'Bearer ' + cookies.get('accessToken'),
                'Content-Type': 'application/json'
            },
            data: {
                "start_datetime": onSetTime(fieldsValue.datetime[0]._d),
                "end_datetime": onSetTime(fieldsValue.datetime[1]._d),
                "number_visitor": Number(fieldsValue.number_visitors)
            },

        };
        console.log(option);
        api(option)
            .then((res) => {
                global.store.dispatch({
                    type: 'set_loading_show',
                    data: { show: false },
                });
                if (res.data.code === 200) {
                    swal.fire({
                        icon: 'success',
                        title: "Invitation success",
                        input: 'text',
                        inputValue: `https://thaisummit-reception.com/invite-seminar/`+get(res, 'data.data'),
                        showConfirmButton: true,
                        confirmButtonColor: "#005953",
                        confirmButtonText: "Copy Link",
                        showCancelButton: true,
                        cancelButtonText: "Cancel",
                        cancelButtonColor: "#ff4d4f",
                    }).then((result) => {
                        if (result['isConfirmed']) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Copy link invitation success',
                                showConfirmButton: false,
                                timer: 1500
                              })
                            navigator.clipboard.writeText(result.value)
                        }else{
                            history.push('/project');
                        }
                    })
                } else {
                    swal.fire({
                        icon: 'error',
                        title: get(res, 'data.message'),
                        text: get(res, 'data.data'),
                        showConfirmButton: true,
                        confirmButtonColor: '#2F79FF',
                        confirmButtonText: 'Close',
                    });
                }
            })
            .catch((err) => {
                global.store.dispatch({
                    type: "set_loading_show",
                    data: { show: false },
                });
                swal.fire({
                    icon: "error",
                    title: 'Invitation failed',
                    text: get(err.response.data, 'message'),
                    showConfirmButton: true,
                    confirmButtonColor: "#2F79FF",
                    confirmButtonText: "Close",
                });
            });
    };
    return (
        <div className="theme-2-content">
            <Form
                name="registerInviteSeminar"
                form={registerInviteSeminar}
                labelAlign="left"
                onFinish={onFinish}
                labelCol={{
                    xs: { span: 24 },
                    sm: { span: 7 },
                    md: { span: 7 },
                    lg: { span: 7 },
                    xl: { span: 7 },
                    xxl: { span: 5 },
                }}
                wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 17 },
                    md: { span: 18 },
                    lg: { span: 20 },
                    xl: { span: 20 },
                    xxl: { span: 18 },
                }}
                initialValues={{ oneTime: false }}
            >
                <Card
                    title="Invite Information"
                    extra={
                        <Row>
                            <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                                <Form.Item style={{ paddingTop: '15%' }}>
                                    <Space>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            className="theme-2-button-1"
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={faSave}
                                                    style={{ marginRight: '10px', color: 'white' }}
                                                />
                                            }
                                        >
                                            Send Invite
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                                <Form.Item style={{ paddingTop: '100%' }}>
                                    <Space>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            className="theme-2-button-1"
                                            icon={<FontAwesomeIcon icon={faSave} />}
                                        ></Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                >
                    <Row justify="center">
                        <Col xxl={12} xl={12} lg={16} md={16} sm={24} xs={24}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Invite Date"
                                        name="datetime"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your date!',
                                            }
                                        ]}
                                    >
                                        <RangePicker
                                            ranges={{
                                                Today: [moment().minute("00"), moment().minute("00")],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            }}
                                            showTime
                                            minuteStep={30}
                                            format="YYYY/MM/DD HH:mm"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Number of Visitors"
                                        name="number_visitors"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your number of visitors!',
                                            }
                                        ]}
                                    >
                                        <InputNumber placeholder="Number of Visitors" style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </div>
    );
}
