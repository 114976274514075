import { remove } from "lodash";

const initialState = {
  content: [],
};

export default function NewsFeed(state = initialState, action) {
  switch (action.type) {
    case "set_new_news_content": {
      return {
        ...state,
        content: [...state.content, action.data],
      };
    }
    case "set_news_content": {
      const data = action.data;
      const index = state.content.findIndex(
        (x) => x.content_language_id === data.content_language_id
      );
      if (index < 0) {
        return {
          ...state,
          content: [...state.content, data],
        };
      } else {
        const neww = state.content;
        neww[index] = data;
        return {
          ...state,
          content: neww,
        };
      }
    }

    case "set_news_content_at_curr_lang": {
      const key = action.data.key;
      const value = action.data.value;
      const lang = action.data.lang;

      const x = state.content.map((obj) => {
        if (obj.content_language_id === lang) {
          obj[key] = value;
          return obj;
        } else {
          return obj;
        }
      });
      return {
        ...state,
        content: state.content.map((obj) => {
          if (obj.content_language_id === lang) {
            obj[key] = value;
            return obj;
          } else {
            return obj;
          }
        }),
      };
    }
    case "clear_news_content": {
      return initialState;
    }
    case "set_news_content_id": {
      const { id, lang } = action.data;
      const index = state.content.findIndex(
        (x) => x.content_language_id === lang
      );
      if (index < 0) {
        return state;
      } else {
        const neww = state.content;
        neww[index] = {
          ...neww[index],
          news_feed_content_id: id,
        };
        return {
          ...state,
          content: neww,
        };
      }
    }
    default:
      return state;
  }
}
