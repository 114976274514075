import React from "react";
import Theme from "../theme/theme_2";
import UserManagement from "../components/UserManagement";

export default function userManagement() {
  return (
    <div>
      <Theme PageName={"User Management"}>
        <UserManagement />
      </Theme>
    </div>
  );
}
