import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const swal = withReactContent(Swal);

export const ApprovedModal = (type, dataIn, action, fetchFunction, floorsData) => {
  const deleteContentName = (type) => {
    if (type === "request") {
      return get(dataIn, "firstname")+'\xa0' +get(dataIn, "lastname");
    } else {
      return type;
    }
  };

  if(action === "approve")
  {
    swal
    .fire({
      title: "Are you sure ?",
      text:
        "you want to approve " +
        type +
        " " +
        '"' +
        deleteContentName(type) +
        '"',
      icon: "warning",
      showConfirmButton: true,
      confirmButtonColor: "#2F79FF",
      confirmButtonText: "Confirm",
      showCancelButton: true,
      cancelButtonText: <div style={{ color: "#2F79FF" }}>Cancel</div>,
      cancelButtonColor: "#e3e3e3",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) approve(action,dataIn.id);
      });
  }
  else if(action === "reject")
  {
    swal
    .fire({
      title: "Are you sure ?",
      text:
        "you want to reject " +
        type +
        " " +
        '"' +
        deleteContentName(type) +
        '"',
      icon: "warning",
      showConfirmButton: true,
      confirmButtonColor: "#2F79FF",
      confirmButtonText: "Confirm",
      showCancelButton: true,
      cancelButtonText: <div style={{ color: "#2F79FF" }}>Cancel</div>,
      cancelButtonColor: "#e3e3e3",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) approve(action,dataIn.id);
    });
  }
   const approve = (approveType,id) => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    let option='';
    if (approveType == 'approve') {
       option = {
        method: 'POST',
        url: `${process.env.REACT_APP_API}/approvals/${id}/approve`,
      };
    }else  {
       option = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API}/approvals/${id}/reject`,
      };
    }
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          if(approveType === "reject")
          {
            swal.fire({
              icon: "success",
              title: "Rejected",
              text:
                type +
                " " +
                '"' +
                deleteContentName(type) +
                '" ' +
                "is successfully rejected.",
              showConfirmButton: true,
              confirmButtonColor: "#2F79FF",
              confirmButtonText: "Close",
            });
          }
          else
          {
            swal.fire({
              icon: "success",
              title: "Approved",
              text:
                type +
                " " +
                '"' +
                deleteContentName(type) +
                '" ' +
                "is successfully approved.",
              showConfirmButton: true,
              confirmButtonColor: "#2F79FF",
              confirmButtonText: "Close",
            });
          }
          fetchFunction();
        } else {
          swal.fire({
            icon: 'error',
            title: get(res, 'data.message'),
            text: get(res, 'data.data'),
            showConfirmButton: true,
            confirmButtonColor: '#2F79FF',
            confirmButtonText: 'Close',
          });
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        swal.fire({
          icon: 'error',
          title: get(err.response.data, 'code'),
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: '#2F79FF',
          confirmButtonText: 'Close'
        });
      });
  };
};
