import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styled from "styled-components";
import theme from "../theme";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Card as BaseCard,
  Select,
  Space,
  Checkbox,
} from "antd";
import { Icon, Tag } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faTimes,
  faSortDown,
  faCheck,
  faPlus,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from 'universal-cookie';
import Modal from "react-awesome-modal";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import editorText from "../constant/editorText";

const { Option } = Select;
const EditorWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.borderColor};
`;
const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgb(0 89 83);
`;

const swal = withReactContent(Swal);
const cookies = new Cookies();
export const BuildingForm = ({ type, initialValue, initialJuristic , submitForm }) => {
  const history = useHistory();
  const [buildingForm] = Form.useForm();
  const [juristicAdminForm] = Form.useForm();
  const [juristicAdmin, setJuristicAdmin] = useState([]);
  const [isAddJuristicAdmin, setisAddJuristicAdmin] = useState(false);
  const [isConditon, setisConditon] = useState(false);
  const [viewConsentModal, setViewConsentModal] = useState(false);
  const [selectedConsent, setSelectedConsent] = useState("");
  const getJuristicAdmin = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    if(type === "edit")
    {
      const option = {
        method: "get",
        url: `${process.env.REACT_APP_API}/users/juristic_admin?building_id=null`,
        headers:{
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          if (res.data.code === 200) {
            setJuristicAdmin([...get(res, "data.data"), ...initialJuristic]);
          }
        })
        .catch((err) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          setJuristicAdmin([...initialJuristic]);
        });
    }
    else
    {
      const option = {
        method: "get",
        url: `${process.env.REACT_APP_API}/users/juristic_admin?building_id=null`,
        headers:{
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          if (res.data.code === 200) {
            setJuristicAdmin(get(res, "data.data"));
          }
        })
    }
  };
  const openConsentModal = (consent) => {
    setSelectedConsent(EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(consent)
      )
    ))
  };
  useEffect(() => {
    getJuristicAdmin();
    CheckConsent();
  }, []);

  const tagRender = (props) => {
    const { label, closable, onClose } = props;

    return (
      <Tag
        color={theme.bgTextButton}
        closable={closable}
        onClose={onClose}
        closeIcon={<Icon fontSize="10px" icon={faTimes} />}
      >
        {label}
      </Tag>
    );
  };
  const handleCondition=(e)=>{
    setisConditon(e.target.checked)
  }
  const submitJuristicForm = (values) => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const o = {
      method: "get",
      url: `${process.env.REACT_APP_API}/consents/latest`,
    }
    api(o)
      .then((res) => {
        if(res.data.code === 200){
          const value = {
            personal_id: values.citizenID,
            email: values.email,
            firstname: values.firstname,
            lastname: values.lastname,
            phone: values.telephone,
            password: values.password,
            user_type: 2,
            consent_id: res.data.data.id,
          };
          const option = {
            method: "POST",
            url: `${process.env.REACT_APP_API}/users`,
            headers:{
              'Authorization': 'Bearer ' + cookies.get('accessToken'),
              'Content-Type': 'application/json'
            },
            data: value,
          };
          api(option)
          .then((res) => {
            global.store.dispatch({
              type: "set_loading_show",
              data: { show: false },
            });
    
            if (res.data.code === 201) {
              swal.fire({
                icon: "success",
                title: "Success",
                text: `Juristic "${get(values, "firstname")}" is successfully created`,
                showConfirmButton: true,
                confirmButtonColor: "#2F79FF",
                confirmButtonText: "Close",
              });
              setisAddJuristicAdmin(false);
              juristicAdminForm.resetFields();
              getJuristicAdmin();
            }
          })
          .catch((err) => {
            global.store.dispatch({
              type: "set_loading_show",
              data: { show: false },
            });
            swal.fire({
              icon: "error",
              title: 'Created failed',
              text: get(err.response.data, 'message'),
              showConfirmButton: true,
              confirmButtonColor: "#2F79FF",
              confirmButtonText: "Close",
            });
          });
      }
    })
  };
  const handleConsentSave = () => {
    setisAddJuristicAdmin(true);
    setViewConsentModal(false);
    setisConditon(false);
    
  };
  const handleCancel = () => {
    setisAddJuristicAdmin(false);
    setViewConsentModal(false);
  };
  const handleSave = () => {
    juristicAdminForm
      .validateFields()
      .then((values) => {
        submitJuristicForm(values);
      })
  };
  const CheckConsent = () => {
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/consents/latest`,
    }
    api(option)
    .then((res) => {
      global.store.dispatch({
        type: "set_loading_show",
        data: { show: false },
      });
      if (res.data.code === 200) {
        openConsentModal(res.data.data.content);
      }
    })
    .catch((err) => {
      global.store.dispatch({
        type: "set_loading_show",
        data: { show: false },
      });
      swal.fire({
        icon: "error",
        title: 'Created failed',
        text: get(err.response.data, 'message'),
        showConfirmButton: true,
        confirmButtonColor: "#2F79FF",
        confirmButtonText: "Close",
      });
    });
  }
  return (
    <div className="theme-2-content">
      <Form
        name="buildingForm"
        form={buildingForm}
        labelAlign="left"
        onFinish={submitForm}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 7 },
          md: { span: 7 },
          lg: { span: 7 },
          xl: { span: 7 },
          xxl: { span: 5 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 17 },
          md: { span: 18 },
          lg: { span: 20 },
          xl: { span: 20 },
          xxl: { span: 18 },
        }}
        initialValues={
          initialValue
            ? { ...initialValue }
            : {
                buildingName: "",
                telephone: "",
                juristicAdmin: [],
              }
        }
      >
        <Card
          title="Building Information"
          extra={
            <Row>
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <Form.Item style={{ paddingTop: "11%" }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          style={{ marginRight: "10px" }}
                        />
                      }
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="theme-button-save"
                      icon={
                        <FontAwesomeIcon
                          icon={faSave}
                          style={{ marginRight: "10px" }}
                        />
                      }
                    >
                      Save
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <Form.Item style={{ paddingTop: "30%" }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={<FontAwesomeIcon icon={faArrowLeft} />}
                      onClick={() => {
                        history.goBack();
                      }}
                    ></Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="theme-2-button-1"
                      icon={<FontAwesomeIcon icon={faSave} />}
                    ></Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          }
        >
          <Row justify="center">
            <Col xxl={12} xl={12} lg={16} md={16} sm={24} xs={24}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Building Name"
                    name="buildingName"
                    rules={[
                      {
                        required: true,
                        message: "Please input building name!",
                      },
                      {
                        min: 1,
                        message: "minimum length = 1",
                      },
                      {
                        max: 165,
                        message: "maximum length = 165",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Building Name"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Telephone"
                    name="telephone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your telephone number!",
                      },
                      {
                        pattern: "^[+]?[0-9]{9,12}$",
                        message: "Your telephone number is incorrect format.",
                      },
                    ]}
                  >
                    <Input placeholder="Telephone" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="juristicAdmin"
                    label="Juristic Admin"
                    rules={[
                      {
                        required: true,
                        message: "Please input juristic admin!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      tagRender={tagRender}
                      style={{ width: "100%" }}
                      suffixIcon={<Icon fontSize="50px" icon={faSortDown} />}
                      menuItemSelectedIcon={<Icon icon={faCheck} />}
                    >
                      {juristicAdmin.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.firstname} {item.lastname}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Button
                    style={{ width: "100%", marginBottom: "25px" }}
                    type="primary"
                    className="theme-2-button-1"
                    icon={
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ marginRight: "10px" }}
                      />
                    }
                    onClick={() => setViewConsentModal(true)}
                  > 
                    Create Juristic Admin
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
      <Modal
        visible={viewConsentModal}
        width="60%"
        height="70%"
        effect="fadeInDown"
      >
        <Row
          align="middle"
          style={{ height: "100%", padding: "20px" }}
        >
          <Col span={24}  style={{ position: "absolute", top: "10px", right: "10px" }}>
          <Icon
            icon={faTimes}
            color="grey"
            onClick={() => setViewConsentModal(false)}
          />
          </Col>
          
          <Col span={24} style={{ width:'100%',top: "0px", left: "0px",position: "absolute",backgroundColor:"#007F7B" }}>
          <h1 style={{ paddingTop: '10px', paddingLeft: "20px",color:'#fff' }}>Add Juristic Admin</h1>
          </Col>
          <div style={{ width: "100%", height: "80%" }}>
            <Col span={24} style={{height:"100%",marginTop: '20px', overflow: "auto"}}>
            <h4>Consent policy: </h4>
            <EditorWrapper>
              <Editor
                readOnly
                toolbarHidden
                editorStyle={{ margin: "20px" }}
                editorState={selectedConsent}
                localization={{
                  translations: editorText,
                }}
              />
            </EditorWrapper>
            <Checkbox onClick={handleCondition} checked={isConditon}>Accept</Checkbox>
            </Col>
            
          </div>
          <Col span={24} style={{textAlign:'right',margin:'5px'}}>
            <Button
            style={{marginRight:'5px'}}
            key="back"
            className="theme-2-button-2"
            onClick={() => setViewConsentModal(false)}
          >
            Close
          </Button>
          {isConditon &&(
         <Button
            style={{marginLeft:'5px'}}
            key="submit"
             className="theme-button-save"
             type="primary"
             onClick={handleConsentSave}
           >
             Next
           </Button>)}
            </Col>
        </Row>
      </Modal>
      <Modal
        visible={isAddJuristicAdmin}
        width="40%"
        height="65%"
        effect="fadeInDown"
      >
        <Row
          align="middle"
          style={{marginTop:"60px", padding: "20px" }}
        >
            <Col span={24}  style={{ position: "absolute", top: "10px", right: "10px" }}>
          <Icon
            icon={faTimes}
            color="grey"
            onClick={() => setViewConsentModal(false)}
          />
          </Col>
          
          <Col span={24} style={{ width:'100%',top: "0px", left: "0px",position: "absolute",backgroundColor:"#007F7B" }}>
          <h1 style={{ paddingTop: '10px', paddingLeft: "20px",color:'#fff' }}>Add Juristic Admin</h1>
          </Col>
          <div style={{ width: "100%", height: "60%" }}>
          <Col span={24}>
          <Form
          name="juristicAdminForm"
          form={juristicAdminForm}
          labelAlign="left"
          labelCol={{
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 },
            xl: { span: 8 },
            xxl: { span: 8 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 17 },
            md: { span: 18 },
            lg: { span: 20 },
            xl: { span: 20 },
            xxl: { span: 18 },
          }}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your E-mail!",
              },
              {
                type: "email",
                message: "Your email is incorrect.",
              },
              {
                min: 8,
                message: "minimum length = 8",
              },
              {
                max: 165,
                message: "maximum length = 165",
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="First Name"
            name="firstname"
            rules={[
              {
                required: true,
                message: "Please input First Name!",
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastname"
            rules={[
              {
                required: true,
                message: "Please input Last Name!",
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="telephone"
            label="telephone"
            rules={[
              {
                required: true,
                message: "Please input Telephone number!",
                whitespace: true,
              },
              {
                pattern: "^[+]?[0-9]{9,12}$",
                message: "Your telephone number is incorrect format.",
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="citizenID"
            label="citizen ID"
            rules={[
              {
                required: true,
                message: "Please input Citizen ID!",
                whitespace: true,
              },
              {
                min: 13,
                message: "minimum length = 13",
                whitespace: true,
              },
              {
                max: 13,
                message: "maximum length = 13",
                whitespace: true,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input password!",
              },
              {
                min: 8,
                message: "minimum length = 8",
              },
              {
                max: 32,
                message: "maximum length = 32",
              },
            ]}
          >
            <Input.Password style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="cfPassword"
            rules={[
              {
                required: true,
                message: "Please input password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password style={{ width: "100%" }} />
          </Form.Item>
        </Form>
        </Col>
        <Col span={24} style={{textAlign:'right',margin:'5px'}}>
            <Button
            style={{marginRight:'5px'}}
            key="back"
            className="theme-2-button-2"
            onClick={handleCancel}
          >
            Close
          </Button>
         <Button
            style={{marginLeft:'5px'}}
            key="submit"
            className="theme-button-save"
            type="primary"
             onClick={handleSave}
           >
             Save
           </Button>
            </Col>
          </div>
          
        </Row>
      </Modal>
    </div>
  );
};
