import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from '../services';
import { checkPermission } from '../utils/checkPermission';
import get from 'lodash/get';
import theme from '../theme';
import { Row, Col, Button, Input, Space, Tooltip } from 'antd';
import { Card, CardTable, Icon } from '../components';
import {
  faPlus,
  faTrash,
  faEye,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { ComparePassword } from './ComparePassword';
import Cookies from 'universal-cookie';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

export default function Cars() {
  const history = useHistory();
  const [data, setData] = useState();
  const [totalData, setTotalData] = useState();
  const [searchCol, setSearchCol] = useState('');
  const [searchData, setSearchData] = useState('');
  const [page, setPage] = useState('');
  const cookies = new Cookies();
  const [parkingLots, setParkingLots] = useState([]);

  const fetchParkingLot = async () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'post',
      url: `${process.env.REACT_APP_API_SAPI}/member/ListPermitBySite`,
      headers: {
        Authorization:
          'Bearer 011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7',
        'Content-Type': 'application/json',
      },
      data: {
        SiteGUID: process.env.REACT_APP_SITEGUID,
      },
    };
    api(option)
      .then((res) => {
        if (res.status === 200) {
          setParkingLots(res.data);
        }
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: true },
        });
      })
      .catch(() => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };

  const getCar = async (limit, offset, search) => {
    if (search) setPage(1);
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/cars?limit=${limit}&offset=${offset}`,
      headers: {
        Authorization: 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json',
      },
    };
    api(option).then((res) => {
      if (res.data.code === 200) {
        setSearchCol('');
        setSearchData('');
        setData(sortBy(get(res, 'data.data.list'), ['created_at']).reverse());
        setTotalData(get(res, 'data.data'));
        formatData(
          sortBy(get(res, 'data.data.list'), ['created_at']).reverse()
        );
      }
    });

    global.store.dispatch({
      type: 'set_loading_show',
      data: { show: false },
    });
  };

  const formatData = (data) => {
    const dataFormat = data.map((car) => {
      return {
        id: car.id,
        firstname: car.user.firstname,
        lastname: car.user.lastname,
        org_id: car.user.org_id,
        org_name: car.user.org_name,
        license_plate: car.license_plate_number,
        province: car.license_plate_province,
        start_date: moment(car.start_date).format('YYYY-MM-DD'),
        end_date: moment(car.end_date).format('YYYY-MM-DD'),
        parking_lot:
          parkingLots?.find(
            (parkingLot) => parkingLot.permitType === car.parking_lot
          )?.permitName ?? '',
      };
    });
    setData(dataFormat);
  };

  const getRequestOption = ({ limit, offset, col, data }) => {
    let option = {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json',
      },
    };
    const baseUrl = `${process.env.REACT_APP_API}/cars?limit=${limit}&offset=${offset}`;

    switch (col) {
      case 'firstname':
        return { ...option, url: `${baseUrl}&fname=${data}` };
      case 'lastname':
        return { ...option, url: `${baseUrl}&lname=${data}` };
      case 'license_plate':
        return { ...option, url: `${baseUrl}&lp=${data}` };
      // case 'province':
      //   return { ...option, url: `${baseUrl}&province=${data}` };
      // case 'org_name':
      //   return { ...option, url: `${baseUrl}&org_name=${data}` };
      // case 'parking_lot':
      //   return { ...option, url: `${baseUrl}&parking_lot=${data}` };
      default:
        return {};
    }
  };

  const searchFilter = (limit, offset, search, col, data) => {
    if (search) setPage(1);
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = getRequestOption({ limit, offset, col, data });

    api(option)
      .then((res) => {
        if (res.data.code === 200) {
          setSearchCol(col);
          setSearchData(data);
          setData(sortBy(get(res, 'data.data.list'), ['created_at']).reverse());
          setTotalData(get(res, 'data.data'));
          formatData(
            sortBy(get(res, 'data.data.list'), ['created_at']).reverse()
          );
        }

        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };

  const handleChange = (pagination) => {
    setPage(pagination.current);
    const offset =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    if (searchCol === '') getCar(limit, offset, false);
    else searchFilter(limit, offset, false, searchCol, searchData);
  };

  useEffect(() => {
    fetchParkingLot();
  }, []);

  useEffect(() => {
    if (parkingLots.length > 0) {
      getCar(10, 0, true);
    }
  }, [parkingLots.length]);

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchCol === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchData]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, dataIndex) => {
    searchFilter(10, 0, true, dataIndex, selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    getCar(10, 0, true);
    clearFilters();
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstname',
      key: 'firstname',
      align: 'start',
      className: 'theme-2-table-text',
      width: '11.11%',
      ...getColumnSearchProps('firstname', 'First Name'),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      key: 'lastname',
      align: 'start',
      className: 'theme-2-table-text',
      width: '11.11%',
      ...getColumnSearchProps('lastname', 'Last Name'),
    },
    {
      title: 'License Plate',
      dataIndex: 'license_plate',
      key: 'license_plate',
      align: 'center',
      className: 'theme-2-table-text',
      width: '11.11%',
      responsive: ['sm'],
      ...getColumnSearchProps('license_plate', 'License Plate'),
    },
    {
      title: 'Province',
      dataIndex: 'province',
      key: 'province',
      align: 'center',
      className: 'theme-2-table-text',
      width: '11.11%',
      // ...getColumnSearchProps('province', 'Province'),
    },
    {
      title: 'Organization',
      dataIndex: 'org_name',
      key: 'org_name',
      align: 'center',
      className: 'theme-2-table-text',
      width: '11.11%',
      responsive: ['sm'],
      // ...getColumnSearchProps('org_name', 'Organization'),
    },
    {
      title: 'Parking Lot',
      dataIndex: 'parking_lot',
      key: 'parking_lot',
      align: 'center',
      className: 'theme-2-table-text',
      width: '11.11%',
      responsive: ['sm'],
      // ...getColumnSearchProps('parking_lot', 'Parking Lot'),
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      align: 'center',
      className: 'theme-2-table-text',
      width: '11.11%',
      responsive: ['sm'],
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      align: 'center',
      className: 'theme-2-table-text',
      width: '11.11%',
      responsive: ['sm'],
    },
    {
      title: 'Action',
      key: 'user_id',
      align: 'center',
      className: 'theme-2-table-text',
      width: '11.11%',
      render: (cars) => (
        <Space size="middle">
          {(checkPermission('2')
            ? checkPermission('2')
            : checkPermission('4')) && (
            <Tooltip title="View Car" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faEye} />}
                type="text"
                onClick={() => {
                  history.push(`/parking/view_cars/${get(cars, 'id')}`);
                }}
              />
            </Tooltip>
          )}
          {checkPermission('2') && (
            <Tooltip title="Edit Car" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faPencilAlt} />}
                type="text"
                onClick={() =>
                  history.push(`/parking/edit_car/${get(cars, 'id')}`)
                }
              />
            </Tooltip>
          )}
          {checkPermission('2') && (
            <Tooltip title="Delete Car" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faTrash} />}
                type="text"
                onClick={() => ComparePassword('cars', cars, getCar, '')}
              />
            </Tooltip>
          )}
          {!checkPermission('2') && <div>-</div>}
        </Space>
      ),
    },
  ];

  const getInactiveClassName = (data) => {
    const endDate = moment(data.end_date).format('YYYY-MM-DD');
    const currentDate = moment().format('YYYY-MM-DD');

    if (moment(endDate).isBefore(currentDate)) {
      return 'inactive-row';
    }

    return '';
  };

  return (
    <div className="theme-2-content">
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card margin="20px 0">
            <Row justify="space-between">
              <Col xs={12}>
                <div style={{ marginTop: '10px' }}>
                  <p className="theme-2-text-1">{'All Cars'}</p>
                  <p className="theme-2-text-2">
                    {get(totalData, 'total') ? get(totalData, 'total') : 0}
                    {'\xa0Total Cars'}
                  </p>
                </div>
              </Col>
              <Col
                xs={0}
                sm={10}
                style={{ textAlign: 'right', marginTop: '2%' }}
              >
                <Button
                  type="primary"
                  className="theme-2-button-1"
                  icon={
                    <Icon margin="0 10px 0 0" color="white" icon={faPlus} />
                  }
                  onClick={() => history.push('/parking/add_car')}
                >
                  Add Car
                </Button>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <CardTable
                  rowKey={(obj) => obj.id}
                  columns={columns}
                  dataSource={data}
                  bordered={false}
                  onChange={handleChange}
                  scroll={{ x: 1900 }}
                  pagination={{
                    current: page,
                    position: ['bottomCenter'],
                    total: get(totalData, 'total'),
                  }}
                  rowClassName={getInactiveClassName}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
