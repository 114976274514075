import React from "react";
import Theme from "../theme/theme_2";
import ConsentManagement from "../components/ConsentManagement";
export default function consentManagement() {
  return (
    <div>
      <Theme PageName={"Consent Management"}>
        <ConsentManagement />
      </Theme>
    </div>
  );
}
