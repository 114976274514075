import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services";
import get from "lodash/get";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styled from "styled-components";
import theme from "../theme";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Card as BaseCard,
  Select,
  Space,
  Checkbox,
} from "antd";
import { Icon, Tag } from "../components";
import { SelectIcon } from "../components/icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faTimes,
  faSortDown,
  faCheck,
  faPlus,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from 'universal-cookie';
import Modal from "react-awesome-modal";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import editorText from "../constant/editorText";
const swal = withReactContent(Swal);

const { Option } = Select;
const EditorWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.borderColor};
`;
const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgb(0 89 83);
`;

const ErrorSection = styled.div`
  color: ${({ theme }) => theme.red};
`;

export const OrganizationForm = ({
  type,
  initialValue,
  initialOrgAdmin,
  initialStamp,
  initialRate,
  initialRateStamp,
  submitForm
}) => {
  const history = useHistory();
  const [organizeForm] = Form.useForm();
  const [organizeAdminForm] = Form.useForm();
  const [planOptions, setPlanOption] = useState([]);
  const [planOptionsEdit, setPlanOptionEdit] = useState([]);
  const [defPlanOptionsEdit, setdefPlanOptionEdit] = useState([]);
  const [organizeAdmin, setOrganizeAdmin] = useState([]);
  const [isAddOrganizeAdmin, setisAddOrganizeAdmin] = useState(false);
  const [buildings, setBuildings] = useState();
  const [errorRateTemplate, setErrorRateTemplate] = useState("");
  const [isConditon, setisConditon] = useState(false);
  const [viewConsentModal, setViewConsentModal] = useState(false);
  const [selectedConsent, setSelectedConsent] = useState("");
  let defaulPlanOptionsEdit = []
  let dumbValuePlanOptionsEdit = []
  let checkBoxValueEdit = []
  let data = []
  const cookies = new Cookies();

  const getBuilding = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/buildings`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
          setBuildings(get(res, "data.data"));
        } else {
          swal.fire({
            icon: "error",
            title: get(res, "data.code"),
            text: get(res, "data.data"),
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
        }
      })
  };

  const getPlanOption = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    if (type === "edit") {
      for (let index = 0; index < initialRateStamp.length ? initialRateStamp.length : 0; index++) {
        dumbValuePlanOptionsEdit.push({
          label: initialRateStamp[index].rateTemplateCode + " : " + initialRateStamp[index].templateName,
          value: initialRateStamp[index].rateTemplateGUID
        });
      }
      for (let index = 0; index < initialStamp.length ? initialStamp.length : 0; index++) {
        defaulPlanOptionsEdit.push(initialStamp[index].rate_template_guid);
        
      }
      setPlanOptionEdit(dumbValuePlanOptionsEdit)
      for (let i = 0; i < dumbValuePlanOptionsEdit.length ? dumbValuePlanOptionsEdit.length : 0; i++) {
        for (var prop in defaulPlanOptionsEdit) {
          if (dumbValuePlanOptionsEdit[i].value === defaulPlanOptionsEdit[prop]) {
            let temp = []
            temp = dumbValuePlanOptionsEdit[i].label.split(": ")
            data.push({ rate_template_guid: dumbValuePlanOptionsEdit[i].value, template_name: temp[1] });
          }
        }
      }
      checkBoxValueEdit.push(data);
      unDirtyEdit();
      initialRate(data);
    }
    else {
      const option = {
        method: 'post',
        url: `${process.env.REACT_APP_API_SAPI}/Stamp/ListRateTemplate`,
        headers: {
          'Authorization': 'Bearer ' + "011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7",
          'Content-Type': 'application/json'
        },
        data: {
          "SiteGUID": `${process.env.REACT_APP_SITEGUID}`,
        }
      };
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          if (res.status === 200) {
            setPlanOption(res.data ? res.data : []);
          }
        });
    }
  };

  const getOrganizeAdmin = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    if (type === "edit") {
      const option = {
        method: "get",
        url: `${process.env.REACT_APP_API}/users/org_admin?org_id=null`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      }
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          if (res.data.code === 200) {
            setOrganizeAdmin([...get(res, "data.data"), ...initialOrgAdmin]);
          }
        })
        .catch((err) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          setOrganizeAdmin([...initialOrgAdmin]);
        });
    }
    else {
      const option = {
        method: "get",
        url: `${process.env.REACT_APP_API}/users/org_admin?org_id=null`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      }
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          if (res.data.code === 200) {
            setOrganizeAdmin(get(res, "data.data"));
          }
        });
    }
  };
  const openConsentModal = (consent) => {
    setSelectedConsent(EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(consent)
      )
    ))
  };
  useEffect(() => {
    getBuilding();
    getOrganizeAdmin();
    getPlanOption();
    CheckConsent();
  }, []);

  const tagOrganizeAdminRender = (props) => {
    const { label, closable, onClose } = props;
    return (
      <Tag
        color={theme.bgTextButton}
        closable={closable}
        onClose={onClose}
        closeIcon={<Icon fontSize="10px" icon={faTimes} />}
      >
        {label}
      </Tag>
    );
  };
  let checkBoxValue = []
  const unDirty = () => {
    if (checkBoxValue.length > 0) setErrorRateTemplate("");
    else setErrorRateTemplate("Please select rate template!");
  }
  const unDirtyEdit = () => {

    if (checkBoxValueEdit.length > 0) {
      setErrorRateTemplate("");
    }
    else if (defPlanOptionsEdit.length > 0) {
      setErrorRateTemplate("");
    }
    else setErrorRateTemplate("Please select rate template!");
  }
  const onChangeEdit = (checkedValues) => {

    for (let i = 0; i < planOptionsEdit.length; i++) {
      for (var prop in checkedValues) {
        if (planOptionsEdit[i].value === checkedValues[prop]) {
          let temp = []
          temp = planOptionsEdit[i].label.split(": ")
          data.push({ rate_template_guid: planOptionsEdit[i].value, template_name: temp[1] });
        }
      }
    }
    setdefPlanOptionEdit(data)
    checkBoxValueEdit.push(data);
    unDirtyEdit();
    initialRate(data);
  }
  const onChange = (checkedValues) => {
    const value = {
      rate_template_guid: checkedValues.target.value,
      template_name: checkedValues.target.label,
      status: checkedValues.target.checked
    }
    checkBoxValue.push(value);
    unDirty();
  }
  const setCheckBoxData = () => {
    let submitRateFalse = []
    let submitRate = []

    for (let index = 0; index < checkBoxValue.length; index++) {
      if (checkBoxValue[index].status === true) {
        submitRate.push({ rate_template_guid: checkBoxValue[index].rate_template_guid, template_name: checkBoxValue[index].template_name })
      } else {
        submitRateFalse.push({ rate_template_guid: checkBoxValue[index].rate_template_guid, template_name: checkBoxValue[index].template_name })
      }
    }
    let temp = submitRate.filter((submitRate) => {
      return !submitRateFalse.find((submitRateFalse) => {
        return submitRate.rate_template_guid === submitRateFalse.rate_template_guid;
      })
    })
    initialRate(temp);
  }

  const submitOrganizeAdminForm = (values) => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const o = {
      method: "get",
      url: `${process.env.REACT_APP_API}/consents/latest`,
    }
    api(o)
      .then((res) => {
        if (res.data.code === 200) {
          const value = {
            user_type: 4,
            email: values.email,
            password: values.password,
            firstname: values.firstname,
            lastname: values.lastname,
            personal_id: values.citizenID,
            phone: values.telephone,
            consent_id: res.data.data.id,
          };
          const option = {
            method: "post",
            url: `${process.env.REACT_APP_API}/users`,
            data: value,
          };
          api(option)
            .then((res) => {
              global.store.dispatch({
                type: "set_loading_show",
                data: { show: false },
              });
              if (res.data.code === 201) {
                setisAddOrganizeAdmin(false);
                organizeAdminForm.resetFields();
                getOrganizeAdmin();
                swal.fire({
                  icon: "success",
                  title: "Success",
                  text: `User "${get(values, "firstname")}" is successfully created`,
                  showConfirmButton: true,
                  confirmButtonColor: "#2F79FF",
                  confirmButtonText: "Close",
                });
              }
            })
            .catch((err) => {
              global.store.dispatch({
                type: "set_loading_show",
                data: { show: false },
              });
              swal.fire({
                icon: "error",
                title: "Created failed",
                text: get(err.response.data, 'message'),
                showConfirmButton: true,
                confirmButtonColor: "#2F79FF",
                confirmButtonText: "Close",
              });
            });
        }
      })
  };
  const handleCondition = (e) => {
    setisConditon(e.target.checked)
  }
  const handleConsentSave = () => {
    setisAddOrganizeAdmin(true);
    setViewConsentModal(false);
    setisConditon(false);

  };
  const handleCancel = () => {
    setisAddOrganizeAdmin(false);
  };
  const handleSave = () => {
    organizeAdminForm
      .validateFields()
      .then((values) => {
        submitOrganizeAdminForm(values);
      });
  };
  const CheckConsent = () => {
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/consents/latest`,
    }
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
          openConsentModal(res.data.data.content);
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: "error",
          title: 'Created failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: "#2F79FF",
          confirmButtonText: "Close",
        });
      });
  }

  const handleChangeBuilding = (value) => {
    const selectedBuilding = find(buildings, ["building_id", value]);
  };

  return (
    <div className="theme-2-content">
      <Form
        name="organizeForm"
        form={organizeForm}
        labelAlign="left"
        onFinish={submitForm}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 7 },
          md: { span: 7 },
          lg: { span: 7 },
          xl: { span: 7 },
          xxl: { span: 5 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 17 },
          md: { span: 18 },
          lg: { span: 20 },
          xl: { span: 20 },
          xxl: { span: 18 },
        }}
        initialValues={
          initialValue
            ? { ...initialValue }
            : {
              organizeName: "",
              telephone: "",
              organizeAdmin: [],
            }
        }
      >
        <Card
          title="Organize Information"
          extra={
            <Row>
              {isEqual(type, "edit") && (
                <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                  <Form.Item style={{ paddingTop: "11%" }}>
                    <Space>
                      <Button
                        type="default"
                        className="theme-2-button-2"
                        icon={
                          <FontAwesomeIcon
                            icon={faArrowLeft}
                            style={{ marginRight: "10px" }}
                          />
                        }
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="theme-button-save"
                        onClick={() => {
                          //unDirtyEdit();
                        }}
                        icon={
                          <FontAwesomeIcon
                            icon={faSave}
                            style={{ marginRight: "10px" }}
                          />
                        }
                      >
                        Save
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>)}
              {isEqual(type, "create") && (
                <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                  <Form.Item style={{ paddingTop: "11%" }}>
                    <Space>
                      <Button
                        type="default"
                        className="theme-2-button-2"
                        icon={
                          <FontAwesomeIcon
                            icon={faArrowLeft}
                            style={{ marginRight: "10px" }}
                          />
                        }
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="theme-button-save"
                        onClick={() => {
                          unDirty();
                          setCheckBoxData();
                        }}
                        icon={
                          <FontAwesomeIcon
                            icon={faSave}
                            style={{ marginRight: "10px" }}
                          />
                        }
                      >
                        Save
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>)}
              {isEqual(type, "create") && (
                <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                  <Form.Item style={{ paddingTop: "30%" }}>
                    <Space>
                      <Button
                        type="default"
                        className="theme-2-button-2"
                        icon={<FontAwesomeIcon icon={faArrowLeft} />}
                        onClick={() => {
                          history.goBack();
                        }}
                      ></Button>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="theme-2-button-1"
                        icon={<FontAwesomeIcon icon={faSave} />}
                      ></Button>
                    </Space>
                  </Form.Item>
                </Col>)}
            </Row>
          }
        >
          <Row justify="center">
            <Col xxl={12} xl={12} lg={16} md={16} sm={24} xs={24}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Organize Name"
                    name="organizeName"
                    rules={[
                      {
                        required: true,
                        message: "Please input organize name!",
                      },
                      {
                        min: 1,
                        message: "minimum length = 1",
                      },
                      {
                        max: 165,
                        message: "maximum length = 165",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Organize Name"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {isEqual(type, "create") && (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="building"
                      label="Building"
                      rules={[
                        { required: true, message: "Please input building" },
                      ]}
                    >
                      <Select
                        disabled={isEqual(type, "edit")}
                        style={{ width: "100%" }}
                        suffixIcon={<SelectIcon />}
                        menuItemSelectedIcon={<Icon icon={faCheck} />}
                        placeholder="Building"
                        onChange={(value) => handleChangeBuilding(value)}
                      >
                        {buildings &&
                          buildings.map((building) => {
                            return (
                              <Option
                                key={building.id}
                              >
                                {building.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="organizeAdmin"
                    label="Organize Admin"
                    rules={[
                      {
                        required: true,
                        message: "Please input organize admin!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      name="building"
                      placeholder="Select organize admin"
                      tagRender={tagOrganizeAdminRender}
                      style={{ width: "100%" }}
                      suffixIcon={<Icon fontSize="50px" icon={faSortDown} />}
                      menuItemSelectedIcon={<Icon icon={faCheck} />}
                    >
                      {organizeAdmin.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.firstname} {item.lastname}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="taxID"
                    label="Tax ID"
                    rules={[
                      {
                        required: true,
                        message: "Please input Tax ID!",
                      },
                      {
                        min: 1,
                        message: "minimum length = 1",
                      },
                      {
                        max: 13,
                        message: "maximum length = 13",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Tax ID"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {isEqual(type, "edit") && (
                    <Form.Item
                      name="rateTemplate"
                      label="Rate Template"
                      className="rate-template"
                    >
                      <Row>
                        <Col span={12}>
                          <Checkbox.Group options={planOptionsEdit} defaultValue={defaulPlanOptionsEdit} onChange={onChangeEdit} />
                          <ErrorSection>{errorRateTemplate}</ErrorSection>
                        </Col>
                      </Row>

                    </Form.Item>
                  )}
                  {isEqual(type, "create") && (
                    <Form.Item
                      name="rateTemplate"
                      label="Rate Template"
                      className="rate-template">
                      {planOptions.map((item) => {
                        return (
                          <Row>
                            <Col span={12}>
                              <Checkbox.Group>
                                <Checkbox onChange={onChange} key={item.rateTemplateGUID} value={item.rateTemplateGUID} label={item.templateName}>
                                  {item.rateTemplateCode + " : " + item.templateName}
                                </Checkbox>
                              </Checkbox.Group>
                            </Col>
                          </Row>
                        )
                      })}
                      <ErrorSection>{errorRateTemplate}</ErrorSection>
                    </Form.Item>
                  )}

                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Button
                    style={{ width: "100%", marginBottom: "25px" }}
                    type="primary"
                    className="theme-2-button-1"
                    icon={
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ marginRight: "10px" }}
                      />
                    }
                    onClick={() => setViewConsentModal(true)}
                  >
                    Create Organize Admin
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
      <Modal
        visible={viewConsentModal}
        width="60%"
        height="70%"
        effect="fadeInDown"
      >
        <Row
          align="middle"
          style={{ height: "100%", padding: "20px" }}
        >
          <Col span={24} style={{ position: "absolute", top: "10px", right: "10px" }}>
            <Icon
              icon={faTimes}
              color="grey"
              onClick={() => setViewConsentModal(false)}
            />
          </Col>

          <Col span={24} style={{ width: '100%', top: "0px", left: "0px", position: "absolute", backgroundColor: "#007F7B" }}>
            <h1 style={{ paddingTop: '10px', paddingLeft: "20px", color: '#fff' }}>Add Organize Admin</h1>
          </Col>
          <div style={{ width: "100%", height: "80%" }}>
            <Col span={24} style={{ height: "100%", marginTop: '20px', overflow: "auto" }}>
              <h4>Consent policy: </h4>
              <EditorWrapper>
                <Editor
                  readOnly
                  toolbarHidden
                  editorStyle={{ margin: "20px" }}
                  editorState={selectedConsent}
                  localization={{
                    translations: editorText,
                  }}
                />
              </EditorWrapper>
              <Checkbox onClick={handleCondition} checked={isConditon}>Accept</Checkbox>
            </Col>

          </div>
          <Col span={24} style={{ textAlign: 'right', margin: '5px' }}>
            <Button
              style={{ marginRight: '5px' }}
              key="back"
              className="theme-2-button-2"
              onClick={() => setViewConsentModal(false)}
            >
              Close
            </Button>
            {isConditon && (
              <Button
                style={{ marginLeft: '5px' }}
                key="submit"
                className="theme-button-save"
                type="primary"
                onClick={handleConsentSave}
              >
                Next
              </Button>)}
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={isAddOrganizeAdmin}
        width="40%"
        height="70%"
        effect="fadeInDown"
      >
        <Row
          align="middle"
          style={{ marginTop: "60px", padding: "20px", height: "100%" }}
        >
          <Col span={24} style={{ position: "absolute", top: "10px", right: "10px" }}>
            <Icon
              icon={faTimes}
              color="grey"
              onClick={() => setViewConsentModal(false)}
            />
          </Col>

          <Col span={24} style={{ width: '100%', top: "0px", left: "0px", position: "absolute", backgroundColor: "#007F7B" }}>
            <h1 style={{ paddingTop: '10px', paddingLeft: "20px", color: '#fff' }}>Add Organize Admin</h1>
          </Col>

          <div style={{ width: "100%", height: "100%" }}>
            <Col span={24}>
              <Form
                name="organizeAdminForm"
                form={organizeAdminForm}
                labelAlign="left"
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 8 },
                  md: { span: 8 },
                  lg: { span: 8 },
                  xl: { span: 8 },
                  xxl: { span: 8 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 17 },
                  md: { span: 18 },
                  lg: { span: 20 },
                  xl: { span: 20 },
                  xxl: { span: 18 },
                }}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                    {
                      type: "email",
                      message: "Your email is incorrect.",
                    },
                    {
                      min: 8,
                      message: "minimum length = 8",
                    },
                    {
                      max: 165,
                      message: "maximum length = 165",
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  label="First Name"
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input First Name!",
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Please input Last Name!",
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="telephone"
                  label="telephone"
                  rules={[
                    {
                      required: true,
                      message: "Please input Telephone number!",
                      whitespace: true,
                    },
                    {
                      pattern: "^[+]?[0-9]{9,12}$",
                      message: "Your telephone number is incorrect format.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="citizenID"
                  label="citizen ID"
                  rules={[
                    {
                      required: true,
                      message: "Please input Citizen ID!",
                      whitespace: true,
                    },
                    {
                      min: 13,
                      message: "minimum length = 13",
                      whitespace: true,
                    },
                    {
                      max: 13,
                      message: "maximum length = 13",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input Password!",
                    },
                    {
                      min: 8,
                      message: "minimum length = 8",
                    },
                    {
                      max: 32,
                      message: "maximum length = 32",
                    },
                  ]}
                >
                  <Input.Password style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="cfPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input Confirm Password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password style={{ width: "100%" }} />
                </Form.Item>
              </Form>
              <div style={{ textAlign: 'right', margin: '5px' }}>
                <Button
                  style={{ marginRight: '5px' }}
                  key="back"
                  className="theme-2-button-2"
                  onClick={handleCancel}
                >
                  Close
                </Button>
                <Button
                  style={{ marginLeft: '5px' }}
                  key="submit"
                  className="theme-button-save"
                  type="primary"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </Col>
          </div>

        </Row>
      </Modal>
    </div>
  );
};
