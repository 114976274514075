import React, { useState, useEffect } from "react";
import { api } from "../services";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import find from "lodash/find";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import theme from "../theme";
import { Modal, Form, Select } from "antd";
import { Icon, Tag } from "../components";
import {
  faSortDown,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { SelectIcon } from "../components/icon";

const { Option } = Select;

const tagRender = (props) => {
  const { label, closable, onClose } = props;
  return (
    <Tag
      color={theme.bgTextButton}
      style={{
        color: theme.primaryColor,
        border: `${theme.primaryColor} 1px solid`,
      }}
      closable={closable}
      onClose={onClose}
      closeIcon={<Icon fontSize="10px" icon={faTimes} />}
    >
      {label}
    </Tag>
  );
};

const swal = withReactContent(Swal);

export const CreateAccessModal = ({ visible, submitForm, onCancel }) => {
  const [createNewAccessForm] = Form.useForm();
  const [buildings, setBuilding] = useState();
  const [floorData, setFloorData] = useState();

  const fetchBuilding = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/buildings`,
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.status === "success") {
          setBuilding(
            sortBy(get(res, "data.payload"), ["building_name", "building_id"])
          );
        }
      })
  };
  useEffect(() => {
    fetchBuilding();
  }, []);
  const handleSave = () => {
    createNewAccessForm
      .validateFields()
      .then((values) => {
        const b = find(buildings, function (o) {
          return o.building_id === values.building;
        });
        submitForm({ ...values, buildingName: b.building_name });
      })
  };

  const handleChangeBuilding = (value) => {
    const selectedBuilding = find(buildings, ["building_id", value]);
    setFloorData(selectedBuilding);
    createNewAccessForm.setFieldsValue({ floor: [] });
  };

  return (
    <Modal
      visible={visible}
      title="Create New Access"
      okButtonProps={{ className: "theme-2-button-1" }}
      okText="Save"
      cancelButtonProps={{ className: "theme-2-button-2" }}
      cancelText="Cancel"
      onOk={handleSave}
      onCancel={onCancel}
      maskClosable={false}
    >
      <Form
        name="createNewAccessForm"
        form={createNewAccessForm}
        labelAlign="left"
        labelCol={{
          xs: { span: 24 },
          sm: { span: 4 },
          md: { span: 4 },
          lg: { span: 4 },
          xl: { span: 4 },
          xxl: { span: 4 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 17 },
          md: { span: 18 },
          lg: { span: 20 },
          xl: { span: 20 },
          xxl: { span: 18 },
        }}
      >
        <Form.Item
          name="building"
          label="Building"
          rules={[{ required: true, message: "Please input building" }]}
          style={{ width: "100%" }}
        >
          <Select
            style={{ width: "100%" }}
            suffixIcon={<SelectIcon />}
            menuItemSelectedIcon={<Icon icon={faCheck} />}
            placeholder="Building"
            onChange={(value) => handleChangeBuilding(value)}
          >
            {buildings &&
              buildings.map((building) => {
                return (
                  <Option
                    key={building.building_id}
                    value={building.building_id}
                  >
                    {building.building_name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item
          name="floor"
          label="Floor"
          rules={[{ required: true, message: "Please input floor" }]}
          style={{ width: "100%" }}
        >
          <Select
            mode="multiple"
            tagRender={tagRender}
            style={{ width: "100%" }}
            suffixIcon={<Icon icon={faSortDown} />}
            menuItemSelectedIcon={<Icon icon={faCheck} />}
            placeholder="Floor"
          >
            {floorData &&
              floorData.floors.map((floor) => {
                return (
                  <Option key={floor.floor_id} value={floor.floor_id}>
                    {floor.floor_name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
