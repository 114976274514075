import { api } from "../services";

export const getAllNewsContent = (dispatch, data) => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API}/news-feed/${data.id}`,
    params: {
      pc: process.env.REACT_APP_PROJECT_CODE,
      lang: data.lang,
    },
  };
  return api(config)
    .then((res) => {
      if (res.data.status === "success") {
        dispatch({
          type: "set_news_content",
          data: res.data.payload,
        });
      }
    })
};

export const setNewNewsContent = (dispatch, data) => {
  return dispatch({
    type: "set_new_news_content",
    data: data,
  });
};

export const setNewsContentAtCurrLang = (dispatch, data) => {
  return dispatch({
    type: "set_news_content_at_curr_lang",
    data: data,
  });
};

export const setNewsContent = (dispatch, data) => {
  return dispatch({
    type: "set_news_content",
    data: data,
  });
};

export const clearNewsContent = (dispatch) => {
  return dispatch({
    type: "clear_news_content",
  });
};

export const setNewsContentID = (dispatch, data) => {
  return dispatch({
    type: "set_news_content_id",
    data: data,
  });
};
