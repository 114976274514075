import React from "react";
import Theme from "../theme/theme_2";
import Project from "../components/Project";

export default function project() {
  return (
    <div>
      <Theme PageName={"Project"}>
        <Project />
      </Theme>
    </div>
  );
}
