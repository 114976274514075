import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';
import { Row, Col, Button } from 'antd';
import moment from 'moment';

import { Card } from '../components';
import { getRole } from '../utils/helper';
import { api } from '../services';

const renderContentLayout = (title, content) => (
  <Row gutter={[24, 24]}>
    <Col xs={{ span: 12 }} sm={{ span: 6 }}>
      <p sm="theme-2-text-3">{title}</p>
    </Col>
    <Col xs={{ span: 12 }} md={{ span: 18 }}>
      <p className="theme-2-text-4">{`:\xa0\xa0\xa0\xa0${content}`}</p>
    </Col>
  </Row>
);

const BuildingSection = (data) => (
  <Card margin="20px 0">
    {renderContentLayout('Name', get(data, 'name'))}
    {renderContentLayout('License Plate', get(data, 'license_plate'))}
    {renderContentLayout('Province', get(data, 'province'))}
    {renderContentLayout('Organization', get(data, 'org_name'))}
    {renderContentLayout('User Type', get(data, 'user_type'))}
    {renderContentLayout('Parking Lot', get(data, 'parking_lot'))}
    {renderContentLayout('Start Date', get(data, 'start_date'))}
    {renderContentLayout('End Date', get(data, 'end_date'))}
  </Card>
);

export default function CarForm() {
  const history = useHistory();
  const { carID } = useParams();
  const [data, setData] = useState();
  const cookies = new Cookies();
  const [parkingLots, setParkingLots] = useState([]);

  const formatData = (data) => {
    const dataFormat = {
      id: data.id,
      name: data.user.firstname + '\xa0' + data.user.lastname,
      org_id: data.user.org_id,
      org_name: data.user.org_name,
      user_type: getRole(data.user.user_type),
      license_plate: data.license_plate_number,
      province: data.license_plate_province,
      start_date: moment(data.start_date).format('YYYY-MM-DD'),
      end_date: moment(data.end_date).format('YYYY-MM-DD'),
      parking_lot:
        parkingLots?.find(
          (parkingLot) => parkingLot.permitType === data.parking_lot
        )?.permitName ?? '',
    };
    setData(dataFormat);
  };

  const fetchParkingLot = async () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'post',
      url: `${process.env.REACT_APP_API_SAPI}/member/ListPermitBySite`,
      headers: {
        Authorization:
          'Bearer 011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7',
        'Content-Type': 'application/json',
      },
      data: {
        SiteGUID: process.env.REACT_APP_SITEGUID,
      },
    };
    api(option)
      .then((res) => {
        if (res.status === 200) {
          setParkingLots(res.data);
        }
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: true },
        });
      })
      .catch(() => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };

  const getCarByID = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/cars/${carID}`,
      headers: {
        Authorization: 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json',
      },
    };
    api(option).then((res) => {
      if (res.data.code === 200) {
        formatData(get(res, 'data.data'));
      }
      global.store.dispatch({
        type: 'set_loading_show',
        data: { show: false },
      });
    });
  };

  useEffect(() => {
    fetchParkingLot();
  }, []);

  useEffect(() => {
    if (parkingLots.length > 0) {
      getCarByID();
    }
  }, [parkingLots.length]);

  return (
    <div className="theme-2-content">
      <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
        <Row justify="end" style={{ paddingTop: '2%' }}>
          <Button
            type="default"
            className="theme-2-button-2"
            onClick={() => {
              history.goBack();
            }}
            icon={
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ marginRight: '10px' }}
              />
            }
          >
            Back
          </Button>
        </Row>
      </Col>
      <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
        <Row justify="end" style={{ paddingTop: '5%' }}>
          <Button
            type="default"
            className="theme-2-button-2"
            onClick={() => {
              history.goBack();
            }}
            icon={
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ marginRight: '10px' }}
              />
            }
          >
            Back
          </Button>
        </Row>
      </Col>
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          {parkingLots.length > 0 && data && (
            <BuildingSection {...{ ...data }} />
          )}
        </Col>
      </Row>
    </div>
  );
}
