import React from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services";
import get from "lodash/get";
import Theme from "../theme/theme_1";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const swal = withReactContent(Swal);
export default function ForgotPassword() {
  const history = useHistory();

  const submitForm = (values) => {
    const option = {
      method: "post",
      url: `${process.env.REACT_APP_API}/users/forgot_password`,
      data: {
        email: values.email,
      },
    };
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
          swal.fire({
            icon: 'success',
            title: "email is success.",
            text: "Please check your new password in your email.",
            showConfirmButton: true,
            confirmButtonColor: '#2F79FF',
            confirmButtonText: 'Close',
          });
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: 'error',
          title: "email is invalid.",
          text: "Please check your email is invalid.",
          showConfirmButton: true,
          confirmButtonColor: '#2F79FF',
          confirmButtonText: 'Close',
        });
      });
  };

  const cancelForm = () => {
    history.goBack();
  };

  return (
    <div>
      <Theme>
        <ForgotPasswordForm submitForm={submitForm} cancelForm={cancelForm} />
      </Theme>
    </div>
  );
}
