import React from "react";
import Theme from "../theme/theme_2";
import ParkingManagement from "../components/Parking";
export default function parkingManagement() {
  return (
    <div>
      <Theme PageName={"Parking Management"}>
        <ParkingManagement />
      </Theme>
    </div>
  );
}
