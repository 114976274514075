import Cookies from "universal-cookie";
import { api } from "../services";
import get from "lodash/get";

const cookies = new Cookies();

const uploadImageInEditor = (file) => {

  let bodyFormData = new FormData();

  bodyFormData.append("image", file);
  bodyFormData.append("folder_name", Date.now());

  const option = {
    method: "post",
    url: `${process.env.REACT_APP_API}/upload-image`,
    headers: {
      Authorization: `Bearer ${cookies.get("accessToken")}`,
      "Content-Type": "multipart/form-data",
    },
    data: bodyFormData,
  };

  return new Promise((resolve, reject) => {
    api(option)
      .then((res) => {
        if (res.data.status === "success") {
          resolve({ data: { link: get(res, "data.payload") } });
        }
      })
      .catch((err) => {
        reject();
      });
  });
};
export default uploadImageInEditor;
