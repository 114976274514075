import React, { useState, useEffect , useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { api } from "../services";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { set } from "lodash";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styled from "styled-components";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Card as BaseCard,
  Space,
  Radio,
  Dropdown,
  Menu,
} from "antd";
import { Icon, InputThumbnail } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faPlus,
  faArrowLeft,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  addNewLang,
  setCurrentLang,
  setNewNewsContent,
  setNewsContentAtCurrLang,
  setLangList,
  setNewsContent,
  clearNewsContent,
  setNewsContentID,
} from "../action";
import editorText from "../constant/editorText";
import editorToolbar from "../constant/editorToolbar";
import AuthenContext from '../utils/useAuthentication';

const cookies = new Cookies();

const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgba(167, 190, 216, 0.5);
`;

const EditorWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.borderColor};
`;

const swal = withReactContent(Swal);

export const ConsentForm = ({ formType }) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { lang, newsFeed } = useSelector(
    (state) => ({
      lang: state.Language,
      newsFeed: state.NewsFeed,
    }),
    shallowEqual
  );
  useEffect(() => {
    setCurrentLang(dispatch, lang.defaultLang);
    if (get(lang, "defaultLang.id")) {
      setLangList(dispatch, [get(lang, "defaultLang")]);
      setCurrentLang(dispatch, get(lang, "defaultLang.id"));
      const value = {
        content: EditorState.createEmpty(),
        content_language_id: get(lang, "defaultLang.id"),
      };
      setNewNewsContent(dispatch, value);
    }
    return () => {
      if (newsFeed.content.length > 0) {
        clearNewsContent(dispatch);
        newsFeed.content.length = 0;
      }
  };
  }, [lang.defaultLang]);

  const onEditorStateChange = (editorState) => {
    setNewsContentAtCurrLang(dispatch, {
      key: "content",
      value: editorState,
      lang: lang.currentLang,
    });
  };

  
  const submitNewsForm = () => {
      global.store.dispatch({ type: "set_loading_show", data: { show: true } });
      const value = newsFeed.content.map((x) => {
        return {
          ...x,
          content: draftToHtml(convertToRaw(x.content.getCurrentContent())),
        };
      });
      var newsData = {
        content_th: "",
        content_en: ""
      }
      if(value.length == 2)
      {
        newsData.content_th = value[0].content;
        newsData.content_en = value[1].content;
      }
      else
      {
        newsData.content_th = value[0].content;
      }
      console.log(newsData)
      const option = {
          method: "post",
          url: `${process.env.REACT_APP_API}/consents`,
          data: newsData,
        }
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          if (res.data.code === 201) {
            swal.fire({
              icon: "success",
              title: "Success",
              text: `Consent is successfully added`,
              showConfirmButton: true,
              confirmButtonColor: "#2F79FF",
              confirmButtonText: "Close",
            });
            history.push("/management/consent");
            clearNewsContent(dispatch);
          }
        })
        .catch((err) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          swal.fire({
            icon: "error",
            title: 'Created failed',
            text: get(err.response.data, 'message'),
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
        });
  };

  const validateTitle = (type, value, language) => {
      switch (type) {
        case "changeLang": {
            setCurrentLang(dispatch, language);
          break;
        }
        case "submitForm": {
          submitNewsForm();
          break;
        }
        case "addNewLang": {
          const baseValue = {
            content: EditorState.createEmpty(),
            content_language_id: language.id,
          };
          const editTypeValue = {
            ...baseValue,
            news_feed_content_id: 0,
            news_feed_id: "",
          };

          addNewLang(dispatch, language);
          setNewNewsContent(
            dispatch,
            formType === "create" ? baseValue : editTypeValue
          );

          setCurrentLang(dispatch, language.id);
          break;
        }
        default: {
          break;
        }
      }
  };

  const handleValidateTitle = (type, value, newLang) => {
    switch (type) {
      case "changeLang": {
        const newsTitle = newsFeed.content.find(
          (x) => x.content_language_id === lang.currentLang
        );
        validateTitle(type, newsTitle, newLang);
        break;
      }
      case "submitForm": {
        const newsTitle = newsFeed.content.find(
          (x) => x.content_language_id === lang.currentLang
        );
        validateTitle(type, newsTitle);
        break;
      }
      case "addNewLang": {
        const newsTitle = newsFeed.content.find(
          (x) => x.content_language_id === lang.currentLang
        );
        validateTitle(type, newsTitle, newLang);
        break;
      }
      default: {
        validateTitle("", value);
        break;
      }
    }
  };

  const onChangeLang = (e) => {
    if (formType === "view") {
      setCurrentLang(dispatch, e.target.value);
    } else {
      handleValidateTitle("changeLang", null, e.target.value);
    }
  };

  const [newsForm] = Form.useForm();

  const handleAddNewLang = (item) => {
    handleValidateTitle("addNewLang", null, item);
  };

  const menu = (
    <Menu>
      {lang.availableLang.map((item) => {
        return (
          <Menu.Item key={item.id} onClick={() => handleAddNewLang(item)}>
            {item.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const renderFormContent = (content) => {
    if (content) {
      return (
        <>
          <Form.Item
            label="Consent"
          >
            <EditorWrapper>
              <Editor
                editorStyle={{ margin: "20px" }}
                readOnly={formType === "view"}
                editorState={content.content}
                onEditorStateChange={onEditorStateChange}
                localization={{
                  translations: editorText,
                }}
                toolbar={{
                  ...editorToolbar
                }}
              />
            </EditorWrapper>
          </Form.Item>
        </>
      );
    } else return <div>no data</div>;
  };

  const submitButton = () => {
      handleValidateTitle("submitForm");
  };

  return (
    <div className="theme-2-content">
      <Form
        name="newsForm"
        form={newsForm}
        labelAlign="left"
        labelCol={{
          xs: { span: 24 },
          sm: { span: 6 },
          md: { span: 4 },
          lg: { span: 4 },
          xl: { span: 3 },
          xxl: { span: 2 },
        }}
        wrapperCol={{ span: 24 }}
      >
        <Card
          title={`${capitalize(formType)} Consent`}
          extra={
            <Row>
              <Col
                xxl={24}
                xl={24}
                lg={24}
                md={24}
                sm={0}
                xs={0}
                style={{ marginTop: "8%" }}
              >
                <Form.Item>
                  <Space>
                    <Button
                      type="default"
                      onClick={() => {
                        history.goBack();
                        clearNewsContent(dispatch);
                      }}
                      className="theme-2-button-2"
                      icon={
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          style={{ marginRight: "10px" }}
                        />
                      }
                    >
                      Back
                    </Button>
                    <Button
                      type="primary"
                      className="theme-button-save"
                      onClick={submitButton}
                      icon={
                        <FontAwesomeIcon
                          icon={formType === "view" ? faEdit : faSave}
                          style={{ marginRight: "10px" }}
                        />
                      }
                    >
                      Create Consent
                  </Button>
                  </Space>
                </Form.Item>
              </Col>
              <Col
                xxl={0}
                xl={0}
                lg={0}
                md={0}
                sm={24}
                xs={24}
                style={{ marginTop: "30%" }}
              >
                <Form.Item>
                  <Space>
                    <Button
                      type="default"
                      onClick={() => {
                        history.goBack();
                        clearNewsContent(dispatch);
                      }}
                      className="theme-2-button-2"
                      icon={<FontAwesomeIcon icon={faArrowLeft} />}
                    ></Button>
                    <Button
                      type="primary"
                      onClick={submitButton}
                      className="theme-2-button-1"
                      icon={
                        <FontAwesomeIcon
                          icon={formType === "view" ? faEdit : faSave}
                        />
                      }
                    ></Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          }
        >
          <Row justify="center">
            <Col xxl={22} xl={22} lg={22} md={22} sm={24} xs={24}>
              <Row>
                <Col span={24}>
                  <Form.Item label="Language">
                    <Radio.Group
                      onChange={onChangeLang}
                      value={lang.currentLang}
                    >
                      {lang.langList.map((lang) => {
                        return (
                          <Radio.Button key={lang.id} value={lang.id}>
                            {lang.name}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                    {formType !== "view" && lang.availableLang.length > 0 && (
                      <Dropdown
                        overlay={menu}
                        trigger={["click", "hover"]}
                        placement="bottomCenter"
                        arrow
                      >
                        <Button
                          shape="circle"
                          icon={<Icon icon={faPlus} />}
                          style={{ marginLeft: "10px" }}
                        ></Button>
                      </Dropdown>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {renderFormContent(
                newsFeed.content.find(
                  (x) => x.content_language_id === lang.currentLang
                )
              )}
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};
