import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services";
import { checkPermission } from "../utils/checkPermission";
import get from "lodash/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import theme from "../theme";
import { Tooltip, Row, Col, Button, Input, Space,Checkbox } from "antd";
import { Icon, Card, CardTable } from "../components";
import { faPlus, faEye, faTimes } from "@fortawesome/free-solid-svg-icons";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import Modal from "react-awesome-modal";
import Time from 'react-time-format'
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import styled from "styled-components";
import { Editor } from "react-draft-wysiwyg";
import editorText from "../constant/editorText";

const swal = withReactContent(Swal);
const EditorWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.borderColor};
`;

export default function ConsentManagement() {
  const history = useHistory();
  const [data, setData] = useState();
  const [selectedConsent, setSelectedConsent] = useState("");
  const [viewConsentModal, setViewConsentModal] = useState(false);
  const fetchConsent = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/consents`,
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.message === "success") {
          setData(get(res, "data.data"));
        }
      })
  };
  useEffect(() => {
    fetchConsent();
  }, []);

  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters , confirm)}
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters , confirm) => {
    clearFilters();
    setState({ searchText: "" });
    confirm();
  };

  const formatDate = (issued_datetime) => {
    issued_datetime = issued_datetime * 1000
    let d = new Date(issued_datetime);

    return d.getTime()
  }


  const renderPreviewConsent = (consent) => {
    let strippedHtml = "";
    let previewLength = 15

    if (consent == null) return ""
    else strippedHtml = consent.replace(/<[^>]+>/g, '');
    if (strippedHtml.length <= previewLength ) return strippedHtml
    return strippedHtml.slice(0, previewLength) + "..."
  }

  const [engConsent, setEngConsent] = useState();
  const [thaiConsent, setThaiConsent] = useState();
  const openConsentModal = (consent) => {
    setThaiConsent(EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(consent.content_th)
      )
    ))// stored Thai consent message.
    if(consent.content_en) setEngConsent(EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(consent.content_en)
      )
    )) // stored Eng consent message.
    setSelectedConsent(EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(consent.content_th)
      )
    ))
    // setThaiConsent(consent.;
    setViewConsentModal(true);
  };
  const handleConsentCancel = () => {
    setViewConsentModal(false)
  };
  const setLang = (lang) => {
    let selectConsent = ""
    if (lang == "th") {
      selectConsent = thaiConsent;
    }
    else if(lang ==  "en") {
      selectConsent = engConsent;
    }

    if (selectConsent == null) {
      setSelectedConsent("N/A");
    }
    else {
      setSelectedConsent(selectConsent);
    }
  
    setViewConsentModal(true);
  }
  const columns = [
    {
      title: "Consent Message - TH",
      dataIndex: "content_th",
      key: "id",
      align: "center",
      className: "theme-2-table-text",
      width: "40%",
      ellipsis: true,
      render: (content_th) => (
        <div>
          {renderPreviewConsent(content_th)}
        </div>
      ),
    },
    {
      title: "Consent Message - EN",
      dataIndex: "content_en",
      key: "id",
      align: "center",
      className: "theme-2-table-text",
      width: "40%",
      ellipsis: true,
      render: (content_en) => (
        <div>
          {renderPreviewConsent(content_en)}
        </div>
      ),
    },
    {
      title: "Publish Date",
      dataIndex: "issued_datetime",
      key: "id",
      align: "center",
      className: "theme-2-table-text",
      width: "30%",
      responsive: ["sm"],
      render: (issued_datetime) => (
        <div>
          <Time value={formatDate(issued_datetime)} format="DD/MM/YYYY hh:mm:ss" />
        </div>
      ),
    },
    {
      title: "Action",
      key: "id",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
      render: (consent) => (
        <Tooltip title="View Consent" color={theme.primaryColor}>
          <Button
            icon={<Icon icon={faEye} />}
            type="text"
            onClick={() => {
              openConsentModal(consent);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="theme-2-content">
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card margin="20px 0">
            <Row justify="space-between">
              <Col xs={12}>
                <div style={{ marginTop: "10px" }}>
                  <p className="theme-2-text-1">{"All Consent \xa0"}</p>
                  <p className="theme-2-text-2">
                    {get(data, "length") ? get(data, "length") : 0}
                    {"\xa0Total consent"}
                  </p>
                </div>
              </Col>
               {checkPermission("1") && ( 
              <Col
                xs={0}
                sm={8}
                style={{ textAlign: "right", marginTop: "2%" }}
              >
                <Button
                  className="theme-2-button-1"
                  icon={
                    <Icon margin="0 10px 0 0" color="white" icon={faPlus} />
                  }
                  type="primary"
                  onClick={() => {
                    history.push("/management/consent/createconsent");
                  }}
                >
                  Create Consent
                </Button>
              </Col>
               )}
              {checkPermission("1") && ( 
              <Col
                xs={2}
                sm={0}
                style={{ textAlign: "right", marginTop: "8%" }}
              >
                <Tooltip title="Create Consent" color={theme.primaryColor}>
                  <Button
                    icon={<Icon color="white" icon={faPlus} />}
                    type="primary"
                    shape="circle"
                    onClick={() => {
                      history.push("/management/consent/createconsent");
                    }}
                  />
                </Tooltip>
              </Col>
               ) }
            </Row>
            <Row justify="center">
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
              <Col xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
                <CardTable
                  rowKey="id"
                  columns={columns}
                  dataSource={data}
                  bordered={false}
                  pagination={{ position: ["bottomCenter"] }}
                  size="middle"
                />
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        visible={viewConsentModal}
        width="70%"
        height="75%"
        effect="fadeInDown"
      >
        <Row
          justify="center"
          align="middle"
          style={{ height: "100%", padding: "20px" }}
        >
          <Icon
            icon={faTimes}
            color="grey"
            style={{ position: "absolute", top: "10px", right: "10px" }}
            onClick={() => setViewConsentModal(false)}
          />
          <h1 style={{ margin: 0, paddingBottom: "0.5em" }}>Consent Message</h1>

          <div style={{ width: "100%", height: "80%", overflow: "auto" }}>
            <Col span={12}>
              <div style={{ marginTop: '10px', }}>
                <Row >
                  <Col span={6} >
                    <h4 style={{ margin: 0, paddingRight: "0.5em" }}>Language : </h4>
                  </Col>
                  <Col span={8}>
                    <Button
                      type="default"
                      className="theme-2-button-consent"
                      onClick={() => setLang("th")}
                      style={{marginRight:"10px"}}
                    >
                      ไทย
                    </Button>
                    {engConsent && (
                    <Button
                      type="default"
                      className="theme-2-button-consent"
                      onClick={() => setLang("en")}
                    >
                      ENG
                    </Button>
                    )}
                  </Col>
                  
                </Row>
              </div>
            </Col>
            <Col span={24} style={{height:"80%"}}>
            <h4>Content : </h4>
            <EditorWrapper>
              <Editor
                readOnly
                toolbarHidden
                editorStyle={{ margin: "20px" }}
                editorState={selectedConsent}
                localization={{
                  translations: editorText,
                }}
              />
            </EditorWrapper>
            </Col>
            
          </div>
          <Col span={24} style={{textAlign:'right',margin:'5px'}}>
            <Button
            key="back"
            className="theme-2-button-2"
            onClick={() => setViewConsentModal(false)}
          >
            Close
          </Button>
            </Col>
        </Row>
      </Modal>
    </div>
  );
}
