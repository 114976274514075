import React from 'react';
import styled from 'styled-components';
import theme from '../theme';
import { Row, Col, Space } from 'antd';
import { Icon } from '../components';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { UserAvatar } from './UserAvatar';

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const UserCardWrapper = styled.div`
  max-width: 150px;
  padding: 20px 10px;
  border: 1px solid ${(props) => props.theme.grey};
  border-radius: 5px;
  display: inline-block;
`;

const AvatarWrapper = styled.div`
  margin-bottom: 10px;
`;

export const UserCard = ({ user }) => {
  return (
    <UserCardWrapper>
      <Row justify="center">
        <AvatarWrapper>
          <UserAvatar fontSize="30px" imageUrl={user.face_img} />
        </AvatarWrapper>
        <Col span={24}>
          <CenteredDiv>
            <Space direction="vertical" size={2} style={{ width: '100%' }}>
              <p
                className="theme-2-text-5"
                style={{ textAlign: 'center', margin: 0 }}
              >
                {user.firstname} {user.lastname}
              </p>
              <Space>
                <Icon color={theme.grey} margin="0 0 0 3%" icon={faEnvelope} />
                <p className="theme-2-text-6">{user.email}</p>
              </Space>
              <Space>
                <Icon color={theme.grey} margin="0 0 0 3%" icon={faPhone} />
                <p className="theme-2-text-6">{user.telephone_number}</p>
              </Space>
            </Space>
          </CenteredDiv>
        </Col>
      </Row>
    </UserCardWrapper>
  );
};
