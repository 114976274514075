export const getAllLang = (dispatch) => {
  const dataLang = [{
    id: 'th',
    name : 'ไทย',
    is_default_language:1
  },{
    id: 'en',
    name : 'english',
    is_default_language:0
  }
];
dispatch({
  type: "set_all_lang",
  data: dataLang,
});
};

export const setCurrentLang = (dispatch, dataLang) => {
  return dispatch({
    type: "set_current_lang",
    data: dataLang,
  });
};

export const addNewLang = (dispatch, dataLang) => {
  return dispatch({
    type: "add_new_lang",
    data: dataLang,
  });
};

export const setLangList = (dispatch, dataLang) => {
  return dispatch({
    type: "set_lang_list",
    data: dataLang,
  });
};
