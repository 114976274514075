const comparer = (otherArray) => {
  return function (current) {
    return (
      otherArray.filter(function (other) {
        return other.id == current.id;
      }).length == 0
    );
  };
};

const getDifferentElement = (a, b) => {
  const onlyInA = a.filter(comparer(b));
  const onlyInB = b.filter(comparer(a));

  return [...onlyInA, ...onlyInB];
};
export default getDifferentElement;
