import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import notFoundImg from "../assets/images/404-Error-NotFound.png";
import styled from "styled-components";

const Title = styled.h1`
  text-align: center;
`;
export const DataNotFound = ({ manageAccess }) => {
  const history = useHistory();
  return (
    <div>
      {!manageAccess && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            onClick={() => {
              history.goBack();
            }}
            className="theme-2-button-2"
            icon={
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ marginRight: "10px" }}
              />
            }
          >
            Back
          </Button>
        </div>
      )}

      <Row justify="center">
        <Col xs={24} sm={12} md={12} lg={8}>
          <img style={{ width: "100%" }} alt="Not Found" src={notFoundImg} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Title>Data Not Found</Title>
        </Col>
      </Row>
    </div>
  );
};
