import React from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Theme from "../theme/theme_2";
import { BuildingForm } from "../components";
import Cookies from 'universal-cookie';

const swal = withReactContent(Swal);

export default function CreateBuilding() {
  const history = useHistory();
  const cookies = new Cookies();
  const createBuildingSuccess = (values) => {
    swal.fire({
      icon: "success",
      title: "Success",
      text: `Building "${values.name}" is successfully created`,
      showConfirmButton: true,
      confirmButtonColor: "#2F79FF",
      confirmButtonText: "Close",
    });
    history.push("/project");
  };

  const submitForm = (fieldsValue) => {
    const values = {
      name: fieldsValue["buildingName"],
      phone: fieldsValue["telephone"],
      juristic_admins: fieldsValue["juristicAdmin"]
    };
    const option = {
      method: "POST",
      url: `${process.env.REACT_APP_API}/buildings`,
      headers:{
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
      data: values,
    };
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });

        if (res.data.code === 201) {
          createBuildingSuccess(values, res.data);
        } else {
          swal.fire({
            icon: "error",
            title: get(res, "data.code"),
            text: get(res, "data.message"),
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: "error",
          title: 'Created failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: "#2F79FF",
          confirmButtonText: "Close",
        });
      });
  };
  return (
    <div>
      <Theme PageName={"Create Building"}>
        <BuildingForm type="create" submitForm={submitForm} />
      </Theme>
    </div>
  );
}
