import React from "react";
import Theme from "../theme/theme_2";
import ManageAccess from "../components/UserManageAccess";
import { useParams } from "react-router-dom";

export default function UserManageAccess() {
  const { userID } = useParams();
  return (
    <div>
      <Theme PageName={"User Management Access"}>
        <ManageAccess userID={userID} />
      </Theme>
    </div>
  );
}
