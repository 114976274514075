import React from "react";
import Theme from "../theme/theme_2";
import ViewRequest from "../components/ViewRequest";

export default function viewOrganization() {
  return (
    <div>
      <Theme PageName={"Request Detail"}>
        <ViewRequest />
      </Theme>
    </div>
  );
}
