const theme = {
  fontFamily: "Poppins",
  primaryColor: "#007F7B",
  primaryText: "#007F7B",
  secondaryText: "#005953",
  menuItemColor: "#005953",
  bgTextButton: "#EBF8FF",
  bgIconButton: "#F3F8FF",
  exitFloor: "#ACC8E5",
  disableColor: "#e3e3e3",
  bgGrey: "#F5F5F5",
  blue: "#20416C",
  red: "#f5222d",
  green: "#43B05C",
  yellow: "#FFBA00",
  white: "#FFFFFF",
  black: "#464646",
  grey: "rgba(0, 0, 0, 0.25)",
  backgroundColor: "#F8FBFF",
  boxShadow: "-0.1px -2px 29px 0 rgba(53, 50, 48, 0.21)",
  borderRadius: "14px",
  borderColor: "#D9D9D9",
};

export default theme;
