import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Theme from "../theme/theme_2";
import { DataNotFound } from "../components";
import EditProject from "../components/EditProject";
import Cookies from 'universal-cookie';

const swal = withReactContent(Swal);
const cookies = new Cookies();
export default function EditProjectPage() {
  const history = useHistory();
  const [initialValue, setInitialValue] = useState();

  const setProjectInfo = (data) => {
    setInitialValue({
      projectName: data.name,
      address: data.address,
      telephone: data.phone,
    });
  };

  const getProjectInfo = () => {
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/project`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.message === "success") {
          setProjectInfo(get(res, 'data.data'));
        }
      });
  };

  useEffect(() => {
    getProjectInfo();
  }, []);

  const editProjectSuccess = (values) => {
    swal.fire({
      icon: "success",
      title: "Project edited",
      text: `Project "${values.name}" is successfully edited`,
      showConfirmButton: true,
      confirmButtonColor: "#2F79FF",
      confirmButtonText: "Close",
    });
    history.push("/project");
  };

  const submitForm = (fieldsValue) => {
    const values = {
      name: fieldsValue["projectName"],
      address: fieldsValue["address"],
      phone: fieldsValue["telephone"],
    };
    const option = {
      method: "PUT",
      url: `${process.env.REACT_APP_API}/project`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
      data: values,
    };
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.message === "success") {
          editProjectSuccess(values, res.data);
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: "error",
          title: 'Updated failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: "#2F79FF",
          confirmButtonText: "Close",
        });
      });
  };
  return (
    <div>
      <Theme PageName={"Edit Project"}>
        {initialValue && (
          <EditProject initialValue={initialValue} submitForm={submitForm} />
        )}
        {!initialValue && <DataNotFound />}
      </Theme>
    </div>
  );
}
