import React from "react";
import Theme from "../theme/theme_2";
import InviteSeminar from "../components/InviteSeminar";

export default function inviteSeminar() {
  return (
    <div>
      <Theme PageName={"Seminar Visitor Invite"}>
        <InviteSeminar />
      </Theme>
    </div>
  );
}
