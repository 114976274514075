import React from "react";
import Theme from "../theme/theme_1";
import LoginForm from "../components/LoginForm";

export default function login() {
  return (
    <div>
      <Theme>
        <LoginForm />
      </Theme>
    </div>
  );
}
