import styled, { css } from "styled-components";

const baseShadow = css`
box-shadow: 0px 2px 10px rgb(0 89 83);
`;

export const HeaderCard = styled.div`
  ${baseShadow}
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background: ${(props) => props.theme.white};
  border-radius: 10px;
`;

export const Card = styled.div`
  ${baseShadow}
  padding: 20px;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  background: ${(props) => props.theme.white};
  border-radius: 10px;
`;
