import React from 'react';
import Theme from '../theme/theme_2';
import ViewReport from '../components/ViewReport';
function Report() {
  return (
    <Theme PageName={'Report'}>
      <ViewReport />
    </Theme>
  );
}

export default Report;
