import React from "react";
import Theme from "../theme/theme_2";
import { NewsForm } from "../components";

const createNewsFeed = () => {
  return (
    <div>
      <Theme PageName={"Create News Feed"}>
        <NewsForm formType="create" />
      </Theme>
    </div>
  );
};

export default createNewsFeed;
