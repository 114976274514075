import React, { useState } from "react";
import Cookies from "universal-cookie";
import { api } from "../services";
import get from "lodash/get";
import styled from "styled-components";
import theme from "../theme";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Icon } from ".";
import Avatar from "antd/lib/avatar/avatar";
import { setNewsContentAtCurrLang, setPromoContentAtCurrLang } from "../action";

const cookies = new Cookies();

const InputFile = styled.input`
  visibility: hidden;
  position: absolute;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

export const InputThumbnail = ({
  title,
  content,
  folderName,
  type,
  lang,
  thumbnail,
  dispatch,
  onChangeFn,
}) => {
  
  const [value, setvalue] = useState("");
  const handleUploadClick = (event) => {
    
    let bodyFormData = new FormData();

    bodyFormData.append("file", event.target.files[0]);
    bodyFormData.append("file_type", type);
    const option = {
      method: "post",
      url: `${process.env.REACT_APP_API}/upload`,
      headers: {
        Authorization: `Bearer ${cookies.get("accessToken")}`,
        "Content-Type": "multipart/form-data",
      },
      data: bodyFormData,
    };
    
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.message === "success") {
          const url = get(res, "data.data");
          onChangeFn(null, { title: content.title, thumbnail: url }, null);
          setvalue(event.target.value);
          const data = {
            key: "thumbnail",
            value: url,
            lang: lang,
          };
          if (title === "newsFeed") {
            setNewsContentAtCurrLang(dispatch, data);
          } else {
            setPromoContentAtCurrLang(dispatch, data);
          }
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: "error",
          title: 'Created failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: "#2F79FF",
          confirmButtonText: "Close",
        });
      });
  };

  return (
    <div>
      <InputFile
        accept="image/*"
        id="avatar"
        type="file"
        value={value}
        onChange={handleUploadClick}
      />
      <div
        style={{
          position: "relative",
          width: "fit-content",
          height: "fit-content",
        }}
      >
        {type !== "view" && (
          <label
            htmlFor="avatar"
            style={{
              position: "absolute",
              zIndex: 1,
              top: "-15px",
              right: "-15px",
            }}
          >
            <Avatar
              style={{
                background: theme.white,
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
              icon={<Icon icon={faPen} />}
            />
          </label>
        )}
        <div
          style={{
            background: theme.white,
            borderRadius: "5px",
            border: `5px solid ${theme.white}`,
            boxSizing: "border-box",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          }}
        >
          {thumbnail && thumbnail.length > 0 ? (
            <AvatarImage src={thumbnail} alt="Img error" />
          ) : (
            <div>
              <AvatarImage
                src={
                  "https://shahpourpouyan.com/wp-content/uploads/2018/10/orionthemes-placeholder-image-1.png"
                }
                alt="Img error"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
