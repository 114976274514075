import "./App.less";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";
import Loading from "./components/Loading";

import { Provider } from "react-redux";
import { createStore } from "redux";
import reducer from "./redux";
const store = createStore(reducer);

global.store = store;

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Loading />
        <Router />
      </BrowserRouter>
    </Provider>
  );
}
