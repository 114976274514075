import React , { useState } from 'react';
import Cookies from "universal-cookie";
import { api } from "../services";
import Swal from 'sweetalert2';
import get from "lodash/get";
import withReactContent from 'sweetalert2-react-content';
import styled from 'styled-components';
import theme from '../theme';
import { faUserCircle, faPen } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '.';
import Avatar from 'antd/lib/avatar/avatar';

const cookies = new Cookies();

const InputFile = styled.input`
  visibility: hidden;
  position: absolute;
`;

const AvatarImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
`;

const swal = withReactContent(Swal);

export const InputAvatar = ({imageUrl , onChangeFn,isuserPermission,userType}) => {
  const [value, setvalue] = useState();
  let JuristicPermission = true;
  let orgPermission = false;
  if (userType == 2 && isuserPermission.user_type == 4) {
    JuristicPermission = true;
    
  }
  else if(userType == 4){
    orgPermission = true;
  }
  else if(userType == 1){
    JuristicPermission = true;
   
  }
  else{
    JuristicPermission = true;
    orgPermission = false;
  }
  const handleUploadClick = (event) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    bodyFormData.append("file_type", 'profile');
    const option = {
      method: "post",
      url: `${process.env.REACT_APP_API}/upload`,
      headers: {
        Authorization: `Bearer ${cookies.get("accessToken")}`,
        "Content-Type": "multipart/form-data",
      },
      data: bodyFormData,
    };
    
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });

        if (res.data.message === "success") {
          const url = get(res, "data.data");
          onChangeFn(url);
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: "error",
          title: 'Created failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: "#2F79FF",
          confirmButtonText: "Close",
        });
      });
  };

  return (
    <div>
      <InputFile 
      accept="image/*" 
      id="avatar" 
      type="file" 
      value={value}
      onChange={handleUploadClick}/>
      <div
        style={{
          position: 'relative',
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
         <label
            htmlFor="avatar"
            style={{
              position: 'absolute',
              zIndex: 1,
              top: '-15px',
              right: '-15px',
            }}
          >
            <Avatar
              style={{
                background: theme.white,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              }}
              icon={<Icon icon={faPen} />}
            />
          </label>
         {/* {orgPermission && ( 
          <label
            htmlFor="avatar"
            style={{
              position: 'absolute',
              zIndex: 1,
              top: '-15px',
              right: '-15px',
            }}
          >
            <Avatar
              style={{
                background: theme.white,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              }}
              icon={<Icon icon={faPen} />}
            />
          </label>
        )} 
        {JuristicPermission && (
          <label
            htmlFor="avatar"
            style={{
              position: 'absolute',
              zIndex: 1,
              top: '-15px',
              right: '-15px',
            }}
          >
            <Avatar
              style={{
                background: theme.white,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              }}
              icon={<Icon icon={faPen} />}
            />
          </label>
        )}  */}
        <div
          style={{
            background: theme.white,
            borderRadius: '5px',
            border: `5px solid ${theme.white}`,
            boxSizing: 'border-box',
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
          }}
        >
          {imageUrl && imageUrl.length > 0 ? (
            <AvatarImage src={imageUrl} alt="Img error" />
          ) : (
            <div>
              <Icon
                style={{ width: '150px', height: '150px' }}
                icon={faUserCircle}
                fontSize="100px"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
