import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../view/login';
import ForgotPassword from '../view/forgotPassword';
import ResetPassword from '../view/resetPassword';
import Project from '../view/project';
import RegisterNewUser from '../view/registerNewUser';
import InviteSeminar from '../view/inviteSeminar';
import UpdateProfile from '../view/updateUserProfile';
import UserManagement from '../view/userManagement';
import EditProject from '../view/editProject';
import CreateBuilding from '../view/createBuilding';
import EditBuilding from '../view/editBuilding';
import Building from '../view/building';
import UserManageAccess from '../view/userManageAccess';
import OrganizationManagement from '../view/organizationManagement';
import CreateOrganization from '../view/createOrganization';
import EditOrganization from '../view/editOrganization';
import ViewOrganization from '../view/viewOrganization';
import NewsFeedManagement from '../view/newsFeedManagement';
import Cookies from 'universal-cookie';
import { checkPermission } from '../utils/checkPermission';
import ViewNewsFeed from '../view/viewNewsFeed';
import CreateNewsFeed from '../view/createNewsFeed';
import EditNewsFeed from '../view/editNewsFeed';
import PromotionManagement from '../view/promotionManagement';
import CreatePromotion from '../view/createPromotion';
import ViewPromotion from '../view/viewPromotion';
import EditPromotion from '../view/editPromotion';
import ConsentManagement from '../view/consentManagement';
import Log from '../view/log';
import ViewEntryExitLog from '../view/viewEntryExitLog';
import CreateConsent from '../view/createConsent';
import PageNotFound from '../view/pageNotFound';
import WebStamp from '../view/viewWebStamp';
import { getAllLang } from '../action';
import { useDispatch } from 'react-redux';
import ApprovalRequest from '../view/approvalRequest';
import ViewRequest from '../view/viewRequest';
import receptionManagement from '../view/receptionManagement';
import createReception from '../view/createReception';
import editReception from '../view/editReception';
import addCar from '../view/addCar';
import coupon from '../view/coupon';
import blackList from '../view/blackList';
import viewCars from '../view/viewCar';
import EditCar from '../view/EditCar';
import cars from '../view/carManagement';
import parkingManagement from '../view/viewParking';
import webStamp from '../view/webStamp';
import report from '../view/report';
const cookies = new Cookies();

export default function Router() {
  const dispatch = useDispatch();
  useEffect(() => {
    getAllLang(dispatch);
  }, []);

  const AuthRoute = ({ component: Component, permission, ...rest }) => {
    if(permission != null)
    {
      const permissionArr = permission.split("/")
      let permit = false;
      if(permissionArr.length != 0) 
        for(let  i = 0 ; i < permissionArr.length ; i++) 
          if(checkPermission(permissionArr[i])) permit = true
      if(permit) {
        return (
          <Route
            {...rest}
            render={(props) => {
              return cookies.get('accessToken') &&
                cookies.get('refreshToken')  ? (
                <Component {...props } />
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                  }}
                />
              );
            }} 
          />
        );
      }
      else {
        return (
          <Route
          {...rest}
          render={(props) => {
            return cookies.get('accessToken') &&
              cookies.get('refreshToken')  ? (
                <Redirect
                to={{
                  pathname: '/error',
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                }}
              />
            );
          }} 
          />
        )
      }
    }
    else
    {
      return (
        <Route
          {...rest}
          render={(props) => {
            return cookies.get('accessToken') &&
              cookies.get('refreshToken')  ? (
              <Component {...props } />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                }}
              />
            );
          }} 
        />
      );
    }
  };

  const PublicRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => { 
          return cookies.get('accessToken') &&
            cookies.get('refreshToken') ? (
            <Redirect
              to={{
                pathname: '/project',
              }}
            />
          ) : (
            <Component {...props} />
          );
        }}
      />
    );
  };

  return (
    <div>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <PublicRoute exact sensitive strict path="/login" component={Login} />
        <Route
          exact
          sensitive
          strict
          path="/forgotpassword"
          component={ForgotPassword}
        />
        <Route
          exact
          sensitive
          strict
          path="/reset-password/:forgotToken"
          component={ResetPassword}
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/project"
          permission="1/2/4"
          component={Project}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="4"
          path="/parking/web-stamp"
          component={WebStamp}
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/parking"
          component={parkingManagement}
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/editproject"
          permission="1"
          component={EditProject}
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/registernewuser"
          permission="4"
          component={RegisterNewUser}
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/invite-visitor"
          permission="2"
          component={InviteSeminar}
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/updateprofile"
          component={UpdateProfile}
        />
         <AuthRoute
          exact
          sensitive
          strict
          permission="2/4"
          path="/coupon"
          component={coupon}
        />
         <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/black-list"
          component={blackList}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/parking/add_car"
          component={addCar}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/parking/cars"
          component={cars}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/parking/view_cars/:carID"
          component={viewCars}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/parking/edit_car/:carID"
          component={EditCar}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="1/2/4"
          path="/management/users"
          component={UserManagement}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="1/2/4"
          path="/management/users/:userID"
          component={UserManageAccess}
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/building/:buildingID"
          permission="1/2/4"
          component={Building}
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/createbuilding"
          permission="1"
          component={CreateBuilding}
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/editbuilding/:buildingID"
          permission="1"
          component={EditBuilding}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2/4"
          path="/management/organization"
          component={OrganizationManagement}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2/4"
          path="/management/organization/vieworganization/:organizeID"
          component={ViewOrganization}
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/management/organization/createorganization"
          permission="2"
          component={CreateOrganization}
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/management/organization/editorganization/:organizeID"
          permission="2"
          component={EditOrganization}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/management/reception"
          component={receptionManagement}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/management/reception/createreception"
          component={createReception}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/management/reception/editreception/:receptionID"
          component={editReception}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/management/newsFeed"
          component={NewsFeedManagement}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/management/newsFeed/viewnewsFeed/:id"
          component={ViewNewsFeed}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/management/newsFeed/createnewsFeed"
          component={CreateNewsFeed}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/management/newsFeed/editnewsFeed/:id"
          component={EditNewsFeed}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/management/promotion"
          component={PromotionManagement}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/management/promotion/createpromotion"
          component={CreatePromotion}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/management/promotion/viewpromotion/:id"
          component={ViewPromotion}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/management/promotion/editpromotion/:id"
          component={EditPromotion}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="1/2"
          path="/management/consent"
          component={ConsentManagement}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="1"
          path="/management/consent/createconsent"
          component={CreateConsent}
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/building-log"
          permission="1/2"
          component={Log}
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/entry-exit"
          permission="1/2"
          component={ViewEntryExitLog}
        />
         <AuthRoute
          exact
          sensitive
          strict
          path="/webStamp"
          permission="1/2"
          component={webStamp}
          
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/view-report"
          permission="2"
          component={report}
          
        />
        <AuthRoute
          exact
          sensitive
          strict
          path="/error"
          component={PageNotFound}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/request_approval"
          component={ApprovalRequest}
        />
        <AuthRoute
          exact
          sensitive
          strict
          permission="2"
          path="/request_approval/view_request"
          component={ViewRequest}
        />
        <Redirect to="/error" />
      </Switch>
    </div>
  );
}
