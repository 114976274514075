import React,{ useContext } from "react";
import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import { Modal, Form, Col, Row, Select, Image, Input } from "antd";
import { SelectIcon } from "./icon";
import { Icon } from ".";
import {
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import Cookies from 'universal-cookie';

const swal = withReactContent(Swal);
const cookies = new Cookies();

export const FromEstemModal = ({ visible, onCancel, onData, stampTemplate }) => {
  const [addStampRate] = Form.useForm();

  const submitForm = (stampRate,remark, trn_no) => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const value = {
      stamp_code: stampRate ? stampRate:"" ,
      trn_no_entry: trn_no,
      remark:remark ? remark:""
    };
    const option = {
      method: "POST",
      url: `${process.env.REACT_APP_API}/stamp/webstamp`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
      data: value,
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
          swal.fire({
            icon: "success",
            title: "Success",
            text: "Stamprate is created.",
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
        }
        else {
          swal.fire({
            icon: "error",
            title: 'Created failed',
            text: res.data.message,
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: "error",
          title: 'Created failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: "#2F79FF",
          confirmButtonText: "Close",
        });
      });
  };

  return (
    <Modal
      visible={visible}
      title="Web-Stamp"
      okButtonProps={{ className: "theme-2-button-1" }}
      okText="Save"
      cancelButtonProps={{ className: "theme-2-button-2" }}
      cancelText="Cancel"
      maskClosable={false}
      onCancel={onCancel}
      onOk={() => {
        addStampRate
          .validateFields()
          .then((values) => {
            submitForm(values.stampRate,values.remark, get(onData, "trn_no"));
            addStampRate.resetFields();
            onCancel()
          })
          .catch((info) => {
            console.log("validate failed = ", info);
          });
      }}
    >
      <Row>
        <Col span={8}>
          <h3 style={{ textAlign: 'center' }}>Date/Time </h3>
          <p style={{ textAlign: 'center' }}>{get(onData, "trn_log_date") ? get(onData, "trn_log_date") : "-"}</p>
        </Col>
        <Col span={8}>
          <h3 style={{ textAlign: 'center' }}>License Plate</h3>
          <p style={{ textAlign: 'center' }}>{get(onData, "lp_no") ? get(onData, "lp_no") : "-"}</p>
        </Col>
        <Col span={8}>
          <h3 style={{ textAlign: 'center' }}>Card No</h3>
          <p style={{ textAlign: 'center' }}>{get(onData, "card_no") ? get(onData, "card_no") : "-"}</p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <h3 style={{ textAlign: 'center' }}>Car Pic </h3>
          <div style={{ textAlign: 'center' }}>
            <Image className="loading_image" src={get(onData, "image_vehicle_url") ? get(onData, "image_vehicle_url") : ""} style={{ width: '150px' }} />
          </div>
        </Col>
        <Col span={12}>
          <h3 style={{ textAlign: 'center' }}>Driver Pic</h3>
          <div style={{ textAlign: 'center' }}>
            <Image className="loading_image" src={get(onData, "image_driver_url") ? get(onData, "image_driver_url") : ""} style={{ width: '150px' }} />
          </div>
        </Col>
      </Row>

      <Form
        form={addStampRate}
        layout="vertical"
        name="addStampRate"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          label="Stamp Rate"
          name="stampRate"
          rules={[
            {
              required: true,
              message: "Please select stamp rate!",
            }
          ]}
        >
          <Select
            style={{ width: "100%" }}
            suffixIcon={<SelectIcon />}
            defaultValue={"Select stamp rate"}
            menuItemSelectedIcon={<Icon icon={faCheck} />}
          >
            {stampTemplate.map((item) => {
              return (
                <Option key={item.id} value={item.stamp_code}>
                  {item.stamp_code +" : " +item.template_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Remark"
          name="remark"
        >
          <Input style={{ width: "100%" }}></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};
