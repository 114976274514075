import includes from "lodash/includes";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const checkPermission = (permission) => {
  const userPermission = cookies.get("permission");
  
  if (permission) {
    return includes(userPermission, permission);
  } else {
    return true;
  }
};
