export const categories = [
  { value: 0, label: 'ไม่ระบุสิทธิ์' },
  { value: 1, label: 'ผู้บริหาร' },
  { value: 2, label: 'รถหน่วยงาน' },
  { value: 3, label: 'สิทธิ์จับฉลาก' },
  { value: 4, label: 'พนักงานกะ' },
  { value: 5, label: 'พนักงานตั้งครรภ์' },
  { value: 6, label: 'ผู้ทุพพลภาพ' },
];

export const roles = [
  { id: 1, user_type: 'Project Owner' },
  { id: 2, user_type: 'Juristic Admin' },
  { id: 3, user_type: 'Reception' },
  { id: 4, user_type: 'Organize Admin' },
  { id: 5, user_type: 'Member' },
  { id: 6, user_type: 'Visitor' },
  { id: 7, user_type: 'Walk-in Visitor' },
  { id: 8, user_type: 'Seminar Visitor' },
];

export const entryLogRoles = [
  { id: 1, user_type: 'Member' },
  { id: 2, user_type: 'Visitor' },
  { id: 3, user_type: 'VIP' },
];
