import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services";
import get from "lodash/get";
import isequal from "lodash/isEqual";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import theme from "../theme";
import { Tooltip, Row, Col, Button, Input, Space } from "antd";
import { Icon, Card, CardTable } from "../components";
import {
  faEdit,
  faPlus,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { ComparePassword } from "./ComparePassword";
import Time from 'react-time-format'

const swal = withReactContent(Swal);

export default function NewsFeedManagement() {
  const history = useHistory();
  const [data, setData] = useState();

  const fetchNewsFeed = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/advertisements`,
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.message === "success") {
          var data = [];
          for(var i = 0 ; i < res.data.data.length ; i++)
            if(res.data.data[i].advertisement_type === "news") 
              data.push(res.data.data[i]);
          setData(data);
        }
      });
  };


  const renderTitle = (title) => {
    let previewLength = 15
    if (title == null) return ""
    if (title.length <= previewLength ) return title
    return title.slice(0, previewLength) + "..."
  }

  useEffect(() => {
    fetchNewsFeed();
  }, []);

  const formatDate = (issued_datetime) => {
    issued_datetime = issued_datetime * 1000
    let d = new Date(issued_datetime);

    return d.getTime()
  }
  
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      get(record, dataIndex)
        ? get(record, dataIndex)
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      isequal(state.searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
    {
      title: "Thai Title",
      dataIndex: ["title_th"],
      key: "id",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
      ...getColumnSearchProps(["title_th"]),
      render: (title_th) => (
        <div>
          {renderTitle(title_th)}
        </div>
      ),
    },
    {
      title: "English Title",
      dataIndex: ["title_en"],
      key: "id",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
      ...getColumnSearchProps(["title_en"]),
      render: (title_en) => (
        <div>
          {renderTitle(title_en)}
        </div>
      ),
    },
    {
      title: "Publish Date",
      dataIndex: ["created_at"],
      key: "id",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
      responsive: ["sm"],
      render: (created_at) => (
        <div>
          <Time value={formatDate(created_at)} format="DD/MM/YYYY hh:mm:ss" />
        </div>
      ),
    },
    {
      title: "Action",
      key: "id",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
      render: (news) => (
        <Space size="middle">
          {/* {checkPermission("view_news") && ( */}
          <Tooltip title="View News" color={theme.primaryColor}>
            <Button
              icon={<Icon icon={faEye} />}
              type="text"
              onClick={() => {
                history.push(
                  `/management/newsFeed/viewnewsFeed/${get(news, "id")}`
                );
              }}
            />
          </Tooltip>
          {/* )} */}
          {/* {checkPermission("update_news") && ( */}
          <Tooltip title="Edit News" color={theme.primaryColor}>
            <Button
              icon={<Icon icon={faEdit} />}
              type="text"
              onClick={() => {
                history.push(
                  `/management/newsFeed/editnewsFeed/${get(news, "id")}`
                );
              }}
            />
          </Tooltip>
          {/* )} */}
          {/* {checkPermission("delete_news") && ( */}
          <Tooltip title="Delete News" color={theme.primaryColor}>
            <Button
              icon={<Icon icon={faTrash} />}
              type="text"
              onClick={() => {
                ComparePassword("news", news, fetchNewsFeed, "");
              }}
            />
          </Tooltip>
          {/* )} */}
        </Space>
      ),
    },
  ];

  return (
    <div className="theme-2-content">
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card margin="20px 0">
            <Row justify="space-between">
              <Col xs={12}>
                <p className="theme-2-text-1">{"All News \xa0"}</p>
                <p className="theme-2-text-2">
                  {get(data, "length") ? get(data, "length") : 0}
                  {"\xa0Total news"}
                </p>
              </Col>
              {/* {checkPermission("create_news") && ( */}
              <Col
                xs={0}
                sm={8}
                style={{ textAlign: "right", marginTop: "2%" }}
              >
                <Button
                  className="theme-2-button-1"
                  icon={
                    <Icon margin="0 10px 0 0" color="white" icon={faPlus} />
                  }
                  type="primary"
                  onClick={() => {
                    history.push("/management/newsFeed/createnewsFeed");
                  }}
                >
                  Create News
                </Button>
              </Col>
              {/* )} */}
              {/* {checkPermission("create_news") && ( */}
              <Col
                xs={2}
                sm={0}
                style={{ textAlign: "right", marginTop: "8%" }}
              >
                <Tooltip title="Create News" color={theme.primaryColor}>
                  <Button
                    icon={<Icon color="white" icon={faPlus} />}
                    type="primary"
                    shape="circle"
                    onClick={() => {
                      history.push("/management/newsFeed/createnewsFeed");
                    }}
                  />
                </Tooltip>
              </Col>
              {/* )} */}
            </Row>
            <Row justify="center">
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
              <Col xxl={20} xl={20} lg={20} md={20} sm={24} xs={24}>
                <CardTable
                  rowKey={(obj) => obj.id}
                  columns={columns}
                  dataSource={data}
                  bordered={false}
                  pagination={{ position: ["bottomCenter"] }}
                  size="middle"
                />
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
