import React from "react";
import Theme from "../theme/theme_2";
import NewsFeedManagement from "../components/NewsFeedManagement";

export default function newsFeedManagement() {
  return (
    <div>
      <Theme PageName={"News Feed Management"}>
        <NewsFeedManagement />
      </Theme>
    </div>
  );
}
