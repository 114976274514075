import React from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Theme from "../theme/theme_2";
import { ReceptionForm } from '../components/ReceptionForm';
import Cookies from 'universal-cookie';

const swal = withReactContent(Swal);

export default function createReception() {
  const history = useHistory();
  const cookies = new Cookies();
  const createOrganizeSuccess = (values) => {
    swal.fire({
      icon: "success",
      title: "Reception added",
      text: `Reception "${values.firstname}" is successfully added`,
      showConfirmButton: true,
      confirmButtonColor: "#2F79FF",
      confirmButtonText: "Close",
    });
    history.push("/management/reception");
  };

  const submitForm = (fieldsValue) => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const o = {
      method: "get",
      url: `${process.env.REACT_APP_API}/consents/latest`,
    }
    api(o)
      .then((res) => {
        if(res.data.code === 200){
          const values = {
            building_id: Number(get(fieldsValue, "building")),
            personal_id: get(fieldsValue, "citizenID"),
            email: get(fieldsValue, "email"),
            firstname: get(fieldsValue, "firstname"),
            lastname: get(fieldsValue, "lastname"),
            phone: get(fieldsValue, "phone"),
            password: get(fieldsValue, "password"),
            user_type:3,
            consent_id: res.data.data.id,
          };
          const option = {
            method: "post",
            url: `${process.env.REACT_APP_API}/users`,
            headers: {
              'Authorization': 'Bearer ' + cookies.get('accessToken'),
              'Content-Type': 'application/json'
            },
            data: values,
          };
          api(option)
          .then((res) => {
            global.store.dispatch({
              type: "set_loading_show",
              data: { show: false },
            });
    
            if (res.data.code === 201) {
              createOrganizeSuccess(values, res);
            } else {
              swal.fire({
                icon: "error",
                title: get(res, "data.code"),
                text: get(res, "data.message"),
                showConfirmButton: true,
                confirmButtonColor: "#2F79FF",
                confirmButtonText: "Close",
              });
            }
          })
          .catch((err) => {
            global.store.dispatch({
              type: "set_loading_show",
              data: { show: false },
            });
            swal.fire({
              icon: "error",
              title: 'Created failed',
              text: get(err.response.data, 'message'),
              showConfirmButton: true,
              confirmButtonColor: "#2F79FF",
              confirmButtonText: "Close",
            });
          });
      }
    })
  };
  return (
    <div>
      <Theme PageName={"Create Reception"}>
        <ReceptionForm type="create" 
         submitForm={submitForm} 
        />
      </Theme>
    </div>
  );
}
