import React, { useState } from "react";
import Modal from "react-awesome-modal";
import { Row, Col, Button } from "antd";
import { Icon } from "../components";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const Text = styled.div`
  text-align: center;
  font-size: 15px;
`;

const Title = styled.h1`
  text-align: center;
  margin: 0;
`;

export const SessionTimeoutModal = ({ visible }) => {
  const [modalVisible, setModalVisible] = useState(visible);

  return (
    <Modal
      style
      visible={modalVisible}
      width="400"
      height="300"
      effect="fadeInDown"
    >
      <Row
        justify="center"
        align="middle"
        style={{ height: "100%", padding: "20px" }}
      >
        <Icon fontSize="60px" icon={faExclamationCircle} />
        <Col span={24}>
          <Title>Session Expired</Title>
        </Col>

        <Text>Your session is expired, please log in again.</Text>
        <Button onClick={() => setModalVisible(false)}>OK</Button>
      </Row>
    </Modal>
  );
};
