import React, { useState, useEffect } from "react";
import sortBy from 'lodash/sortBy';
import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import theme from "../theme";
import { Tooltip, Row, Col, Button, Space, Form, Modal, Input, Select } from "antd";
import { Icon, Card, CardTable, Tag } from ".";
import {
  faCheck,
  faTimes,
  faMoneyBillWave,
  faPlus,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { SelectIcon } from "../components/icon";
import Cookies from 'universal-cookie';
import { format } from 'date-fns'
import { ComparePassword } from './ComparePassword';
import { checkPermission } from '../utils/checkPermission';
const swal = withReactContent(Swal);

export default function BlackList() {
  const [addBlackList] = Form.useForm();
  const [blackList, setBlackList] = useState([]);
  const [page, setPage] = useState("");
  const [isBlackList, setIsBlackList] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const cookies = new Cookies();

  const getBlackList = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const option = {
      method: "post",
      url: `${process.env.REACT_APP_API_SAPI}/blacklist/LoadBlackList`,
      headers: {
        'Authorization': 'Bearer ' + "011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7",
        'Content-Type': 'application/json'
      },
      data: {
        "SiteGUID": `${process.env.REACT_APP_SITEGUID}`,
      }
    }
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data) {
          setBlackList(res.data)
        }
      })
  }
  const deleteBlackList = (data) => {
    swal
      .fire({
        title: "Are you sure ?",
        text: "you want to delete black list" + 
        " " +
        '"' +
        data.lpNo +
        '"',
        icon: "warning",
        showConfirmButton: true,
        confirmButtonColor: "#2F79FF",
        confirmButtonText: "Confirm",
        showCancelButton: true,
        cancelButtonText: <div style={{ color: "#2F79FF" }}>Cancel</div>,
        cancelButtonColor: "#e3e3e3",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swal
            .fire({
              title: "Enter your password",
              input: "password",
              inputPlaceholder: "Enter your password",
              inputAttributes: {
                autocapitalize: "off",
                autocorrect: "off",
              },
              showConfirmButton: true,
              confirmButtonColor: "#2F79FF",
              confirmButtonText: "Confirm",
              showCancelButton: true,
              cancelButtonText: <div style={{ color: "#2F79FF" }}>Cancel</div>,
              cancelButtonColor: "#e3e3e3",
              reverseButtons: true,
              preConfirm: (password) => {
                if (!password) {
                  return Swal.showValidationMessage(`Please enter password`);
                }
              },
            })
            .then((result) => {
              if (result.isConfirmed) {
                global.store.dispatch({ type: "set_loading_show", data: { show: true } });
                const option = {
                  method: "post",
                  url: `${process.env.REACT_APP_API_SAPI}/blacklist/DeleteBlacklist`,
                  headers: {
                    'Authorization': 'Bearer ' + "011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7",
                    'Content-Type': 'application/json'
                  },
                  data: {
                    "SiteGUID": `${process.env.REACT_APP_SITEGUID}`,
                    "blackListGUID": data.blackListGUID
                  }
                }
                api(option)
                  .then((res) => {
                    global.store.dispatch({
                      type: "set_loading_show",
                      data: { show: false },
                    });
                    if (res) {
                      getBlackList();
                      swal.fire({
                        icon: "success",
                        title: "Deleted",
                        text: `Car "${get(data, "lpNo")}" is successfully delete`,
                        showConfirmButton: true,
                        confirmButtonColor: "#2F79FF",
                        confirmButtonText: "Close",
                      });
                    }
                  })
              }
            });
        }
      });
  }
  const getProvince = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/provinces`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
          setProvinces(get(res, "data.data"));
        }
      })
  };
  useEffect(() => {
    getBlackList();
    getProvince();
  }, []);

  const tagOrganizeAdminRender = (props) => {
    const { label, closable, onClose } = props;
    return (
      <Tag
        color={theme.bgTextButton}
        closable={closable}
        onClose={onClose}
        closeIcon={<Icon fontSize="10px" icon={faTimes} />}
      >
        {label}
      </Tag>
    );
  };

  const submitaddNewBlackList = (values) => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    setIsBlackList(false);

    const option = {
      method: "post",
      url: `${process.env.REACT_APP_API_SAPI}/blacklist/AddBlackList`,
      headers: {
        'Authorization': 'Bearer ' + "011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7",
        'Content-Type': 'application/json'
      },
      data: {
        "SiteGUID": `${process.env.REACT_APP_SITEGUID}`,
        "lpNo": values.lpNo,
        "provinceID": values.provinceID,
        "vehicleTypeID": 0,
        "brand": values.brand,
        "modelName": values.modelName,
        "color": values.color,
        "remark": values.remark,
        "isSuspend": 0
      }
    }
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res) {
          addBlackList.resetFields();
          getBlackList();
          swal.fire({
            icon: "success",
            title: "Success",
            text: `Car "${get(values, "lpNo")}" is successfully created`,
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: "error",
          title: 'Created failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: "#2F79FF",
          confirmButtonText: "Close",
        });
      });
  };

  const BlackListModal = () => {
    const handleCancel = () => {
      addBlackList.resetFields();
      setIsBlackList(false);
    };
    const handleSave = () => {
      setIsBlackList(false);
      addBlackList
        .validateFields()
        .then((values) => {
          submitaddNewBlackList(values);
        });
    };
    return (
      <Modal
        visible={isBlackList}
        width="40%"
        height="50%"
        title="Add Black List"
        onOk={handleSave}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            key="back"
            className="theme-2-button-2"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="theme-button-save"
            type="primary"
            onClick={handleSave}
          >
            Save
          </Button>,
        ]}
      >
        <Form
          name="AddBlackList"
          form={addBlackList}
          labelAlign="center"
          layout="vertical"
        >
          <Row align="middle" justify="center" >
            <Col span={24} style={{ textAlign: "left" }}>
              <h2>Add Black List</h2>
            </Col>
            <Col span={11}>
              <Form.Item
                name="lpNo"
                label="License Plate"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input license plate",
                  }
                ]}
              >
                <Input size="large" placeholder="License Plate" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item
                name="provinceID"
                label="Province"
                rules={[
                  {
                    required: true,
                    message: "Please input province name!",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  size="large"
                  suffixIcon={<SelectIcon />}
                  menuItemSelectedIcon={<Icon icon={faCheck} />}
                  filterOption={(input, option) =>
                    (option?.children)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {provinces.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                name="brand"
                label="Brand"
                rules={[
                  {
                    required: true,
                    message: "Please input brand!",
                  },
                ]}
              >
                <Input size="large" placeholder="Brand"></Input>
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item
                name="modelName"
                label="Model Name"
                rules={[
                  {
                    required: true,
                    message: "Please input model name!",
                  },
                ]}
              >
                <Input size="large" placeholder="Model Name"></Input>
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                name="color"
                label="Color"
                rules={[
                  {
                    required: true,
                    message: "Please input color!",
                  },
                ]}
              >
                <Input size="large" placeholder="Color"></Input>
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item
                name="remark"
                label="Remark"
              >
                <Input size="large" placeholder="Remark"></Input>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };

  const columns = [
    {
      title: "Lpno (ทะเบียน)",
      dataIndex: ["lpNo"],
      key: "lpNo",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
    },
    {
      title: "Province",
      dataIndex: ["provinceID"],
      key: "provinceID",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
      render: (provinceID) => {
        const province = provinces.filter(province => {
          return province.id === provinceID
        })
        return <>
          <p>
            {province ? province[0]?.name : ""}
          </p>
        </>
      },

    },
    {
      title: "Model",
      dataIndex: ["modelName"],
      key: "modelName",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
    },
    {
      title: "Brand",
      dataIndex: ["brand"],
      key: "brand",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
    },
    {
      title: "Color",
      dataIndex: ["color"],
      key: "color",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
    },
    {
      // title: "Action",
      key: "id",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
      render: (id) => (
        checkPermission("2") && (
          <Space size="middle">
            <Tooltip title="Delete" color={"#f44336"}>
              <Button
                icon={<Icon icon={faTrash} />}
                type="text"
                onClick={() => {
                  deleteBlackList(id);
                }}
              />
            </Tooltip>
          </Space>
        )
      ),
    },
  ];

  return (
    <div className="theme-2-content">
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card margin="20px 0">
            <Row justify="space-between">
              <Col span={4}>
                <p className="theme-2-text-1">{"All Black List \xa0"}</p>
                <p className="theme-2-text-2">
                  {blackList.length ? blackList.length : 0}
                  {"\xa0Total Black List"}
                </p>
              </Col>
              <Col span={4} >
                <Col span={24} style={{ width: '100%', padding: '10px' }}>
                  <Button
                    type="primary"
                    className="theme-2-button-1"
                    icon={
                      <Icon margin="0 10px 0 0" color="white" icon={faPlus} />
                    }
                    onClick={() => {
                      setIsBlackList(true)
                      getProvince()
                    }
                    }
                  >
                    Add
                  </Button>
                </Col>
              </Col>
            </Row>
            <Row justify="center">
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
              <Col xxl={20} xl={20} lg={20} md={20} sm={24} xs={24}>
                <CardTable
                  rowKey={(obj) => obj.id}
                  columns={columns}
                  dataSource={blackList}
                  bordered={false}
                  pagination={{
                    position: ['bottomCenter'],
                    total: blackList.length,
                  }}
                  size="middle"
                />
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <BlackListModal />
    </div>
  );
}
