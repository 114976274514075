import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { api } from '../services';
import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Theme from '../theme/theme_2';
import { DataNotFound } from '../components';
import { ReceptionForm } from '../components/ReceptionForm';
import Cookies from 'universal-cookie';

const swal = withReactContent(Swal);
const cookies = new Cookies();

export default function editReception() {
  const { receptionID } = useParams();
  const history = useHistory();
  const [initialValue, setInitialValue] = useState();
  const [buildings, setBuildings] = useState();
  const getReceptionAdmin = () => {
    
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/users/${receptionID}`,  
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
            setInitialValue(get(res, 'data.data'));
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };

  const getBuilding = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/buildings`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
          setBuildings(get(res, "data.data"));
        }
      });
  };

  useEffect(() => {
    getReceptionAdmin();
    getBuilding();
  }, []);

  const editReceptionSuccess = (values) => {
    swal.fire({
      icon: 'success',
      title: 'Reception edited',
      text: `Reception "${values.firstname + " " + values.lastname}" is successfully edited`,
      showConfirmButton: true,
      confirmButtonColor: '#2F79FF',
      confirmButtonText: 'Close',
    });
    history.push('/management/reception');
  };

  const submitForm = (fieldsValue) => {
    const o = {
      method: "get",
      url: `${process.env.REACT_APP_API}/consents/latest`,
    }
    api(o)
      .then((res) => {
        if(res.data.code === 200){
          const values = {
            building_id: get(fieldsValue, "building"),
           // personal_id: get(fieldsValue, "citizenID"),
            email: get(fieldsValue, "email"),
            firstname: get(fieldsValue, "firstname"),
            lastname: get(fieldsValue, "lastname"),
            phone: get(fieldsValue, "phone"),
            password: get(fieldsValue, "password"),
            user_type:3,
            consent_id: res.data.data.id,
          };
          const option = {
            method: "PUT",
            url: `${process.env.REACT_APP_API}/users/${receptionID}`,
            headers: {
              'Authorization': 'Bearer ' + cookies.get('accessToken'),
              'Content-Type': 'application/json'
            },
            data: values,
          };
          api(option)
          .then((res) => {
            global.store.dispatch({
              type: 'set_loading_show',
              data: { show: false },
            });
            if (res.data.code === 200) {
              editReceptionSuccess(values);
            }
          })
          .catch((err) => {
            global.store.dispatch({
              type: "set_loading_show",
              data: { show: false },
            });
            swal.fire({
              icon: "error",
              title: 'Updated failed',
              text: get(err.response.data, 'message'),
              showConfirmButton: true,
              confirmButtonColor: "#2F79FF",
              confirmButtonText: "Close",
            });
          });
      }
    })
  };

  return (
    <div>
      <Theme PageName={'Edit Reception'}>
         {initialValue && ( 
          <ReceptionForm
            initialValue={initialValue}
            type="update"
             submitForm={submitForm}
          />
         )}
         {!initialValue && <DataNotFound />}
      </Theme>
    </div>
  );
}