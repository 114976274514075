import React from 'react';
import Theme from '../theme/theme_2';
import WebStamp from '../components/WebStamp';
function webStamp() {
  return (
    <Theme PageName={'Web Stamp Log'}>
      <WebStamp/>
    </Theme>
  );
}

export default webStamp;
