import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { api } from '../services';
import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Theme from '../theme/theme_2';
import { BuildingForm, DataNotFound } from '../components';
import Cookies from 'universal-cookie';

const swal = withReactContent(Swal);
const cookies = new Cookies();

export default function EditBuilding() {
  const { buildingID } = useParams();
  const [error, setError] = useState(false);
  const history = useHistory();
  const [initialValue, setInitialValue] = useState();
  const [initialJuristic, setInitialJuristic] = useState();
  const getInitialValue = () => {
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/buildings/${buildingID}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          getJuristicAdmin(get(res, 'data.data'));
          setError(false);
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };
  const getJuristicAdmin = (data) => {
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/users/juristic_admin?building_id=${buildingID}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          setInitialJuristic(get(res, 'data.data'))
          const values = get(res , 'data.data');
          let juristic = [];
          if(values) {
            juristic = values.map((item) => {
              return item.id;
            })
          }
          setInitialValue({
            buildingName: get(data, 'name'),
            telephone: get(data, 'phone'),
            juristicAdmin: juristic,
          });
          setError(false);
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };

  useEffect(() => {
    getInitialValue();
  }, []);

  const editBuildingSuccess = (values) => {
    swal.fire({
      icon: 'success',
      message: 'Building edited',
      text: `Building "${values.name}" is successfully edited`,
      showConfirmButton: true,
      confirmButtonColor: '#2F79FF',
      confirmButtonText: 'Close',
    });
    history.goBack();
  };

  const submitForm = (fieldsValue) => {
    const values = {
      name: fieldsValue['buildingName'],
      phone: fieldsValue['telephone'],
      juristic_admins: fieldsValue['juristicAdmin'],
    };
    const option = {
      method: 'PUT',
      url: `${process.env.REACT_APP_API}/buildings/${buildingID}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
      data: values,
    };
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          editBuildingSuccess(values, res.data);
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: "error",
          title: 'Updated failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: "#2F79FF",
          confirmButtonText: "Close",
        });
      });
  };
  return (
    <div>
      <Theme PageName={'Edit Building'}>
        {initialValue && (
          <BuildingForm
            initialValue={initialValue}
            initialJuristic={initialJuristic}
            type="edit"
            submitForm={submitForm}
          />
        )}
        {error && !initialValue && <DataNotFound />}
      </Theme>
    </div>
  );
}
