import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import notFoundImg from "../assets/images/404-Error-NotFound.png";

const Title = styled.h1`
  font-size: 24px;
  // font-family: 'PoppinsBold';
  font-weight: bolder;
  margin: 0;
  text-align: center;
`;

const pageNotFound = () => {
  return (
    <Row justify="center">
      <Col xs={24} sm={12} md={12} lg={8}>
        <img style={{ width: "100%" }} alt="Not Found" src={notFoundImg} />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Title>Page Not Found</Title>
        <div style={{ marginTop: "10px", textAlign: "center" }}>
          Sorry, the page you visited does not exist.
        </div>
      </Col>
    </Row>
  );
};

export default pageNotFound;
