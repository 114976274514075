import React, { useContext } from "react";
import AuthenContext from "../utils/useAuthentication";
import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import { Modal, Form, Input } from "antd";
import withReactContent from "sweetalert2-react-content";

const swal = withReactContent(Swal);

export const ChangePasswordModal = ({ visible, onCancel ,onLogout}) => {
  const { logout,user } = useContext(AuthenContext);
  const [changePasswordForm] = Form.useForm();

  const submitForm = (values) => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const value = {
      old_password: values.oldPassword,
      new_password: values.newPassword,
    };
    const option = {
      method: "put",
      url: `${process.env.REACT_APP_API}/users/${user.data.id}/password`,
      data: value,
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
          swal.fire({
            icon: "success",
            title: "Success",
            text: "Your password is successfully changed.",
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
          changePasswordForm.resetFields();
          onCancel();
          onLogout();        
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: "error",
          title: "Old password is invalid.",
          text: "Please check your old password is correct.",
          showConfirmButton: true,
          confirmButtonColor: "#2F79FF",
          confirmButtonText: "Close",
        });
      });
  };

  return (
    <Modal
      visible={visible}
      title="Change Password"
      okButtonProps={{ className: "theme-2-button-1" }}
      okText="Save"
      cancelButtonProps={{ className: "theme-2-button-2" }}
      cancelText="Cancel"
      maskClosable={false}
      onCancel={onCancel}
      onOk={() => {
        changePasswordForm
          .validateFields()
          .then((values) => {
            submitForm(values);
          })
      }}
    >
      <Form
        form={changePasswordForm}
        layout="vertical"
        name="changePasswordForm"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          label="Old Password"
          name="oldPassword"
          rules={[
            {
              required: true,
              message: "Please input old password!",
            },
            {
              min: 8,
              message: "minimum length = 8",
            },
            {
              max: 32,
              message: "maximum length = 32",
            },
          ]}
        >
          <Input.Password style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Please input new password!",
            },
            {
              min: 8,
              message: "minimum length = 8",
            },
            {
              max: 32,
              message: "maximum length = 32",
            },
          ]}
        >
          <Input.Password style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          name="cfNewPassword"
          rules={[
            {
              required: true,
              message: "Please input confirm new password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input.Password style={{ width: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
