import React from "react";
import { useSelector } from "react-redux";
import { Row, Card as BaseCard, Image } from "antd";
import styled from "styled-components";
import "./../assets/styles/loading.less";
import logo from "./../assets/images/loading-icon.gif";

const Card = styled(BaseCard)`
  border: none;
  background-color: rgba(255, 255, 255, 0);
`;

export default function Loading() {
  const loading = useSelector((state) => state.Loading);

  return (
    loading.show && (
      <Row className="loading_container" justify="space-around" align="middle">
        <Card>
          <Image className="loading_image" src={logo} />
        </Card>
      </Row>
    )
  );
}
