import React from "react";
import { Form, Input, Button, Space, Row, Col } from "antd";
import { FormItem } from "../components";

export const ResetPasswordForm = ({ submitForm, cancelForm }) => {

  return (
    <Row>
      <Col xxl={3} xl={3} lg={3} md={1} sm={1} xs={1}></Col>
      <Col xxl={18} xl={18} lg={18} md={22} sm={22} xs={22}>
        <div className="resetPassword-form">
          <Form
            name="resetPassword"
            onFinish={submitForm}
          >
            <Row>
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <p className="theme-1-text-1">Reset Password</p>
              </Col>
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <p className="theme-1-text-A">Reset Password</p>
              </Col>
            </Row>
            <Row>
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <p className="theme-1-text-3">Password</p>
              </Col>
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <p className="theme-1-text-B">Password</p>
              </Col>
            </Row>
            <FormItem
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input password!",
                },
                {
                  min: 8,
                  message: "minimum length = 8",
                },
                {
                  max: 32,
                  message: "maximum length = 32",
                },
              ]}
            >
              <Input.Password style={{ width: "100%" }} />
            </FormItem>
            <Row>
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <p className="theme-1-text-3">Confirm Password</p>
              </Col>
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <p className="theme-1-text-B">Confirm Password</p>
              </Col>
            </Row>
            <FormItem
              name="cfPassword"
              rules={[
                {
                  required: true,
                  message: "Please input password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password style={{ width: "100%" }} />
            </FormItem>
            <Row>
              <FormItem>
                <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                  <Space size="middle">
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="theme-1-button-1"
                    >
                      Submit
                    </Button>
                    <Button
                      htmlType="button"
                      type="default"
                      className="theme-1-button-2"
                      onClick={cancelForm}
                    >
                      Cancel
                    </Button>
                  </Space>
                </Col>
                <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                  <Space size="middle">
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="theme-1-button-A"
                    >
                      Submit
                    </Button>
                    <Button
                      htmlType="button"
                      type="default"
                      className="theme-1-button-B"
                      onClick={cancelForm}
                    >
                      Cancel
                    </Button>
                  </Space>
                </Col>
              </FormItem>
            </Row>
            <Row>
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <p className="theme-1-text-4">
                  Enter your password to reset your password
                </p>
              </Col>
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <p className="theme-1-text-C">
                  Enter your password to reset your password
                </p>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
      <Col xxl={3} xl={3} lg={3} md={1} sm={1} xs={1}></Col>
    </Row>
  );
};
