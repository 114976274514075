import React from "react";
import Theme from "../theme/theme_2";
import WebStempManagement from "../components/WebStemForm";
export default function webStempManagement() {
  return (
    <div>
      <Theme PageName={"Web-Stamp Management"}>
        <WebStempManagement />
      </Theme>
    </div>
  );
}
