import React from "react";
import Theme from "../theme/theme_2";
import { PromotionForm } from "../components";

const viewPromotion = () => {
  return (
    <div>
      <Theme PageName={"View Promotion"}>
        <PromotionForm formType="view" />
      </Theme>
    </div>
  );
};

export default viewPromotion;
