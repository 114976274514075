import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  Row,
  Col,
  Button,
  Input,
  Space,
  Tooltip,
  Select,
  Modal,
  Form,
} from 'antd';
import {
  faUserCog,
  faTrash,
  faUserPlus,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Avatar from 'antd/lib/avatar/avatar';

import { Card, CardTable, Icon } from '../components';
import theme from '../theme';
import { api } from '../services';
import { UserAvatar } from './UserAvatar';
import { ComparePassword } from './ComparePassword';
import Cookies from 'universal-cookie';
import { InputAvatar } from './InputAvatar';
import { checkPermission } from '../utils/checkPermission';
import AuthenContext from '../utils/useAuthentication';
import { getRole } from '../utils/helper';
import { roles } from '../utils/data';

const swal = withReactContent(Swal);
const cookies = new Cookies();

const InputFile = styled.input`
  visibility: hidden;
  position: absolute;
`;

export default function UserManagement() {
  const history = useHistory();
  const { user } = useContext(AuthenContext);
  const [image, setImage] = useState();
  const [dataNew, setDataNew] = useState();
  const [totalData, setTotalData] = useState();
  const [organizes, setOrganize] = useState([]);
  const [searchCol, setSearchCol] = useState('');
  const [searchData, setSearchData] = useState('');
  const [page, setPage] = useState(1);
  const [isAddUser, setIsAddUser] = useState(false);
  const [addUserForm] = Form.useForm();
  const [organizeAdmin, setOrganizeAdmin] = useState([]);
  const [departments, setDepartments] = useState([]);

  const userBuildingId = user?.data?.building_id;

  const getOrganization = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/organizations`,
      headers: {
        Authorization: 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json',
      },
    };
    api(option).then((res) => {
      global.store.dispatch({
        type: 'set_loading_show',
        data: { show: false },
      });
      if (res.data.code === 200) {
        setOrganize(res.data.data);
      }
    });
  };

  const fetchUser = (limit, offset, search) => {
    if (search) setPage(1);
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}`,
      headers: {
        Authorization: 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json',
      },
    };
    api(option)
      .then((res) => {
        if (res.data.code === 200) {
          setSearchCol('');
          setSearchData('');
          setDataNew(sortBy(get(res, 'data.data.list'), ['created_at']));
          setTotalData(get(res, 'data.data'));
          formatData(sortBy(get(res, 'data.data.list'), ['created_at']));
        }

        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };

  const searchFilter = (limit, offset, search, col, data) => {
    if (search) setPage(1);
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    let option = {};
    if (col === 'email')
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}&email=${data}`,
        headers: {
          Authorization: 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json',
        },
      };
    else if (col === 'phone')
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}&phone=${data}`,
        headers: {
          Authorization: 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json',
        },
      };
    else if (col === 'firstname')
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}&fname=${data}`,
        headers: {
          Authorization: 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json',
        },
      };
    else if (col === 'lastname')
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}&lname=${data}`,
        headers: {
          Authorization: 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json',
        },
      };
    else if (col === 'roles')
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}${data}`,
        headers: {
          Authorization: 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json',
        },
      };
    else if (col === 'org_name')
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}&oid=${data}`,
        headers: {
          Authorization: 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json',
        },
      };
    api(option)
      .then((res) => {
        if (res.data.code === 200) {
          setSearchCol(col);
          setSearchData(data);
          setDataNew(
            sortBy(get(res, 'data.data.list'), ['created_at']).reverse()
          );
          setTotalData(get(res, 'data.data'));
          formatData(
            sortBy(get(res, 'data.data.list'), ['created_at']).reverse()
          );
        }

        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };

  const handleChange = (pagination) => {
    setPage(pagination.current);
    const offset =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    if (searchCol === '') fetchUser(limit, offset, false);
    else searchFilter(limit, offset, false, searchCol, searchData);
  };

  const fetchDepartments = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'post',
      url: `${process.env.REACT_APP_API_SAPI}/member/LoadGroups`,
      headers: {
        Authorization:
          'Bearer 011f1d1fadb6446fa381cfbabddee860ad569df4434f4eaf9daabc86e410d844b62979423dee4860be0985484efc61e7',
        'Content-Type': 'application/json',
      },
      data: {
        SiteGUID: process.env.REACT_APP_SITEGUID,
      },
    };
    api(option)
      .then((res) => {
        if (res.status === 200) {
          setDepartments(res.data);
        }

        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      })
      .catch(() => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };

  useEffect(() => {
    fetchDepartments();
    getOrganization();
  }, []);

  useEffect(() => {
    if (departments.length > 0) {
      fetchUser(10, 0, true);
    }
  }, [departments.length]);

  const formatData = (data) => {
    const dataFormat = data.map((user) => ({
      id: user.id,
      firstname: user.firstname,
      lastname: user.lastname,
      user_type: getRole(user.user_type),
      email: user.email ? user.email : '-',
      phone: user.phone.slice(0, 6) + 'XXXX',
      roles: getRole(user.user_type),
      faceImg: user.portrait_photo,
      org_name: user.org_name,
      user_permit_guid: '',
      tag_title: user.tag_title,
      tag_code: user.tag_code,
      department: departments.find((dep) => dep.groupID === user.group_id)
        .groupName,
    }));
    setDataNew(dataFormat);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              handleReset(clearFilters, setSelectedKeys, dataIndex)
            }
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchCol === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchData]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, dataIndex) => {
    let dataIn = '';
    if (dataIndex === 'roles') {
      for (let i = 0; i < selectedKeys.length; i++)
        dataIn += '&types=' + selectedKeys[i];
    }
    if (dataIndex !== 'roles')
      searchFilter(10, 0, true, dataIndex, selectedKeys[0]);
    else if (dataIndex === 'org_name')
      searchFilter(10, 0, true, dataIndex, selectedKeys);
    else searchFilter(10, 0, true, dataIndex, dataIn);
  };

  const handleReset = (clearFilters, setSelectedKeys, dataIndex) => {
    if (dataIndex === 'roles' || dataIndex === 'org_name')
      setSelectedKeys(null);
    fetchUser(10, 0, true);
    clearFilters();
  };

  const getColumnSearchByOption = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {dataIndex === 'roles' && (
          <Select
            mode="multiple"
            placeholder={`Search ${dataIndex}`}
            onChange={(e) => setSelectedKeys(e)}
            value={selectedKeys}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          >
            {roles.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.user_type}
                </Select.Option>
              );
            })}
          </Select>
        )}
        {dataIndex === 'org_name' && (
          <Select
            placeholder={`Search ${dataIndex}`}
            onChange={(e) => setSelectedKeys(e ? [e] : [])}
            value={selectedKeys}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          >
            {organizes.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              handleReset(clearFilters, setSelectedKeys, dataIndex)
            }
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  });

  const handleUploadClick = (event) => {
    let bodyFormData = new FormData();
    bodyFormData.append('file', event.target.files[0]);
    bodyFormData.append('file_type', 'profile');
    const option = {
      method: 'post',
      url: `${process.env.REACT_APP_API}/upload`,
      headers: {
        Authorization: `Bearer ${cookies.get('accessToken')}`,
        'Content-Type': 'multipart/form-data',
      },
      data: bodyFormData,
    };

    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.message === 'success') {
          const url = get(res, 'data.data');
          setImage(url);
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        swal.fire({
          icon: 'error',
          title: 'Created failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: '#2F79FF',
          confirmButtonText: 'Close',
        });
      });
  };
  const getOrgAdmin = (organizeID) => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const o = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/users/org_admin?org_id=${organizeID}`,
      headers: {
        Authorization: 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json',
      },
    };
    api(o).then((res) => {
      global.store.dispatch({
        type: 'set_loading_show',
        data: { show: false },
      });
      if (res.data.code === 200) {
        setOrganizeAdmin(get(res, 'data.data'));
      } else {
        swal.fire({
          icon: 'error',
          title: get(res, 'data.code'),
          text: get(res, 'data.message'),
          showConfirmButton: true,
          confirmButtonColor: '#2F79FF',
          confirmButtonText: 'Close',
        });
      }
    });
  };
  const onSubmitAddNewUser = (values) => {
    const o = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/consents/latest`,
    };
    api(o).then((res) => {
      if (res.data.code === 200) {
        const option = {
          method: 'POST',
          url: `${process.env.REACT_APP_API}/users/register`,
          headers: {
            Authorization: 'Bearer ' + cookies.get('accessToken'),
            'Content-Type': 'application/json',
          },
          data: {
            token:
              'uat3i2cs758v9mg2lrscr8rqwi8503to1v1dix3k2y7qt6p0hcmfy8s5ve5bc6pq',
            portrait_photo: image ? image : 'https://true2-dev.thssoft.com',
            firstname: values.first_name,
            lastname: values.last_name,
            email: values.email,
            password: '!ths123456',
            personal_id: values.citizen,
            phone: values.telephone,
            tag_code: values.card_number,
            tag_title: values.true_card_code,
            group_id: values.department,
            remark: values.remark,
            user_type: 5,
            consent_id: res.data.data.id,
            building_id: userBuildingId ? userBuildingId : 1,
            parent_id: values.organizeAdmin,
            org_id: values.organizes,
          },
        };
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: true },
        });

        api(option)
          .then((res) => {
            global.store.dispatch({
              type: 'set_loading_show',
              data: { show: false },
            });
            if (res.data) {
              setIsAddUser(false);
              const option = {
                method: 'POST',
                url: `${process.env.REACT_APP_API}/approvals/${res.data.data.approval_id}/approve`,
              };
              api(option).then((res) => {
                global.store.dispatch({
                  type: 'set_loading_show',
                  data: { show: false },
                });
                if (res.data.code === 200) {
                  swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: `User "${get(
                      values,
                      'first_name'
                    )}" is successfully created`,
                    showConfirmButton: true,
                    confirmButtonColor: '#2F79FF',
                    confirmButtonText: 'Close',
                  });
                }
              });
            }
          })
          .catch((err) => {
            global.store.dispatch({
              type: 'set_loading_show',
              data: { show: false },
            });
            swal.fire({
              icon: 'error',
              title: 'Created failed',
              text: get(err.response.data, 'message'),
              showConfirmButton: true,
              confirmButtonColor: '#2F79FF',
              confirmButtonText: 'Close',
            });
          });
      }
    });
  };
  const AddUserModal = () => {
    const handleCancel = () => {
      addUserForm.resetFields();
      setIsAddUser(false);
    };
    const handleSave = () => {
      setIsAddUser(false);
      addUserForm.validateFields().then((values) => {
        onSubmitAddNewUser(values);
      });
    };
    return (
      <Modal
        visible={isAddUser}
        width="50%"
        height="60%"
        title="Add User"
        onOk={handleSave}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            key="back"
            className="theme-2-button-2"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="theme-button-save"
            type="primary"
            onClick={handleSave}
          >
            ยืนยัน
          </Button>,
        ]}
      >
        <Form
          name="addUserForm"
          form={addUserForm}
          labelAlign="center"
          layout="vertical"
        >
          <Row>
            <Col span={24} style={{ margin: 10 }}>
              <InputFile
                accept="image/*"
                id="avatar"
                type="file"
                onChange={handleUploadClick}
              />
              <div
                style={{
                  position: 'relative',
                  width: 'fit-content',
                  height: 'fit-content',
                }}
              >
                <label
                  htmlFor="avatar"
                  style={{
                    position: 'absolute',
                    zIndex: 1,
                    top: '-15px',
                    right: '-15px',
                  }}
                >
                  <Avatar
                    style={{
                      background: theme.white,
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                    icon={<Icon icon={faPen} />}
                  />
                </label>
                <InputAvatar imageUrl={image ? image : ''} />
              </div>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col span={11} style={{ textAlign: 'left' }}>
              <Form.Item
                name="organizes"
                label="Organize"
                rules={[
                  {
                    required: true,
                    message: 'Please input organize!',
                  },
                ]}
              >
                <Select
                  onChange={(e) => getOrgAdmin(e)}
                  size="large"
                  placeholder="Select organize"
                  style={{ width: '100%' }}
                >
                  {organizes.map((item) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={11} offset={1} style={{ textAlign: 'left' }}>
              <Form.Item
                name="organizeAdmin"
                label="Organize Admin"
                rules={[
                  {
                    required: true,
                    message: 'Please input organize admin!',
                  },
                ]}
              >
                <Select
                  name="building"
                  size="large"
                  placeholder="Select organize admin"
                  style={{ width: '100%' }}
                >
                  {organizeAdmin.map((item) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.firstname} {item.lastname}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col span={11} style={{ textAlign: 'center' }}>
              <Form.Item
                name="first_name"
                label="First Name"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'กรุณาใส่ชื่อ',
                  },
                ]}
              >
                <Input size="large" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={11} offset={1} style={{ textAlign: 'center' }}>
              <Form.Item
                name="last_name"
                label="Last Name"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'กรุณาใส่นามสกุล',
                  },
                ]}
              >
                <Input size="large" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col span={11} style={{ textAlign: 'center' }}>
              <Form.Item
                name="email"
                label="Email Address"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'กรุณาใส่อีเมล',
                  },
                  {
                    type: 'email',
                    message: 'Your email is incorrect.',
                  },
                  {
                    min: 8,
                    message: 'minimum length = 8',
                  },
                  {
                    max: 165,
                    message: 'maximum length = 165',
                  },
                ]}
              >
                <Input size="large" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={11} offset={1} style={{ textAlign: 'left' }}>
              <Form.Item
                name="department"
                label="Department"
                rules={[
                  {
                    required: true,
                    message: 'Please select department',
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select department"
                  style={{ width: '100%' }}
                >
                  {departments.map((item) => (
                    <Select.Option key={item.groupID} value={item.groupID}>
                      {item.groupName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col span={11} style={{ textAlign: 'center' }}>
              <Form.Item
                name="citizen"
                label="Citizen ID"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'กรุณาใส่เลขบัตรประชาชน',
                  },
                  {
                    min: 13,
                    message: 'minimum length = 13',
                    whitespace: false,
                  },
                  {
                    max: 13,
                    message: 'maximum length = 13',
                    whitespace: false,
                  },
                ]}
              >
                <Input type="number" size="large" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={11} offset={1} style={{ textAlign: 'center' }}>
              <Form.Item
                name="telephone"
                label="Telephone"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'กรุณาใส่เบอร์โทรศัพท์',
                  },
                  {
                    pattern: '^[+]?[0-9]{9,12}$',
                    message: 'Your telephone number is incorrect format.',
                  },
                ]}
              >
                <Input type="number" size="large" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col span={11} style={{ textAlign: 'center' }}>
              <Form.Item
                name="true_card_code"
                label="True UID"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'กรุณาใส่รหัสบัตรทรู',
                  },
                ]}
              >
                <Input size="large" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={11} offset={1} style={{ textAlign: 'center' }}>
              <Form.Item
                name="card_number"
                label="True card"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'กรุณาใส่เลขบัตร',
                  },
                ]}
              >
                <Input size="large" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col span={23} style={{ textAlign: 'center' }}>
              <Form.Item name="remark" label="Remark" style={{ width: '100%' }}>
                <Input.TextArea size="large" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };
  const columns = [
    {
      title: 'Firstname',
      dataIndex: 'firstname',
      key: 'firstname',
      align: 'start',
      className: 'theme-2-table-text',
      width: '10%',
      ...getColumnSearchProps('firstname'),
      render: (id, dataNew) => {
        return (
          <Space>
            <UserAvatar
              imgWidth="30px"
              fontSize="30px"
              imageUrl={dataNew.faceImg}
            />
            {get(dataNew, 'firstname')}
          </Space>
        );
      },
    },
    {
      title: 'Lastname',
      dataIndex: 'lastname',
      key: 'email',
      align: 'start',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      width: '10%',
      ...getColumnSearchProps('lastname'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      width: '10%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
      key: 'user_type',
      align: 'center',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      width: '10%',
      ...getColumnSearchByOption('roles'),
    },
    {
      title: 'Organization Name',
      dataIndex: 'org_name',
      key: 'org_name',
      align: 'center',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      width: '10%',
      ...getColumnSearchByOption('org_name'),
    },
    {
      title: 'Telephone',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      width: '10%',
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'Mifare Card',
      dataIndex: 'tag_title',
      key: 'tag_title',
      align: 'center',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      width: '10%',
    },
    {
      title: 'Employee ID',
      dataIndex: 'tag_code',
      key: 'tag_code',
      align: 'center',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      width: '10%',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      align: 'center',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      width: '10%',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      className: 'theme-2-table-text',
      width: '10%',
      render: (dataNew) => (
        <Space size="middle">
          <Tooltip title="Manage Access" color={theme.primaryColor}>
            <Button
              icon={<Icon icon={faUserCog} />}
              type="text"
              onClick={() => history.push(`/management/users/${dataNew.id}`)}
            />
          </Tooltip>

          <Tooltip title="Delete User" color={theme.primaryColor}>
            <Button
              icon={<Icon icon={faTrash} />}
              type="text"
              onClick={() => {
                ComparePassword('user', dataNew, fetchUser, '');
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div className="theme-2-content">
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card margin="20px 0">
            <Row justify="space-between">
              <Col span={12}>
                <div style={{ marginTop: '10px' }}>
                  <p className="theme-2-text-1">{'All User \xa0'}</p>
                  <p className="theme-2-text-2">
                    {get(totalData, 'total') ? get(totalData, 'total') : 0}
                    {'\xa0Total user'}
                  </p>
                </div>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {checkPermission('2') && (
                  <div style={{ marginTop: '10px' }}>
                    <Button
                      type="primary"
                      onClick={() => setIsAddUser(true)}
                      icon={
                        <Icon
                          icon={faUserPlus}
                          style={{ color: '#fff', marginRight: 10 }}
                        />
                      }
                      size="large"
                    >
                      Add User
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <CardTable
                  rowKey={(obj) => obj.id}
                  columns={columns}
                  dataSource={dataNew}
                  bordered={false}
                  onChange={handleChange}
                  scroll={{ x: 1900 }}
                  pagination={{
                    current: page,
                    position: ['bottomCenter'],
                    total: get(totalData, 'total'),
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <AddUserModal />
    </div>
  );
}
