import React from "react";
import Theme from "../theme/theme_2";
import Building from "../components/Building";

export default function building() {
  return (
    <div>
      <Theme PageName={"View Building"}>
        <Building />
      </Theme>
    </div>
  );
}
