import React from "react";
import Theme from "../theme/theme_2";
import PromotionManagement from "../components/PromotionManagement";

export default function promotionManagement() {
  return (
    <div>
      <Theme PageName={"Promotion Management"}>
        <PromotionManagement />
      </Theme>
    </div>
  );
}
