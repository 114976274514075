import React from "react";
import Theme from "../theme/theme_2";
import Coupon from "../components/Coupon";

export default function coupon() {
  return (
    <div>
      <Theme PageName={"Coupon"}>
        <Coupon />
      </Theme>
    </div>
  );
}
