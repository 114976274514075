import React from 'react';
import Theme from '../theme/theme_2';
import ViewLog from '../components/ViewLogs';
function Log() {
  return (
    <Theme PageName={'Building Log'}>
      <ViewLog />
    </Theme>
  );
}

export default Log;
