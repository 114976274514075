import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import { ProvideAuth } from "./utils/useAuthentication";

ReactDOM.render(
  <ProvideAuth>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </ProvideAuth>,
  document.getElementById("root")
);

reportWebVitals();
