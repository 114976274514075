import React, { useState, useEffect , useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { api } from '../services';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import styled from 'styled-components';
import { Icon, InputThumbnail } from '../components';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Card as BaseCard,
  Space,
  Radio,
  Dropdown,
  Menu,
  DatePicker,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave,
  faPlus,
  faArrowLeft,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
  addNewLang,
  setCurrentLang,
  setNewPromoContent,
  setPromoContentAtCurrLang,
  setLangList,
  clearPromoContent,
  setExpiredDate,
} from '../action';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment from 'moment';
import editorText from '../constant/editorText';
import editorToolbar from '../constant/editorToolbar';
import Cookies from 'universal-cookie';
import AuthenContext from '../utils/useAuthentication';

const cookies = new Cookies();

const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgb(0 89 83);
`;

const EditorWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.borderColor};
`;

const ErrorSection = styled.div`
  color: ${({ theme }) => theme.red};
`;

const swal = withReactContent(Swal);

export const PromotionForm = ({ formType }) => {
  const [dateNow, setDateNow] = useState();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useContext(AuthenContext);
  const { lang, promotion } = useSelector(
    (state) => ({
      lang: state.Language,
      promotion: state.Promotion,
    }),
    shallowEqual
  );
  useEffect(() => {
    setCurrentLang(dispatch, lang.defaultLang);
    setDateNow(Date.now());
    if (formType === "create" && get(lang, 'defaultLang.id')) {
      setLangList(dispatch, [get(lang, 'defaultLang')]);
      setCurrentLang(dispatch, get(lang, 'defaultLang.id'));
      const value = {
        title: '',
        thumbnail: '',
        content: EditorState.createEmpty(),
        content_language_id: get(lang, 'defaultLang.id'),
      };
      setNewPromoContent(dispatch, value);
      setExpiredDate(dispatch, moment().add(1, 'days'));
    }
    if (formType !== 'create' && get(lang, 'defaultLang.id')) {
      global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
      setCurrentLang(dispatch, 'th');
      const option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/advertisements/${id}`,
      };
      api(option)
        .then((res) => {
          if (res.data.code === 200) {
            if(res.data.data.title_en)
            {
              const dataTemp = [{
                id: 'th',
                name: 'ไทย',
                is_default_language: 1
              },
              {
                id: 'en',
                name: 'english',
                is_default_language: 0
              }]
              setLangList(dispatch, dataTemp);
            }
            else
            {
              const dataTemp = [{
                id: 'th',
                name: 'ไทย',
                is_default_language: 0
              }]
              setLangList(dispatch, dataTemp);
            }
            const o = {
              method: 'get',
              url: `${process.env.REACT_APP_API}/advertisements/${id}`,
            };
            api(o)
              .then((res) => {
                global.store.dispatch({
                  type: 'set_loading_show',
                  data: { show: false },
                });
                if (res.data.code === 200) {
                  if(res.data.data.title_en)
                  {
                    const formatRes = [{
                      title: res.data.data.title_th,
                      thumbnail: res.data.data.thumbnail_th,
                      content: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          htmlToDraft(res.data.data.content_th)
                        )
                      ),
                      content_language_id:"th",
                      id: res.data.data.id
                    },{
                      title: res.data.data.title_en,
                      thumbnail: res.data.data.thumbnail_en,
                      content: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          htmlToDraft(res.data.data.content_en)
                        )
                      ),
                      content_language_id:"en",
                      id: res.data.data.id
                    }];
                    promotion.content = formatRes;
                    setNewPromoContent(dispatch, formatRes);
                  }
                  else
                  {
                    const formatRes = [{
                      title: res.data.data.title_th,
                      thumbnail: res.data.data.thumbnail_th,
                      content: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          htmlToDraft(res.data.data.content_th)
                        )
                      ),
                      content_language_id:"th",
                      id: res.data.data.id
                    }];
                    promotion.content = formatRes;
                    setNewPromoContent(dispatch, formatRes);
                  }
                  promotion.expiredDate = moment(res.data.data.end_datetime*1000);
                  setExpiredDate(dispatch, moment(new Date(res.data.data.end_datetime*1000)));
                }
              })
              .catch((err) => {
                global.store.dispatch({
                  type: "set_loading_show",
                  data: { show: false },
                });
              });
          }
        })
    }
    return () => {
      if (promotion.content.length > 0) {
        clearPromoContent(dispatch);
        promotion.content.length = 0;
      }
    };
  }, [lang.defaultLang]);

  const [errorTitle, setErrorTitle] = useState('');
  const [errorThumbnail, setErrorThumbnail] = useState('');

  const onEditorStateChange = (editorState) => {
    setPromoContentAtCurrLang(dispatch, {
      key: 'content',
      value: editorState,
      lang: lang.currentLang,
    });
  };

  const submitPromotionForm = () => {
    if (formType === 'create') {
      global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
      const value = promotion.content.map((x) => {
        return {
          ...x,
          content: draftToHtml(convertToRaw(x.content.getCurrentContent())),
        };
      });
      var promotionData = {
        advertisement_type: "promotions",
        author: get(user, 'data.id'),
        title_th: value[0].title,
        thumbnail_th: value[0].thumbnail,
        content_th: value[0].content,
        title_en: "",
        thumbnail_en: "",
        content_en: "",
        start_datetime: parseInt(Date.now()/1000),
        end_datetime: parseInt(promotion.expiredDate/1000),
      }
      if(value.length == 2)
      {
        promotionData.title_en = value[1].title ;
        promotionData.thumbnail_en = value[1].thumbnail;
        promotionData.content_en = value[1].content;
      }
      const option = {
        method: "post",
        url: `${process.env.REACT_APP_API}/advertisements`,
        data: promotionData,
      };
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: 'set_loading_show',
            data: { show: false },
          });
          if (res.data.code === 201) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: `Promotion is successfully added`,
              showConfirmButton: true,
              confirmButtonColor: '#2F79FF',
              confirmButtonText: 'Close',
            });
            history.push('/management/promotion');
            clearPromoContent(dispatch);
          }
        })
        .catch((err) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          swal.fire({
            icon: "error",
            title: 'Created failed',
            text: get(err.response.data, 'message'),
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
        });
    } else {
      global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
      const value_th = promotion.content.find(
        (n) => n.content_language_id === "th"
      );
      const value_en = promotion.content.find(
        (n) => n.content_language_id === "en"
      );
      var editedData = {
        advertisement_type: "promotions",
        author: get(user, 'data.id'),
        title_th: value_th.title,
        thumbnail_th: value_th.thumbnail,
        content_th: draftToHtml(convertToRaw(value_th.content.getCurrentContent())),
        title_en: "",
        thumbnail_en: "",
        content_en: "",
        start_datetime: parseInt(Date.now()/1000),
        end_datetime: parseInt(promotion.expiredDate/1000),
      };
      if(value_en != null)
      {
        editedData.title_en = value_en.title;
        editedData.thumbnail_en = value_en.thumbnail;
        editedData.content_en = draftToHtml(convertToRaw(value_en.content.getCurrentContent()));
      }
      const option = {
        method: 'put',
        url: `${process.env.REACT_APP_API}/advertisements/${value_th.id}`,
        data: editedData,
      };
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: 'set_loading_show',
            data: { show: false },
          });
          if (res.data.code === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: `Promotion "${value_th.title}" is successfully edited`,
              showConfirmButton: true,
              confirmButtonColor: '#2F79FF',
              confirmButtonText: 'Close',
            });
          }
        })
        .catch((err) => {
          global.store.dispatch({
            type: 'set_loading_show',
            data: { show: false },
          });
          swal.fire({
            icon: 'error',
            title: 'Updated failed',
            text: get(err.response.data, 'message'),
            showConfirmButton: true,
            confirmButtonColor: '#2F79FF',
            confirmButtonText: 'Close',
          });
        });
    }
  };

  const validateTitle = (type, value, language) => {
    const { title, thumbnail } = value;
    if (title && title.length > 0 && thumbnail && thumbnail.length > 0) {
      setErrorTitle('');
      setErrorThumbnail('');

      switch (type) {
        case 'changeLang': {
            setCurrentLang(dispatch, language);
          break;
        }
        case 'addNewLang': {
          const baseValue = {
            title: '',
            thumbnail: '',
            content: EditorState.createEmpty(),
            content_language_id: language.id,
          };
          const editTypeValue = {
            ...baseValue,
            promotions_content_id: 0,
            promotions_id: '',
          };

          addNewLang(dispatch, language);
          setNewPromoContent(
            dispatch,
            formType === 'create' ? baseValue : editTypeValue
          );

          setCurrentLang(dispatch, language.id);
          break;
        }
        case 'submitForm': {
          submitPromotionForm();
          break;
        }
        default: {
          break;
        }
      }
    } else {
      if (title && title.length > 1) {
        setErrorTitle('');
      } else {
        setErrorTitle('Please input title!');
      }

      if (thumbnail && thumbnail.length > 0) {
        setErrorThumbnail('');
      } else {
        setErrorThumbnail('Please input thumbnail!');
      }
    }
  };

  const handleValidateTitle = (type, value, newLang) => {
    switch (type) {
      case 'changeLang': {
        const newsTitle = promotion.content.find(
          (x) => x.content_language_id === lang.currentLang
        );
        validateTitle(type, newsTitle, newLang);
        break;
      }
      case 'submitForm': {
        const newsTitle = promotion.content.find(
          (x) => x.content_language_id === lang.currentLang
        );
        validateTitle(type, newsTitle);
        break;
      }
      case 'addNewLang': {
        const newsTitle = promotion.content.find(
          (x) => x.content_language_id === lang.currentLang
        );
        validateTitle(type, newsTitle, newLang);
        break;
      }
      default: {
        validateTitle('', value);
        break;
      }
    }
  };

  const onChangeLang = (e) => {
    if (formType === 'view') {
      setCurrentLang(dispatch, e.target.value);
    } else {
      handleValidateTitle('changeLang', null, e.target.value);
    }
  };

  const [promotionForm] = Form.useForm();

  const handleAddNewLang = (item) => {
    handleValidateTitle('addNewLang', null, item);
  };

  const menu = (
    <Menu>
      {lang.availableLang.map((item) => {
        return (
          <Menu.Item key={item.id} onClick={() => handleAddNewLang(item)}>
            {item.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const onChangeExpiredDate = (value) => {
    setExpiredDate(dispatch, value);
  };

  const uploadImageInPromotionEditor = (file) => {
    let bodyFormData = new FormData();
    bodyFormData.append('image', file);
    bodyFormData.append(
      'folder_name',
      `promotions/${id ? id : dateNow}/content/`
    );
    const option = {
      method: 'post',
      url: `${process.env.REACT_APP_API}/upload`,
      headers: {
        Authorization: `Bearer ${cookies.get('accessToken')}`,
        'Content-Type': 'multipart/form-data',
      },
      data: bodyFormData,
    };
    return new Promise((resolve, reject) => {
      api(option)
        .then((res) => {
          if (res.data.status === 'success') {
            resolve({ data: { link: get(res, 'data.payload') } });
          }
        })
        .catch((err) => {
          reject();
        });
    });
  };

  const renderFormContent = (content) => {
    if (content) {
      return (
        <>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Please input title!',
                  },
                ]}
              >
                <Input
                  readOnly={formType === 'view'}
                  onChange={(e) => {
                    handleValidateTitle(
                      null,
                      { title: e.target.value, thumbnail: content.thumbnail },
                      null
                    );
                    setPromoContentAtCurrLang(dispatch, {
                      key: 'title',
                      value: e.target.value,
                      lang: lang.currentLang,
                    });
                  }}
                  value={content.title}
                  placeholder="Title"
                  style={{ width: '100%' }}
                />
                <ErrorSection>{errorTitle}</ErrorSection>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Thumbnail">
                <InputThumbnail
                  title="promotion"
                  dispatch={dispatch}
                  lang={lang.currentLang}
                  content={content}
                  onChangeFn={handleValidateTitle}
                  thumbnail={content.thumbnail}
                  type="advertisement"
                  folderName={`promotions/${id ? id : dateNow}/thumbnail/`}
                />
                <ErrorSection>{errorThumbnail}</ErrorSection>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Content">
            <EditorWrapper>
              <Editor
                editorStyle={{ margin: '20px' }}
                readOnly={formType === 'view'}
                editorState={content.content}
                onEditorStateChange={onEditorStateChange}
                localization={{
                  translations: editorText,
                }}
                toolbar={{
                  ...editorToolbar,
                  image: {
                    uploadCallback: uploadImageInPromotionEditor,
                    previewImage: true,
                    alt: { present: true, mandatory: false },
                    inputAccept:
                      'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  },
                }}
              />
            </EditorWrapper>
          </Form.Item>
        </>
      );
    } else return <div>no data</div>;
  };

  const submitButton = () => {
    if (formType === 'view') {
      clearPromoContent(dispatch);
      history.push(`/management/promotion/editpromotion/${id}`);
    } else {
      handleValidateTitle('submitForm');
    }
  };

  return (
    <div className="theme-2-content">
      <Form
        name="promotionForm"
        form={promotionForm}
        labelAlign="left"
        labelCol={{
          xs: { span: 24 },
          sm: { span: 6 },
          md: { span: 4 },
          lg: { span: 4 },
          xl: { span: 3 },
          xxl: { span: 2 },
        }}
        wrapperCol={{ span: 24 }}
      >
        <Card
          title={`${capitalize(formType)} Promotion`}
          extra={
            <Row>
              <Col
                xxl={24}
                xl={24}
                lg={24}
                md={24}
                sm={0}
                xs={0}
                style={{ marginTop: '8%' }}
              >
                <Form.Item>
                  <Space>
                    <Button
                      type="default"
                      onClick={() => {
                        history.goBack();
                        clearPromoContent(dispatch);
                      }}
                      className="theme-2-button-2"
                      icon={
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          style={{ marginRight: '10px' }}
                        />
                      }
                    >
                      Back
                    </Button>
                    <Button
                      type="primary"
                      className="theme-button-save"
                      onClick={submitButton}
                      icon={
                        <FontAwesomeIcon
                          icon={formType === 'view' ? faEdit : faSave}
                          style={{ marginRight: '10px' }}
                        />
                      }
                    >
                      {formType === 'edit'
                        ? 'Save'
                        : formType === 'create'
                          ? 'Create Promotion'
                          : 'Edit'}
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
              <Col
                xxl={0}
                xl={0}
                lg={0}
                md={0}
                sm={24}
                xs={24}
                style={{ marginTop: '30%' }}
              >
                <Form.Item>
                  <Space>
                    <Button
                      type="default"
                      onClick={() => {
                        history.goBack();
                        clearPromoContent(dispatch);
                      }}
                      className="theme-2-button-2"
                      icon={<FontAwesomeIcon icon={faArrowLeft} />}
                    ></Button>
                    <Button
                      type="primary"
                      onClick={submitButton}
                      className="theme-2-button-1"
                      icon={
                        <FontAwesomeIcon
                          icon={formType === 'view' ? faEdit : faSave}
                        />
                      }
                    ></Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          }
        >
          <Row justify="center">
            <Col xxl={22} xl={22} lg={22} md={22} sm={24} xs={24}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Expired Date"
                  >
                    <DatePicker
                      disabled={formType === 'view'}
                      allowClear={false}
                      disabledDate={(d) => !d || d.isBefore(Date.now())}
                      onChange={onChangeExpiredDate}
                      format="DD-MM-YYYY"
                      showNow={false}
                      showToday={false}
                      value={promotion.expiredDate}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="Languageasd">
                    <Radio.Group
                      onChange={onChangeLang}
                      value={lang.currentLang}
                    >
                      {lang.langList.map((lang) => {
                        return (
                          <Radio.Button key={lang.id} value={lang.id}>
                            {lang.name}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                    {formType !== 'view' && lang.availableLang.length > 0 && (
                      <Dropdown
                        overlay={menu}
                        trigger={['click']}
                        placement="bottomCenter"
                        arrow
                      >
                        <Button
                          shape="circle"
                          icon={<Icon icon={faPlus} />}
                          style={{ marginLeft: '10px' }}
                        ></Button>
                      </Dropdown>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {renderFormContent(
                promotion.content.find(
                  (x) => x.content_language_id === lang.currentLang
                )
              )}
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};
