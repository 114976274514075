import React from "react";
import Theme from "../theme/theme_2";
import RegisterNewUser from "../components/RegisterNewUser";

export default function registerNewUser() {
  return (
    <div>
      <Theme PageName={"Register New User"}>
        <RegisterNewUser />
      </Theme>
    </div>
  );
}
