import React, { useState, useEffect } from "react";
import { useHistory,useParams } from "react-router-dom";
import { api } from "../services";
import get from "lodash/get";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styled from "styled-components";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Card as BaseCard,
  Select,
  Space,
  Modal,
} from "antd";
import { Icon } from "../components";
import { SelectIcon } from "../components/icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faCheck,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from 'universal-cookie';

const swal = withReactContent(Swal);

const { Option } = Select;
const cookies = new Cookies();
const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgb(0 89 83);
`;


export const ReceptionForm = ({
  type,
  initialValue,
  submitForm,
}) => {
  const history = useHistory();
  const [receptionForm] = Form.useForm();
  const [organizeAdminForm] = Form.useForm();
  const [isAddOrganizeAdmin, setisAddOrganizeAdmin] = useState(false);
  const [buildings, setBuildings] = useState();
// if (type == 'update') {
//   initialValue.personal_id = 'XXXXXXXXX'+initialValue.personal_id.slice(9, 13) ;
//   initialValue.phone = initialValue.phone.slice(0, 6) +'XXXX';
// }
  

  const getBuilding = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/buildings`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
          setBuildings(get(res, "data.data"));
          receptionForm.setFieldsValue({
            building: res.data.data[0].id,
          });
        }
      })
  };

  useEffect(() => {
    getBuilding()
  }, []);

  const handleChangeBuilding = (value) => {
    const selectedBuilding = find(buildings, ["id", value]);
  };

  return (
    <div className="theme-2-content">
      <Form
        name="receptionForm"
        form={receptionForm}
        labelAlign="left"
        onFinish={submitForm}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 7 },
          md: { span: 7 },
          lg: { span: 7 },
          xl: { span: 7 },
          xxl: { span: 5 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 17 },
          md: { span: 18 },
          lg: { span: 20 },
          xl: { span: 20 },
          xxl: { span: 18 },
        }}
        initialValues={
          initialValue
            ? { ...initialValue ,
              citizenID: initialValue.personal_id }
            : {
                organizeName: "",
                telephone: "",
              }
        }
      >
        <Card
          title="Reception Information"
          extra={
            <Row>
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <Form.Item style={{ paddingTop: "11%" }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          style={{ marginRight: "10px" }}
                        />
                      }
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="theme-button-save"
                      icon={
                        <FontAwesomeIcon
                          icon={faSave}
                          style={{ marginRight: "10px" }}
                        />
                      }
                    >
                      Save
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <Form.Item style={{ paddingTop: "30%" }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={<FontAwesomeIcon icon={faArrowLeft} />}
                      onClick={() => {
                        history.goBack();
                      }}
                    ></Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="theme-2-button-1"
                      icon={<FontAwesomeIcon icon={faSave} />}
                    ></Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          }
        >
          <Row justify="center">
            <Col xxl={12} xl={12} lg={16} md={16} sm={24} xs={24}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="building"
                      label="Building"
                      rules={[
                        { required: true, message: "Please input building" },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        suffixIcon={<SelectIcon />}
                        menuItemSelectedIcon={<Icon icon={faCheck} />}
                        placeholder="Building"
                        onChange={(value) => handleChangeBuilding(value)}
                      >
                        {buildings &&
                          buildings.map((building) => {
                            return (
                              <Option
                                key={building.id} value={building.id}
                              >
                                {building.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="E-Mail"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input E-mail!",
                      },
                      {
                        min: 1,
                        message: "minimum length = 1",
                      },
                      {
                        max: 165,
                        message: "maximum length = 165",
                      },
                    ]}
                  >
                    <Input
                      placeholder="E-Mail"
                      disabled={isEqual(type, "update")}
                      style={{
                        width: '100%',
                        cursor: 'not-allowed',
                        color:'#000',
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="First Name"
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        message: "Please input First Name!",
                      },
                      {
                        min: 1,
                        message: "minimum length = 1",
                      },
                      {
                        max: 165,
                        message: "maximum length = 165",
                      },
                    ]}
                  >
                    <Input
                      placeholder="First Name"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Last Name"
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: "Please input Last Name!",
                      },
                      {
                        min: 1,
                        message: "minimum length = 1",
                      },
                      {
                        max: 165,
                        message: "maximum length = 165",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Last Name"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Telephone"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input Telephone!",
                      },
                      {
                        min: 1,
                        message: "minimum length = 1",
                      },
                      {
                        pattern: '^[+]?[0-9]{9,12}$',
                        message: 'Your telephone number is incorrect format.',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Telephone"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Citizen ID"
                    name="citizenID"
                    rules={[
                      {
                        required: true,
                        message: "Please input Citizen ID!",
                      },
                      {
                        min: 1,
                        message: "minimum length = 1",
                      },
                      {
                        max: 165,
                        message: "maximum length = 165",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Citizen ID"
                      readOnly
                      style={{
                      width: '100%',
                      cursor: 'not-allowed',
                      backgroundColor: 'rgba(0,0,0,0.1)',
                    }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {isEqual(type, "create") && ( <Row>
                <Col span={24}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input Password!",
                      },
                      {
                        min: 1,
                        message: "minimum length = 1",
                      },
                      {
                        max: 165,
                        message: "maximum length = 165",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Password"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              )}
              {isEqual(type, "create") && (
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please input Confirm Password!",
                      },
                      {
                        min: 1,
                        message: "minimum length = 1",
                      },
                      {
                        max: 165,
                        message: "maximum length = 165",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Confirm Password"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              )}
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};
