import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Row, Col, Button, Input, Space, Select, DatePicker, Form } from "antd";
import { Card, CardTable, Icon } from "../components";
import { SearchOutlined } from "@ant-design/icons";
import { UserAvatar } from "./UserAvatar";
import moment from "moment";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { isNull } from "lodash";

const swal = withReactContent(Swal);
const cookies = new Cookies();
const ErrorSection = styled.div`
  color: ${({ theme }) => theme.red};
`;

export default function BuildingLog() {
  const history = useHistory();
  const [dataNew, setDataNew] = useState();
  const [total, setTotal] = useState();
  const [searchArray, setSearchArray] = useState({});
  const [searchFields, setSearchFields] = useState([]);
  const [startD, setStartD] = useState("");
  const [endD, setEndDate] = useState("now");
  const [searchForm] = Form.useForm();

  const roleData = [
    {
      id: 1,
      user_type: "Project Owner",
    },
    {
      id: 2,
      user_type: "Juristic Admin",
    },
    {
      id: 3,
      user_type: "Reception",
    },
    {
      id: 4,
      user_type: "Organize Admin",
    },
    {
      id: 5,
      user_type: "Member",
    },
    {
      id: 6,
      user_type: "Visitor",
    },
    {
      id: 7,
      user_type: "Walk-in Visitor",
    },
    {
      id: 8,
      user_type: "Seminar Visitor",
    },
  ];

  function loadData(page, size) {
    const addPageData = {
      ...searchArray,
      access_start_time: startD,
      access_end_time: endD,
      page: page,
      size: size,
    };
    return addPageData;
  }

  const fetchLog = (page, size) => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const addPageData = loadData(page, size);
    const value = {
      type: "building",
      payload: addPageData,
    };
    const option = {
      method: "post",
      url: `${process.env.REACT_APP_API}/logging/events`,
      headers: {
        Authorization: "Bearer " + cookies.get("accessToken"),
        "Content-Type": "application/json",
      },
      data: value,
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });

        if (res.data.code === 200) {
          setTotal(res.data.data.total);
          formatData(res.data.data.result);
        } else {
          swal.fire({
            icon: "error",
            title: get(res, "data.status"),
            text: get(res, "data.payload"),
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
        }
      })
      .finally(() => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };

  const convertDate = (handleConvertDate) => {
    let convert = handleConvertDate.split("+")[0];
    let Date = convert.split("T")[0];
    let Time = convert.split("T")[1];

    return Date + " " + Time;
  };

  const handleSearchDate = () => {
    searchForm.validateFields().then((values) => {
      let convertstartDate, convertendDate, startDate, endDate;
      if (values.startDate !== undefined && values.startDate !== null) {
        convertstartDate = moment(
          new Date(values.startDate._d),
          "DD-MM-YYYY hh:mm:ss"
        ).format();
        startDate = convertDate(convertstartDate);
      } else startDate = "";
      if (values.endDate !== undefined && values.endDate !== null) {
        convertendDate = moment(
          new Date(values.endDate._d),
          "DD-MM-YYYY hh:mm:ss"
        ).format();
        endDate = convertDate(convertendDate);
      } else {
        endDate = "now";
      }

      const addPageSearchDate = {
        ...searchArray,
        access_start_time: startDate,
        access_end_time: endDate,
        page: 1,
        size: 10,
      };
      global.store.dispatch({ type: "set_loading_show", data: { show: true } });
      const value = {
        type: "building",
        payload: addPageSearchDate,
      };
      const option = {
        method: "post",
        url: `${process.env.REACT_APP_API}/logging/events`,
        headers: {
          Authorization: "Bearer " + cookies.get("accessToken"),
          "Content-Type": "application/json",
        },
        data: value,
      };
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });

          if (res.data.code === 200) {
            setTotal(res.data.data.total);
            formatData(res.data.data.result);
            setStartD(startDate);
            setEndDate(endDate);
          } else {
            swal.fire({
              icon: "error",
              title: get(res, "data.status"),
              text: get(res, "data.payload"),
              showConfirmButton: true,
              confirmButtonColor: "#2F79FF",
              confirmButtonText: "Close",
            });
          }
        })
        .finally(() => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
        });
    });
  };

  const setRole = (data) => {
    if (data === 1) {
      return "Project Owner";
    } else if (data === 2) {
      return "Juristic Admin";
    } else if (data === 3) {
      return "Reception";
    } else if (data === 4) {
      return "Organize Admin";
    } else if (data === 5) {
      return "Member";
    } else if (data === 6) {
      return "Visitor";
    } else if (data === 7) {
      return "Walk-in Visitor";
    } else if (data === 8) {
      return "Seminar Visitor";
    }
  };

  const formatData = (data) => {
    const dataFormat = data.map((log) => {
      return {
        device_name: log.device_name,
        direction: log.direction,
        name: log.name,
        id: log.id,
        photo: log.image,
        mifare: log.card_number,
        user_id: log.user_id,
        user_type: setRole(log.user_type_id),
        timestamp: log.access_time,
      };
    });
    setDataNew(dataFormat);
  };

  const getColumnSearchByOption = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {dataIndex === "user_type" && (
          <Select
            placeholder={`Search ${dataIndex}`}
            onChange={(e) => {
              checkValue(e, dataIndex, setSelectedKeys);
            }}
            value={selectedKeys}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            {roleData.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.user_type}
                </Option>
              );
            })}
          </Select>
        )}
        {dataIndex === "direction" && (
          <Select
            placeholder={`Search ${dataIndex}`}
            onChange={(e) => {
              checkValue(e, dataIndex, setSelectedKeys);
            }}
            value={selectedKeys}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            <Option key="IN" value="IN">
              IN
            </Option>
            <Option key="OUT" value="OUT">
              OUT
            </Option>
          </Select>
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              handleReset(clearFilters, setSelectedKeys, dataIndex)
            }
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const [error, setErrorTitle] = useState("");
  const [check, setCheck] = useState(false);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => checkValue(e, dataIndex, setSelectedKeys)}
          onPressEnter={() => handleSearch(dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <ErrorSection>{error}</ErrorSection>
        <Space>
          <Button
            hidden={!check}
            type="primary"
            onClick={() => handleSearch(dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              handleReset(clearFilters, setSelectedKeys, dataIndex)
            }
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });

  const checkValue = (e, dataIndex, setSelectedKeys) => {
    if (dataIndex === "direction" || dataIndex === "user_type") {
      if (dataIndex === "user_type")
        setSearchArray((prevState) => ({
          ...prevState,
          user_type_id: e,
        }));
      else if (dataIndex === "direction")
        setSearchArray((prevState) => ({
          ...prevState,
          direction: e,
        }));
      setSelectedKeys(e ? [e] : []);
    } else {
      if (e.target.value.length >= 3) {
        setCheck(true);
        setErrorTitle("");
        let name = "";
        if (dataIndex === "name") name = "name";
        else if (dataIndex === "mifare") name = "card_number";
        else if (dataIndex === "device_name") name = "device_name";
        setSearchArray((prevState) => ({
          ...prevState,
          [name]: e.target.value,
        }));
      } else {
        setCheck(false);
        setErrorTitle("minimum length = 3");
      }
      setSelectedKeys(e.target.value ? [e.target.value] : []);
    }
  };

  const handleSearch = (dataIndex) => {
    let name = "";
    if (dataIndex === "name") name = "name";
    else if (dataIndex === "mifare") name = "card_number";
    else if (dataIndex === "device_name") name = "device_name";
    else if (dataIndex === "direction") name = "direction";
    else if (dataIndex === "user_type") name = "user_type_id";
    fetchLog(1, 10);
    setSearchFields([...searchFields, name]);
  };

  useEffect(() => {
    fetchLog(1, 10);
  }, [searchFields]);
  const handleReset = (clearFilters, setSelectedKeys, dataIndex) => {
    let name = "";
    if (dataIndex === "name") name = "name";
    else if (dataIndex === "mifare") name = "card_number";
    else if (dataIndex === "device_name") name = "device_name";
    else if (dataIndex === "direction") name = "direction";
    else if (dataIndex === "user_type") name = "user_type_id";
    if (searchFields.length > 0) {
      setSearchFields((searchFields) =>
        searchFields.filter((data) => data !== name)
      );
    }
    setSearchArray((prevState) => {
      const coppyState = { ...prevState };
      delete coppyState[name];
      return coppyState;
    });
    setSelectedKeys("");
    setErrorTitle("");
    clearFilters();
  };

  const columns = [
    {
      title: "Access Time",
      dataIndex: "timestamp",
      key: "timestamp",
      align: "start",
      className: "theme-2-table-text",
      render: (timestamp) => {
        return <Space>{moment(timestamp).format("YYYY-MM-DD HH:mm:ss")}</Space>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "start",
      className: "theme-2-table-text",
      responsive: ["sm"],
      ...getColumnSearchProps("name"),
    },
    {
      title: "User type",
      dataIndex: "user_type",
      key: "user_type",
      align: "start",
      className: "theme-2-table-text",
      responsive: ["sm"],
      ...getColumnSearchByOption("user_type"),
    },
    {
      title: "Mifare",
      dataIndex: "mifare",
      key: "mifare",
      align: "start",
      className: "theme-2-table-text",
      responsive: ["sm"],
      ...getColumnSearchProps("mifare"),
    },
    {
      title: "Image",
      dataIndex: "photo",
      key: "photo",
      align: "start",
      className: "theme-2-table-text",
      responsive: ["sm"],
      render: (photo) => {
        return (
          <Space>
            {photo && <UserAvatar imageUrl={photo} />}
            {!photo && <Icon icon={faUserCircle} fontSize={"50px"} />}
          </Space>
        );
      },
    },
    {
      title: "Device name",
      dataIndex: "device_name",
      key: "device_name",
      align: "start",
      className: "theme-2-table-text",
      responsive: ["sm"],
      ...getColumnSearchProps("device_name"),
    },
    {
      title: "Direction",
      dataIndex: "direction",
      key: "direction",
      align: "start",
      className: "theme-2-table-text",
      responsive: ["sm"],
      ...getColumnSearchByOption("direction"),
    },
  ];

  return (
    <div className="theme-2-content">
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card margin="20px 0">
            <Row justify="space-between">
              <Col span={6}>
                <div style={{ marginTop: "10px" }}>
                  <p className="theme-2-text-1">{"Building Log \xa0"}</p>
                  <p className="theme-2-text-2">
                    {total ? total : 0}
                    {"\xa0Total logs"}
                  </p>
                </div>
              </Col>
              <Col span={18}>
                <Form name="searchForm" form={searchForm}>
                  <Col
                    xs={6}
                    sm={16}
                    md={12}
                    lg={6}
                    xl={6}
                    style={{ width: "100%", float: "right", padding: "10px" }}
                  >
                    <Form.Item>
                      <Button
                        className="theme-2-button-4"
                        type="primary"
                        block
                        icon={
                          <Icon
                            color="white"
                            margin="0 10px 0 0"
                            icon={faSearch}
                          />
                        }
                        onClick={() => handleSearchDate()}
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={6}
                    sm={16}
                    md={12}
                    lg={8}
                    xl={8}
                    style={{ width: "100%", float: "right", padding: "10px" }}
                  >
                    <Form.Item name="endDate" label="End Date">
                      <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        style={{ width: "100%", textAlign: "right" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={6}
                    sm={16}
                    md={12}
                    lg={8}
                    xl={8}
                    style={{ width: "100%", float: "right", padding: "10px" }}
                  >
                    <Form.Item name="startDate" label="Start Date">
                      <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        style={{ width: "100%", textAlign: "right" }}
                      />
                    </Form.Item>
                  </Col>
                </Form>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <CardTable
                  rowKey="id"
                  columns={columns}
                  dataSource={dataNew}
                  bordered={false}
                  onChange={(e) => fetchLog(e.current, e.pageSize)}
                  pagination={{
                    position: ["bottomCenter"],
                    total: total >= 10000 ? 10000 : total,
                  }}
                  size="middle"
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
