import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Theme from "../theme/theme_1";
import { ResetPasswordForm } from "../components";

const swal = withReactContent(Swal);

export default function ResetPassword() {
  const history = useHistory();
  const { forgotToken } = useParams();
  
  const submitForm = (values) => {
    const option = {
      method: "post",
      url: `${process.env.REACT_APP_API}/users/reset_password`,
      data: {
        token: forgotToken,
        password: values.password,
      },
    };
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
          swal.fire({
            icon: "success",
            title: "Success",
            text:
              "Password reset successful! You can now login with the new password.",
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
          history.push("/login");
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: "error",
          title: 'Reset password failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: "#2F79FF",
          confirmButtonText: "Close",
        });
      });
  };

  const cancelForm = () => {
    history.goBack();
  };

  return (
    <div>
      <Theme>
        <ResetPasswordForm
          forgotToken={forgotToken}
          submitForm={submitForm}
          cancelForm={cancelForm}
        />
      </Theme>
    </div>
  );
}
