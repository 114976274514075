import styled from 'styled-components';
import {
  Image as BaseImage,
  Table,
  Row,
  Col,
  Tag as BaseTag,
  Form,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export * from './common/Card';
export * from './UserCard';
export * from './BuildingForm';
export * from './HeadBarAvatar';
export * from './ResetPasswordForm';
export * from './DeleteModal';
export * from './ChangePasswordModal';
export * from './FromWebStemModal';
export * from './InputAvatar';
export * from './UpdateProfileForm';
export * from './CreateAccessModal';
export * from './DataNotFound';
export * from './NewsForm';
export * from './InputThumbnail';
export * from './PromotionForm';
export * from './ConsentForm';
export * from './ComparePassword';
export * from './LanguageSelector';

export const FormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    font-weight: bold;
    font-size: 18px;
  }
  margin-bottom: 24px;
`;
export * from './OrganizationForm';
export * from './DeleteModal';

export const Tag = styled(BaseTag)`
  border-radius: 40px;
  border: 1px solid ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryColor};
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${(props) =>
    props.color ? props.theme[props.color] : props.theme.primaryColor};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
`;

export const Image = styled(BaseImage)`
  width: ${(props) => (props.width ? props.width : '100%')};
  display: flex;
  justify-content: center;
  margin: ${(props) => (props.margin ? props.margin : 0)};
`;

export const CardTable = styled(Table)`
  border-collapse: collapse;
  th {
    font-weight: bold !important;
    background-color: ${(props) => props.theme.primaryColor} !important;
    border: 1px solid #007f7b !important;
    color: #fff !important;
  }
  th:first-child {
    //border-radius: 8px 0 0 8px !important;
  }
  th:last-child {
    // border-radius: 0 8px 8px 0 !important;
  }
  td {
    background-color: ${(props) => props.theme.bgTextButton};
    border-top: 5px solid white;
  }
  .inactive-row td {
    background-color: #ffe4e1 !important;
    border-top: 5px solid white;
  }
  td:first-child {
    border-radius: 0 !important;
  }
  td:last-child {
    border-radius: 0 !important;
  }
  tr:hover td:first-child {
    background-color: ${(props) => props.theme.bgTextButton};
  }
  tr:hover td:not(:first-child) {
    background-color: ${(props) => props.theme.bgTextButton};
  }
  .exit-floor {
    td {
      background-color: ${(props) => props.theme.exitFloor};
      border-top: 5px solid white;
    }
    tr:hover td:first-child {
      background-color: ${(props) => props.theme.exitFloor};
    }
    tr:hover td:not(:first-child) {
      background-color: ${(props) => props.theme.exitFloor};
    }
  }
`;

const ColTable = styled(Col)`
  text-align: ${(props) =>
    props.align.toLowerCase() !== 'left' ? props.align : 'left'};
`;

const RowTable = styled(Row)`
  margin-top: 5px;
  padding: ${(props) =>
    props.type.toLowerCase() === 'thead' ? '10px' : '10px'};
  border-radius: ${(props) =>
    props.type.toLowerCase() === 'thead' ? 0 : '10px'};
  fontweight: ${(props) =>
    props.type.toLowerCase() === 'thead' ? 'bold' : 'normal'};
  background: ${(props) =>
    props.type.toLowerCase() === 'thead'
      ? props.theme.primaryColor
      : props.theme.bgTextButton};
`;

export const StyledTable = ({ columns, dataSource, children }) => {
  return (
    <>
      <RowTable
        type={'thead'}
        style={{ padding: '10px', borderRadius: '5px', fontWeight: 'bold' }}
      >
        {columns.length > 0 &&
          columns.map((header) => {
            return (
              <ColTable
                align={header.align}
                xs={header.xs}
                sm={header.sm}
                md={header.md}
                lg={header.lg}
                key={header.key}
              >
                {header.title}
              </ColTable>
            );
          })}
      </RowTable>
      {dataSource.length > 0 &&
        dataSource.map((row) => {
          return (
            <RowTable type={'tbody'}>
              {columns.map((rowDetail) => {
                if (rowDetail.title.toLowerCase() === 'action') {
                  return (
                    <ColTable
                      align={rowDetail.align}
                      key={row.key}
                      xs={rowDetail.xs}
                      sm={rowDetail.sm}
                      md={rowDetail.md}
                      lg={rowDetail.lg}
                    >
                      {children}
                    </ColTable>
                  );
                } else {
                  return (
                    <ColTable
                      align={rowDetail.align}
                      key={row.key}
                      xs={rowDetail.xs}
                      sm={rowDetail.sm}
                      md={rowDetail.md}
                      lg={rowDetail.lg}
                    >
                      {row[rowDetail.dataIndex]}
                    </ColTable>
                  );
                }
              })}
            </RowTable>
          );
        })}
    </>
  );
};
