import React from "react";
import Theme from "../theme/theme_2";
import UserManagement from "../components/UserManagement";
import Cars from "../components/CarManagement";

export default function cars() {
  return (
    <div>
      <Theme PageName={"Cars"}>
        <Cars />
      </Theme>
    </div>
  );
}
